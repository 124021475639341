import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { BaseComponent } from '../../components/BaseComponent';
import Icon, { ICONS } from '../../components/Icon/Icon';
import withSuspense from '../../common/hocs/withSuspense';

import './MaintenanceView.scss';

export interface MaintenanceViewProps extends WithTranslation {}

export class MaintenanceView extends BaseComponent<MaintenanceViewProps> {
    render() {
        const { t } = this.props;

        return (
            <div className="maintenance-container">
                <Icon name={ICONS.PLUG} className="maintenance-container__icon maintenance-container__icon--left" />
                <div className="maintenance-container__content">
                    <span className="maintenance-container__title">{t(`views.maintenance.title`)}</span>
                    <span className="maintenance-container__text">{t(`views.maintenance.message`).replace(/>/g, '\n')}</span>
                </div>
                <Icon name={ICONS.WIRE} className="maintenance-container__icon maintenance-container__icon--right" />
            </div>
        );
    }
}

export default withSuspense(withTranslation()(MaintenanceView));
