import * as React from 'react';
import { LogoProps } from '../Logo';

const FitekInLogo: React.FunctionComponent<LogoProps> = ({ compact, className }: LogoProps) =>
    compact ? (
        <svg width="34" height="48" viewBox="0 0 34 48" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5067 47.0482L12.68 44.9305L6.9699 40.4436L0.331421 6.35181L27.5995 0.969421L33.7088 5.76684V28.7213L19.5067 47.0482ZM13.4883 43.3717L18.8802 45.0449L31.9884 28.1298V6.61325L27.1613 2.81911L2.35056 7.71457L8.53133 39.4762L13.4883 43.3717Z"
                fill="white"
            />
        </svg>
    ) : (
        <svg width="142" height="50" fill="none" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402.52 125">
            <path fill="white" d="M52.81,125l-18.65-5.75-15.6-12.16L.44,14.6,74.91,0,91.59,13V75.29ZM36.37,115l14.73,4.54L86.9,73.67V15.31L73.71,5,6,18.3l16.88,86.16Z" />
            <path
                fill="white"
                d="M163.28,51.84V85.32H150.65V51.84h-6.54V41.33h6.54V37.27c0-6.27,2-9.9,4.5-12.19s6.81-3.54,10.6-3.54c6.8,0,11.22,2.21,13.69,5.13l-6.53,7.59A7,7,0,0,0,167.78,32c-2.74,0-4.5,1.5-4.5,4.85v4.51h9.54V51.84Z"
            />
            <path fill="white" d="M178.82,28.43a7.38,7.38,0,1,1,7.33,7.33A7.18,7.18,0,0,1,178.82,28.43Zm1,12.9h12.72v44H179.79Z" />
            <path
                fill="white"
                d="M219.45,85.93c-7.86,0-13.78-4.23-13.78-14l.08-20h-6.27V41.33h6.36V29.58h12.37V41.33h10.95V51.84h-11V70.65c0,3.89,1.77,4.86,4,4.86a6.77,6.77,0,0,0,4.24-1.76l4.59,8.3A17.49,17.49,0,0,1,219.45,85.93Z"
            />
            <path
                fill="white"
                d="M257.42,86.29c-12.19,0-23-8-23-23.23,0-14.4,10.33-22.88,22.08-22.88,12.1,0,20.58,8.3,21,21.55.09,2.65.18,3.8.18,4.51H247.09c.44,6.27,5.12,10.07,11,10.07a11.86,11.86,0,0,0,10.51-5.92l8.66,5.83C273.67,82.49,267.05,86.29,257.42,86.29ZM247.35,58.11h17.49c-.26-5.56-4.33-8.12-8.66-8.12A8.42,8.42,0,0,0,247.35,58.11Z"
            />
            <path fill="white" d="M303.61,63.59l-4.68,4.24V85.32H286.39V21.9h12.54V54.31l14.4-13h16.33L313.24,55.9l16.6,29.42H315.27Z" />
            <path fill="white" d="M335.4,28.43a7.38,7.38,0,1,1,7.33,7.33A7.18,7.18,0,0,1,335.4,28.43Zm1,12.9h12.72v44H336.37Z" />
            <path
                fill="white"
                d="M373.55,57.49V85.32H360.83v-44h12.72v5.3c2.74-3.89,7.07-6.36,12.9-6.36,9,0,15.63,5.48,15.63,17.4V85.32H389.36V60.23c0-6.09-3-8.57-7.6-8.57C378.41,51.66,375.23,53.69,373.55,57.49Z"
            />
        </svg>
    );

export default FitekInLogo;
