import classNames from 'classnames';
import * as React from 'react';

import { ContentBlockStrip } from './ContentBlockStrip';

export interface ContentBlockFooterProps {
    children?: React.ReactNode;
    className?: string;
    contentClass?: string;
    type?: ContentBlockFooterType;
    noSeparator?: boolean;
}

export enum ContentBlockFooterType {
    DEFAULT = 'DEFAULT',
    NARROW = 'NARROW',
    NO_PADDING = 'NO-PADDING',
    PAGER = 'PAGER',
}

export const ContentBlockFooter: React.StatelessComponent<ContentBlockFooterProps> = ({ children, className, contentClass, type, noSeparator = true }: ContentBlockFooterProps) => {
    const classes = classNames(className, 'content-block__footer', `content-block__footer--${type.toString().toLowerCase()}`);
    const BlockFooter = noSeparator ? 'div' : ContentBlockStrip;

    return (
        <BlockFooter className={classes}>
            <div className={contentClass}>{children}</div>
        </BlockFooter>
    );
};

ContentBlockFooter.defaultProps = {
    type: ContentBlockFooterType.DEFAULT,
};
