import classNames from 'classnames';
import * as React from 'react';

import './SectionSeparator.scss';

export interface SectionSeparatorProps {
    children?: React.ReactNode;
    className?: string;
}

const SectionSeparator: React.FunctionComponent<SectionSeparatorProps> = ({ children, className }: SectionSeparatorProps) => {
    const classes = classNames('section-separator', className, { 'section-separator--empty': !children });

    return (
        <p className={classes}>
            <span className={'section-separator__text'}>{children}</span>
        </p>
    );
};

export default SectionSeparator;
