import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeCompanyDTO, BackOfficeCompanyModules } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, SystemConfigurationDTO } from '../types/ApiTypes';

export default class BoCompaniesService {
    constructor(private axios: AxiosInstance) {}

    getCompanyByGuid = async (companyGuid: string): Promise<AxiosResponse<BackOfficeCompanyDTO>> => {
        return this.axios.get(`/Company/GetByGuid?companyGuid=${companyGuid}`);
    };
    addOrUpdateCompanySetting = async (params: SystemConfigurationDTO): Promise<AxiosResponse<SystemConfigurationDTO>> => {
        return this.axios.post(
            `/CompanySetting/AddOrUpdateCompanySetting`,
            {},
            {
                params,
            },
        );
    };
    getAllCompanySettings = async (companyGuid: string): Promise<AxiosResponse<SystemConfigurationDTO[]>> => {
        return this.axios.get(`/CompanySetting/GetAllCompanySettings?companyGuid=${companyGuid}`);
    };
    getCompanies = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeCompanyDTO>>> => {
        return this.axios.post('/Company/GetCompanies', searchParams);
    };
    saveCompany = async (company: BackOfficeCompanyDTO): Promise<AxiosResponse<BackOfficeCompanyDTO>> => {
        return this.axios.post('/Company/SaveCompany', company);
    };
    deleteCompany = async (companyGuid: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`/Company/DeleteCompany?companyGuid=${companyGuid}`);
    };
    grantAccess = async (companyGuid: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.post(`/Company/GrantAccess?companyGuid=${companyGuid}`);
    };
    cloneDatabase = async (companyGuid: string): Promise<AxiosResponse<BackOfficeCompanyDTO>> => {
        return this.axios.get(`/Company/CloneDatabase?companyGuid=${companyGuid}`);
    };
    getActiveModules = async (companyGuid: string): Promise<AxiosResponse<BackOfficeCompanyModules[]>> => {
        return this.axios.get('/Company/GetActiveModules', {
            params: {
                companyGuid,
            },
        });
    };
    saveActiveModules = async (companyGuid: string, modules: BackOfficeCompanyModules[]): Promise<AxiosResponse<BackOfficeCompanyModules[]>> => {
        return this.axios.post('/Company/SaveActiveModules', modules, {
            params: {
                companyGuid,
            },
        });
    };
    getSameOrganizationCompanies = async (companyGuid: string): Promise<AxiosResponse<BackOfficeCompanyDTO[]>> => {
        return this.axios.get('/Company/GetSameOrganizationCompanies', {
            params: {
                companyGuid,
            },
        });
    };

    exportCompaniesToCsv = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Company/ExportCompaniesToCSV`, search, {
            responseType: 'blob',
        });
    };

    exportCompaniesToXls = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Company/ExportCompaniesToXls`, search, {
            responseType: 'blob',
        });
    };
}
