import classNames from 'classnames';
import { noop } from 'lodash-es';
import React, { FunctionComponent, ReactNode } from 'react';

export interface TabListItemProps {
    children?: ReactNode;
    isActive: boolean;
    isVisible?: boolean;
    className?: string;
    onClick?: () => void;
    dataId: string;
}

export const TabListItem: FunctionComponent<TabListItemProps> = (props) => {
    const { className, isActive, isVisible = true, onClick } = props;
    const classes = classNames('tab-list__item', className, {
        active: isActive,
    });
    return (
        <>
            {isVisible && (
                <li
                    data-id={props.dataId}
                    tabIndex={0}
                    role="button"
                    className={classes}
                    onClick={onClick ? onClick : noop}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && onClick) {
                            onClick();
                        }
                    }}
                >
                    {props.children}
                </li>
            )}
        </>
    );
};

export default TabListItem;
