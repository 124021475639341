import classNames from 'classnames';
import { debounce, isBoolean, uniqueId } from 'lodash-es';
import * as React from 'react';
import { RefObject } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Instance } from 'tippy.js';

import { createDataId, WithDataId } from '../../common/utils/dataId';
import withFormikField from '../../common/utils/withFormikField';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import { Button, ButtonType } from '../Buttons/Button';
import Icon, { IconSize, ICONS } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

import './TextInput.scss';

export const DEFAULT_PASS_MAX_LENGTH = 256;

export interface TextInputProps {
    type?: TextInputType;
    /**
     * Align text to right
     */
    pullRight?: boolean;
    /**
     * if true, component wil trigger focus on the input when mounted
     */
    autofocus?: boolean;

    /**
     * Trigger change only when field is blurred, useful when performance is important with many formik fields
     */
    onlyChangeOnBlur?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    placeholder?: string;
    className?: string;
    wrapperClass?: string;
    forwardRef?: RefObject<HTMLInputElement>;
    validCharacters?: RegExp | undefined;
    onFocusPlaceholder?: string;
    label?: React.ReactNode;
    value?: string;
    textarea?: boolean;
    name?: string;
    maxLength?: number;
    disabled?: boolean;
    replaceComma?: boolean;
    autosize?: boolean;
    error?: React.ReactNode;
    hideError?: boolean;
    blurOnEnter?: boolean;
    restoreOnEsc?: boolean;
    icon?: string;
    password?: boolean;
    email?: boolean;
    showTooltipOnOverflow?: boolean;
    showClear?: boolean;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    disableAutofocusScroll?: boolean;
    ['data-id']?: string;
    labelStyle?: LabelStyle;
    hasFocus?: boolean;
    isHovered?: boolean;
    onMouseEnter?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
    autoComplete?: string;
    readOnly?: boolean;
    isProcessing?: boolean;
    isToggleVisible?: boolean;
}

export enum LabelStyle {
    DEFAULT = 'DEFAULT',
    UPPERCASE = 'UPPERCASE',
}

export enum TextInputType {
    DEFAULT = 'DEFAULT',
    LARGE = 'LARGE',
    BORDERED = 'BORDERED',
    COMPACT = 'COMPACT',
    INLINE_TABLE = 'INLINE_TABLE',
    SEARCH = 'SEARCH',
    SEARCH_COMPACT = 'SEARCH_COMPACT',
    DATE_PICKER = 'DATE_PICKER',
    CURRENCY = 'CURRENCY',
    WITH_LABEL = 'WITH_LABEL',
    WITH_ADDON = 'WITH_ADDON',
}

const parseInputValue = (inputValue: any): string => {
    if (typeof inputValue === 'number') {
        return inputValue.toString();
    }
    if (typeof inputValue === 'string') {
        return inputValue;
    }
    return '';
};

export interface TextInputState {
    value: string;
    lastChangeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    hasFocus: boolean;
    isHovered: boolean;
    isOverflowing: boolean;
    tooltipContent: string;
    backupValue: string;
    loading: boolean;
    success: boolean;
}

export class TextInput extends BaseStatefulComponent<TextInputProps & WithDataId, TextInputState> {
    private inputRef = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
    private contentElementRef = React.createRef<HTMLSpanElement>();
    private inputId: string;
    private tooltipInstance: Instance;

    constructor(props: TextInputProps & React.HTMLProps<HTMLInputElement> & WithDataId) {
        super(props);
        this.state = {
            value: parseInputValue(props.value),
            lastChangeEvent: undefined,
            hasFocus: false,
            isHovered: false,
            isOverflowing: false,
            tooltipContent: parseInputValue(props.value),
            backupValue: '',
            loading: false,
            success: false,
        };
        if (this.props.forwardRef) {
            this.inputRef = this.props.forwardRef;
        }
        this.inputId = uniqueId('text-input_');
    }

    handleInputOverflow = () => {
        if (!this.props.autosize) {
            return;
        }
        const contentElementNode = this.contentElementRef.current;

        if (contentElementNode) {
            const isOverflowing = contentElementNode.offsetWidth < contentElementNode.scrollWidth;

            this.setState({
                isOverflowing,
            });
        }
    };

    debounceOnResize = debounce(this.handleInputOverflow, 100);

    focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        this.inputRef.current.focus();
        if (this.props.disableAutofocusScroll) {
            window.scrollTo(x, y);
        }
        this.setState({
            hasFocus: true,
        });
    };

    componentDidMount() {
        if (this.props.autofocus) {
            this.focus();
        }
        if (this.props.autosize) {
            window.setTimeout(this.handleInputOverflow, 100);
            window.addEventListener('resize', this.debounceOnResize);
        }
        if (this.props.hasFocus !== undefined && this.props.hasFocus !== null) {
            this.setState((prevState) => ({
                ...prevState,
                hasFocus: this.props.hasFocus,
            }));
        }
        if (this.props.isHovered !== undefined && this.props.isHovered !== null) {
            this.setState((prevState) => ({
                ...prevState,
                isHovered: this.props.isHovered,
            }));
        }
    }

    componentWillUnmount() {
        if (this.props.onlyChangeOnBlur && this.state.lastChangeEvent && this.state.hasFocus) {
            this.props.onChange(this.state.lastChangeEvent);
        }
        if (this.props.autosize) {
            window.removeEventListener('resize', this.debounceOnResize);
        }
    }

    componentDidUpdate(prevProps: TextInputProps & React.HTMLProps<HTMLInputElement> & WithDataId) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: parseInputValue(this.props.value),
            });
            if (this.props.showTooltipOnOverflow) {
                if (!this.state.hasFocus) {
                    this.setState({
                        tooltipContent: parseInputValue(this.props.value),
                    });
                }
                if (this.tooltipInstance) {
                    this.tooltipInstance.hide();
                }
            }
        }
        if (!prevProps.autofocus && this.props.autofocus) {
            this.focus();
        }
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                hasFocus: false,
            });
        }
        if (!prevProps.isProcessing && this.props.isProcessing) {
            this.setState({ loading: true });
            if (this.state.success) {
                this.setState({
                    success: false,
                });
            }
        }
        if (prevProps.isProcessing && !this.props.isProcessing && this.state.loading) {
            this.setState({ loading: false });
            if (!this.props.error) {
                this.setState({ success: true });
                setTimeout(() => {
                    this.setState({ success: false });
                }, 3000);
            }
        }
        if (!prevProps.error && this.props.error) {
            this.setState({ loading: false, success: false });
        }
        this.handleInputOverflow();
    }

    clearInput = (e: React.ChangeEvent<any>) => {
        const event = this.state.lastChangeEvent || e;
        this.setState({ value: '' });
        this.focus();
        event.target.value = '';
        this.props.onChange(event);
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { validCharacters, replaceComma } = this.props;
        if (validCharacters && !validCharacters.test(e.target.value)) {
            e.preventDefault();
            return;
        }
        e.persist();
        if (replaceComma) {
            e.target.value = e.target.value.replace(',', '.');
        }
        this.setState({ value: e.target.value, lastChangeEvent: e });
        if (!this.props.onlyChangeOnBlur && this.props.onChange) {
            this.props.onChange(e);
        }
    };

    handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.persist();
        this.setState({
            hasFocus: false,
            tooltipContent: this.state.value,
        });
        if (this.props.onlyChangeOnBlur) {
            this.props.onChange(e);
        }
        if (this.props.onBlur) {
            setTimeout(() => {
                this.props.onBlur(e);
            }, 0);
        }
    };

    handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState((prevState) => ({
            ...prevState,
            hasFocus: true,
            backupValue: prevState.value,
            success: false,
        }));
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.blurOnEnter && e.key === 'Enter') {
            this.inputRef.current.blur();
        } else if (this.props.restoreOnEsc && e.key === 'Escape') {
            this.inputRef.current.value = this.state.backupValue;
            this.setState((prevState) => ({
                ...prevState,
                value: prevState.backupValue,
                backupValue: '',
            }));
            this.inputRef.current.blur();
        } else if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };

    handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(e);
        }
    };

    handleClick = (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    handleMouseEnter = (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        if (isBoolean(this.props.isHovered)) {
            return;
        }
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(e);
            return;
        }
        this.setState((prevState) => ({
            ...prevState,
            isHovered: true,
        }));
    };

    handleMouseLeave = (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        if (isBoolean(this.props.isHovered)) {
            return;
        }
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(e);
            return;
        }
        this.setState((prevState) => ({
            ...prevState,
            isHovered: false,
        }));
    };

    getPlaceholder() {
        const { onFocusPlaceholder, placeholder, disabled } = this.props;
        if (disabled) {
            return null;
        }
        return onFocusPlaceholder && this.state.hasFocus ? onFocusPlaceholder : placeholder;
    }

    setTooltipInstance = (instance: Instance) => {
        this.tooltipInstance = instance;
    };

    render() {
        const {
            autosize,
            className,
            dataId,
            disabled,
            icon,
            label,
            labelStyle = LabelStyle.DEFAULT,
            maxLength,
            name,
            password,
            email,
            pullRight,
            showClear,
            showTooltipOnOverflow,
            textarea,
            type = TextInputType.DEFAULT,
            wrapperClass,
            addonBefore,
            onMouseEnter,
            onMouseLeave,
            autoComplete,
            readOnly,
            addonAfter,
            isToggleVisible,
        } = this.props;
        const labelRenderStyle = type === TextInputType.SEARCH_COMPACT ? LabelStyle.UPPERCASE : labelStyle;
        const classes = classNames('text-input__input', className, {
            'text-input__input--default': type === TextInputType.DEFAULT || type === TextInputType.LARGE,
            'text-input__input--large': type === TextInputType.LARGE,
            'text-input__input--bordered': type === TextInputType.BORDERED,
            'text-input__input--compact': type === TextInputType.COMPACT || type === TextInputType.SEARCH_COMPACT,
            'text-input__input--inline-table': type === TextInputType.INLINE_TABLE,
            'text-input__input--has-icon': (!!this.props.icon && type !== TextInputType.DATE_PICKER) || type === TextInputType.SEARCH || type === TextInputType.SEARCH_COMPACT,
            'text-input__input--pull-right-with-toggle': isToggleVisible,
            'text-input__input--pull-right': pullRight,
            'text-input__input--has-clear': showClear,
            'text-input__input--search text-input__input--bordered': type === TextInputType.SEARCH,
            'text-input__input--search-compact': type === TextInputType.SEARCH_COMPACT,
            'text-input__input--date-picker text-input__input--bordered': type === TextInputType.DATE_PICKER,
            'text-input__input--currency text-input__input--bordered': type === TextInputType.CURRENCY,
            'text-input__input--is-hovered': this.state.isHovered,
            'text-input__input--is-disabled': disabled,
            'text-input__input--has-addon': type === TextInputType.WITH_ADDON,
        });

        const wrapperClasses = classNames('text-input', wrapperClass, {
            'text-input--default': type === TextInputType.DEFAULT || type === TextInputType.LARGE,
            'text-input--has-focus': this.state.hasFocus,
            'text-input--large': type === TextInputType.LARGE,
            'text-input--bordered': type === TextInputType.BORDERED || type === TextInputType.SEARCH,
            'text-input--compact': type === TextInputType.COMPACT || type === TextInputType.SEARCH_COMPACT || type === TextInputType.DATE_PICKER,
            'text-input--inline-table': type === TextInputType.INLINE_TABLE,
            'text-input--has-icon': !!this.props.icon || type === TextInputType.SEARCH || type === TextInputType.SEARCH_COMPACT,
            'text-input--autosize': autosize,
            'text-input--is-overflowing': this.state.isOverflowing,
            'text-input--has-error': !!this.props.error,
            'text-input--is-disabled': disabled,
            'text-input--search': type === TextInputType.SEARCH,
            'text-input--search-compact': type === TextInputType.SEARCH_COMPACT,
            'text-input__input--currency': type === TextInputType.CURRENCY,
            'text-input--has-content': !!this.state.value,
            'text-input--highlight-label': type === TextInputType.WITH_LABEL,
            'text-input--has-textarea': !!textarea,
            'text-input--is-hovered': this.state.isHovered,
            'text-input--has-addon': type === TextInputType.WITH_ADDON,
            'text-input--has-loading': this.state.loading,
            'text-input--has-success': this.state.success,
        });

        const inputWrapper = (
            // add tabIndex -1 because Tippy otherwise adds 0 when tooltip is enabled and breaks the tab navigation order in tables
            <div className="text-input__input-wrapper" tabIndex={-1}>
                {autosize && !textarea && (
                    <span ref={this.contentElementRef} className={`text-input__autosizer ${classes}`}>
                        &nbsp;
                        {this.state.value || this.props.placeholder}
                    </span>
                )}
                {icon && <Icon name={icon} className={type === TextInputType.DATE_PICKER ? 'text-input__icon-date' : 'text-input__icon'} size={IconSize.SM} />}
                {!icon && (type === TextInputType.SEARCH || type === TextInputType.SEARCH_COMPACT) && <Icon name={ICONS.SEARCH} className="text-input__icon" size={IconSize.SM} />}
                {addonBefore && <span className="text-input__addon-before">{addonBefore}</span>}
                {!textarea && (
                    <input
                        type={password ? 'password' : email ? 'email' : 'text'}
                        data-id={dataId}
                        className={classes}
                        ref={this.inputRef as RefObject<HTMLInputElement>}
                        value={this.state.value}
                        placeholder={this.getPlaceholder()}
                        id={this.inputId}
                        name={name}
                        maxLength={password ? DEFAULT_PASS_MAX_LENGTH : maxLength}
                        disabled={disabled}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onKeyDown={this.handleKeyDown}
                        onKeyUp={this.handleKeyUp}
                        onClick={this.handleClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        autoComplete={autoComplete ?? 'off'}
                        readOnly={readOnly}
                    />
                )}
                {showClear && this.state.value && (
                    <Button
                        dataId={createDataId(dataId, 'clear')}
                        className="text-input__clear"
                        buttonType={ButtonType.ICON_SQUARE}
                        icon={ICONS.CLOSE_SMALL}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            this.clearInput(e);
                        }}
                    />
                )}
                {textarea && (
                    <TextareaAutosize
                        type="text"
                        data-id={dataId}
                        className={classes}
                        autoComplete="off"
                        inputRef={(node) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            this.inputRef.current = node;
                        }}
                        readOnly={readOnly}
                        value={this.state.value}
                        placeholder={this.getPlaceholder()}
                        id={this.inputId}
                        name={name}
                        maxLength={maxLength}
                        disabled={disabled}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onKeyDown={this.handleKeyDown}
                        onKeyUp={this.handleKeyUp}
                        onClick={this.handleClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                )}
                {addonAfter && !this.props.error && <span className="text-input__addon-after">{addonAfter}</span>}
                {!this.props.hideError && this.props.error && (
                    <Tooltip content={this.props.error as React.ReactElement}>
                        <Button buttonType={ButtonType.ICON} className="text-input__alert-icon" icon={ICONS.ALERT} tabIndex={-1} dataId={`${dataId}.error`} />
                    </Tooltip>
                )}
                {this.state.loading && <Icon name={ICONS.LoadingInput} className="text-input__icon text-input__auto-alert-loading" size={IconSize.SM} />}
                {this.state.success && !this.props.error && <Icon name={ICONS.SuccessInput} className="text-input__icon text-input__auto-alert-success" size={IconSize.SM} />}
            </div>
        );

        return (
            <>
                <div className={wrapperClasses} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <div className={classNames('text-input__container', { 'text-input__container--pull-right': pullRight })}>
                        {label && (
                            <label className={`text-input__label text-input__label--${labelRenderStyle.toLowerCase()}`} htmlFor={this.inputId}>
                                {label}
                            </label>
                        )}

                        {showTooltipOnOverflow && (
                            <Tooltip content={this.state.tooltipContent} onCreate={this.setTooltipInstance} isEnabled={this.state.isOverflowing}>
                                {inputWrapper}
                            </Tooltip>
                        )}

                        {!showTooltipOnOverflow && inputWrapper}
                    </div>
                </div>
            </>
        );
    }
}

export const TextInputField = withFormikField(TextInput);
