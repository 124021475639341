import * as React from 'react';
import { LogoProps } from '../Logo';

const MaximaLogo: React.FunctionComponent<LogoProps> = ({ compact }: LogoProps) =>
    compact ? (
        <svg width="36" height="48" viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.6669 40.3525L32.5603 41.453C32.2939 41.396 32.0604 41.3744 31.851 41.3744C31.4259 41.3744 31.0604 41.6128 30.7457 42.0857C30.5896 42.3988 30.7787 42.8121 31.3003 43.3459C31.5071 43.5551 32.0908 43.9963 33.0349 44.6873V44.8445C33.0349 44.9991 33.1162 45.5532 33.271 46.499C30.0643 46.499 27.1964 45.8676 24.6737 44.6061C23.1001 43.8201 20.651 42.1326 17.3377 39.5614C15.8695 38.305 14.6475 37.15 13.6729 36.0964C12.692 35.0403 11.6857 33.7294 10.635 32.156L1.48315 39.7211L1.72172 34.0463L7.71382 26.7956C6.61235 25.586 5.63397 24.2776 4.79899 22.8551C4.21526 21.6443 3.74193 20.4817 3.37773 19.3496C3.01227 18.2148 2.74578 17.0548 2.58843 15.8415C2.85364 15.3216 2.98308 14.9476 2.98308 14.741L2.74579 13.7166L11.2631 5.04584L18.0496 16.1572L30.9817 1.97134C31.2495 1.65692 31.4589 1.50098 31.6137 1.50098C31.9792 1.50098 32.1682 2.21096 32.1682 3.62714C32.1682 4.78087 32.0908 5.99038 31.9322 7.25188C31.7736 8.51464 31.6645 9.38184 31.6137 9.85601C31.1416 12.2649 29.3257 14.7625 26.1711 17.3413C24.0709 19.0757 22.5443 20.5743 21.5989 21.832C21.9111 22.5585 22.1776 23.1417 22.3844 23.5664C23.6458 26.1845 24.7244 28.2637 25.6191 29.7915C26.9312 31.9962 28.1735 33.8599 29.3257 35.3851C30.4792 36.9027 31.9322 38.556 33.6669 40.3525Z"
                fill="white"
            />
        </svg>
    ) : (
        <svg width="140" height="32" viewBox="0 0 469 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M134.009 55.148H146.418L141.475 38.6031L134.009 55.148ZM127.053 20.4438H158.412C160.981 28.0434 163.603 35.5209 166.282 42.8465C169.71 52.3914 172.478 59.8906 174.553 65.3468C175.304 67.3057 176.061 69.2781 176.817 71.2343L176.118 87.8444H155.695C155.034 85.2777 154.244 82.6541 153.374 79.968C152.167 76.5386 151.291 73.9746 150.754 72.306H126.649L119.797 87.8444H91.0546C97.0449 77.689 103.258 66.5514 109.709 54.4453C115.499 43.6116 121.272 32.2895 127.053 20.4438Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M416.18 55.148H428.57L423.637 38.6031L416.18 55.148ZM409.219 20.4438H440.581C443.143 28.0434 445.76 35.5209 448.445 42.8465C451.878 52.3914 454.633 59.8906 456.719 65.3468C459.598 72.8731 462.505 80.3723 465.388 87.8444H437.853C437.18 85.2777 436.407 82.6541 435.529 79.968C434.333 76.5386 433.457 73.9746 432.912 72.306H408.818L401.951 87.8444H373.217C379.202 77.689 385.418 66.5514 391.874 54.4453C397.664 43.6116 403.424 32.2895 409.219 20.4438Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M293.241 20.4458H326.523C326.87 22.733 327.797 28.6124 329.343 38.0977C330.628 45.8302 331.466 51.8643 331.876 56.1592C333.215 50.5104 335.2 43.0465 337.82 33.762C339.978 26.1597 341.207 21.7156 341.546 20.4458H375.636C375.636 27.7089 375.449 35.1647 375.08 42.7969C374.722 50.4182 374.334 58.4356 373.979 66.8057C373.607 75.1758 373.42 82.1921 373.42 87.8409H351.029C351.029 83.063 351.189 77.6855 351.528 71.6975C352.341 57.315 352.743 48.2938 352.743 44.6554L340.548 87.8409H318.455L311.407 44.6554C310.659 51.5197 310.024 58.8263 309.485 66.5534C308.942 74.2886 308.671 81.389 308.671 87.8409H287.402C288.27 80.3715 289.176 72.7068 290.122 64.8414C291.063 56.9705 291.817 49.3627 292.381 42.0345C292.954 34.7116 293.241 27.5082 293.241 20.4458Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M252.614 20.4458H279.729C277.779 43.1089 276.811 65.5848 276.811 87.8409H252.787L249.495 84.813C249.566 79.2971 249.815 73.1301 250.238 66.3038C250.745 58.1914 251.263 49.8077 251.8 41.131C252.337 32.4516 252.614 25.5574 252.614 20.4458Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0743 20.4458H43.3587C43.7004 22.733 44.6305 28.6124 46.1789 38.0977C47.4535 45.8302 48.2941 51.8643 48.7009 56.1592C50.0514 50.5104 52.031 43.0465 54.6533 33.762C56.8118 26.1597 58.0403 21.7156 58.382 20.4458H92.469C92.469 27.7089 92.2792 35.1647 91.9104 42.7969C91.5525 50.4182 91.1674 58.4356 90.804 66.8057C90.4352 75.1758 90.2508 82.1921 90.2508 87.8409H67.8569C67.8569 83.063 68.0196 77.6855 68.364 71.6975C69.1694 57.315 69.5735 48.2938 69.5735 44.6554L57.3759 87.8409H35.2857L28.2297 44.6554C27.4866 51.5197 26.8521 58.8263 26.3124 66.5534C25.7674 74.2886 25.5098 81.389 25.5098 87.8409H4.23584C5.09819 80.3715 6.00663 72.7068 6.95033 64.8414C7.89403 56.9705 8.65062 49.3627 9.2228 42.0345C9.78957 34.7116 10.0743 27.5082 10.0743 20.4458Z"
                fill="white"
            />
            <path
                d="M247.991 88.4944L245.626 90.8494C245.056 90.7273 244.557 90.6812 244.11 90.6812C243.202 90.6812 242.421 91.1912 241.748 92.2033C241.414 92.8734 241.819 93.7579 242.933 94.9001C243.375 95.3478 244.623 96.292 246.64 97.7707V98.1071C246.64 98.4381 246.814 99.6238 247.144 101.648C240.292 101.648 234.163 100.297 228.772 97.597C225.41 95.9149 220.176 92.3036 213.095 86.8013C209.958 84.1126 207.346 81.6409 205.264 79.3863C203.168 77.1262 201.017 74.3208 198.772 70.9537L179.214 87.1432L179.724 74.9991L192.529 59.4825C190.175 56.8941 188.085 54.0941 186.3 51.0499C185.053 48.4589 184.041 45.9709 183.263 43.548C182.482 41.1197 181.913 38.6372 181.576 36.0407C182.143 34.9283 182.42 34.1279 182.42 33.6857L181.913 31.4934L200.114 12.9381L214.617 36.7163L242.252 6.35864C242.825 5.68577 243.272 5.35205 243.603 5.35205C244.384 5.35205 244.788 6.87142 244.788 9.90203C244.788 12.371 244.623 14.9594 244.284 17.659C243.945 20.3613 243.711 22.2171 243.603 23.2318C242.594 28.3868 238.714 33.7318 231.972 39.2504C227.484 42.962 224.222 46.1689 222.201 48.8604C222.869 50.4151 223.438 51.6631 223.88 52.572C226.576 58.1747 228.881 62.6243 230.792 65.8937C233.596 70.6119 236.251 74.6002 238.714 77.8642C241.179 81.1118 244.284 84.6498 247.991 88.4944Z"
                fill="#ED1921"
                stroke="#ED1921"
                strokeWidth="2"
                strokeMiterlimit="2.613"
            />
        </svg>
    );

export default MaximaLogo;
