import * as React from 'react';

import './InputErrorMessage.scss';
import { DataId } from '../../common/utils/dataId';

export interface InputErrorMessageProps {
    children?: React.ReactNode;
    dataId?: DataId;
}

const InputErrorMessage: React.StatelessComponent<InputErrorMessageProps> = ({ dataId, children }: InputErrorMessageProps) => {
    return children ? (
        <p data-id={dataId ? `${dataId}.errorMessage` : 'inputErrorMessage'} className="input-error-message">
            {children}
        </p>
    ) : null;
};

export default InputErrorMessage;
