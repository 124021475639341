import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import * as React from 'react';
import { eventTrack } from '../../../common/analytics/gtm';
import { createDataId } from '../../../common/utils/dataId';

import { Button, ButtonType } from '../../../components/Buttons/Button';
import ContentBlockHeading from '../../../components/ContentBlock/ContentBlockHeading';
import { SelectOptionValue } from '../../../components/Select/SelectOption';
import { SUPPORTED_LANGUAGES } from '../loginHelper';

export interface LanguageModalProps {
    children?: React.ReactNode;
    t: TFunction;
    setLanguage: (locale: string) => void;
    selectedLanguage: string;
    closeModal: () => void;
}

interface LanguageModalValues {
    language: SelectOptionValue<string>;
}

const LanguageModal: React.FunctionComponent<LanguageModalProps> = (props: LanguageModalProps) => {
    const { t } = props;
    const initialValues: LanguageModalValues = {
        language: SUPPORTED_LANGUAGES.find((l) => l.value === props.selectedLanguage),
    };

    return (
        <Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize={true} validateOnBlur={false} validateOnChange={false}>
            {(formik) => (
                <Form autoComplete="off">
                    <ContentBlockHeading dataId="LanguageModalHeader">{t('views.login.language.modal')}</ContentBlockHeading>
                    <div className="login__supported-countries">
                        {SUPPORTED_LANGUAGES.map((lang) => (
                            <Button
                                isActive={lang.value === formik.values.language.value}
                                className="login__supported-country"
                                dataId={createDataId('langButton', lang.value)}
                                buttonType={ButtonType.FLUID}
                                key={lang.value}
                                onClick={() => {
                                    props.setLanguage(lang.value);
                                    eventTrack({
                                        event: 'change_language',
                                        label: lang.value,
                                    });
                                    props.closeModal();
                                }}
                                icon={lang.icon}
                            >
                                {lang.text}
                            </Button>
                        ))}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LanguageModal;
