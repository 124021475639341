import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { BaseSearch, FileDTO, PagedListContainer, ProductItemDTO } from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

export class ProductItemService {
    constructor(private axios: AxiosInstance) {}

    getProductItems = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<ProductItemDTO>>> => {
        return this.axios.post('/ProductItem/GetList', searchParams);
    };

    saveProductItem = async (account: ProductItemDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/ProductItem/SaveItem', account);
    };

    deleteProductItem = async (id: number, searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<ProductItemDTO>>> => {
        return this.axios.post(`/ProductItem/DeleteItem?itemId=${id}`, searchParams);
    };

    uploadCSVFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<PagedListContainer<ProductItemDTO>>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/ProductItem/UploadCSVFile', file, config);
    };
}

export default ProductItemService;
