import * as React from 'react';
import { useEffect } from 'react';

export interface KeyboardFocuserProps {}
export const USING_KB = 'using-kb';

/**
 * Adds class "using-kb" to body tag whenever user uses keyboard, useful if you need to use focus styles only for keyboard focus
 * used for Checkbox, Switch keyboard focus styles
 * @param props
 * @constructor
 */
const KeyboardFocuser: React.FunctionComponent<KeyboardFocuserProps> = () => {
    useEffect(() => {
        function removeClass() {
            document.body.classList.remove(USING_KB);
        }
        function addClass() {
            document.body.classList.add(USING_KB);
        }

        // Let the document know when the mouse/keyboard is being used,
        // so accessibility styling can be added/removed.
        document.body.addEventListener('mousedown', removeClass);
        document.body.addEventListener('keydown', addClass);

        return function cleanup() {
            document.body.removeEventListener('mousedown', removeClass);
            document.body.removeEventListener('keydown', addClass);
        };
    }, []);

    return null;
};

export default KeyboardFocuser;
