import isValid from 'date-fns/esm/isValid';
import parseISO from 'date-fns/esm/parseISO';
import format from 'date-fns/esm/format';
import numeral from 'numeral';
import 'numeral/locales/et';

numeral.locale('et');
numeral.localeData().delimiters.thousands = ',';
numeral.localeData().delimiters.decimal = '.';

export function formatMoneyAndCurrency(value: number, currency?: string): string {
    return `${formatMoney(value)}\u00A0${currency || 'EUR'}`;
}

export function formatMoney(value: number | string): string {
    return numeral(value).format('0,0.00');
}

export function formatNumber(value: number): string {
    return numeral(value).format('0.00');
}

export function formatThousandsWithDecimals(value: number | string): string {
    return numeral(value).format('0,0.[0000]');
}

/**
 * formatDate
 * @param value: Date
 * @param formatString: string - defaults to 'dd.MM.yyyy'
 * @returns date: string - date as string formatted according to the provided format string
 * @example formatDate(new Date(), 'HH:mm MM-dd-yyyy') // returns ex. '16:35 11-21-2018'
 */
export const formatDate = (value: Date | string, formatString = 'dd.MM.yyyy'): string => {
    if (typeof value === 'string') {
        value = parseISO(value);
    }
    if (isValid(value)) {
        return format(value, formatString);
    }
    return '';
};
