import { TFunction } from 'i18next';
import * as React from 'react';
import FormikField from '../../../common/utils/FormikField';

import { TextInputField, TextInputType } from '../../../components/TextInput/TextInput';

export function UsernameTab({ t }: { t: TFunction }) {
    return (
        <>
            <FormikField component={TextInputField} type={TextInputType.BORDERED} label={t('views.login.username.label')} name="username" dataId="username" />
            <FormikField component={TextInputField} type={TextInputType.BORDERED} label={t('views.login.password.label')} name="password" password={true} dataId="password" />
        </>
    );
}
