import { isUsingGoogleAnalytics } from '../utils/whitelabelHelper';

export interface GTMEventProperties {
    event?: string;
    category?: string;
    label?: string;
    value?: any;
    noninteraction?: any;
}

// inspired by https://github.com/angulartics/angulartics-google-tag-manager/blob/master/lib/angulartics-google-tag-manager.js
export function eventTrack(properties: GTMEventProperties = {}, userId?: any) {
    if (!isUsingGoogleAnalytics) {
        return;
    }
    const dataLayer: any[] = (window as any).dataLayer || [];
    dataLayer.push({
        event: properties.event,
        properties: {
            label: properties.label,
            value: properties.value,
        },
        userId: {
            id: userId,
        },
    });
}

export function sendPageView(path: string, userId?: any) {
    if (!isUsingGoogleAnalytics) {
        return;
    }
    const dataLayer: any[] = (window as any).dataLayer || [];
    dataLayer.push({
        event: 'content-view',
        'content-name': path,
        userId,
    });
}
