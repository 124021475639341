export const DECIMAL_DP_REGEXP = /^(\s*|\d+[.,]?\d*)$/;
export const DECIMAL_DP2_REGEXP = /^(\s*|\d+[.,]?\d{0,2})$/;
export const DECIMAL_DP4_REGEXP = /^(\s*|\d+[.,]?\d{0,4})$/;
export const INTEGER_REGEXP = /^\d*$/;
export const INTEGER_NATURAL_NUMBERS_REGEXP = /^(|[1-9]\d*)$/;
export const PERCENTAGE_REGEXP = /^(\s*|[0-9]|[1-9][0-9]|100)$/;
export const COST_OBJECTIVE_CODE_REGEXP = /^(?!xml|[0-9])([A-z_]?)([A-z0-9.\-:_]*?)$/i;
export const ALPHANUMERIC_REGEXP = /^[a-z0-9]*$/i;
export const ALPHANUMERIC_EXTENDED_REGEXP = /^[a-z0-9_/-]*$/i;
export const ALPHANUMERIC_SECOND_EXTENDED_REGEXP = /^[a-z0-9_õäöü-]*$/i;
export const DATE_PICKER_REGEXP = /^[0-9.]*$/i;
export const NUMBERS_DOT_SLASH = /^[0-9./]*$/i;
