import Tippy, { TippyProps } from '@tippy.js/react';
import { escape } from 'lodash-es';
import * as React from 'react';

require('tippy.js/dist/tippy.css');
require('tippy.js/dist/themes/light.css');
require('./Tooltip.scss');

export interface TooltipProps extends TippyProps {}

function sanitizeContent(content: any) {
    if (typeof content === 'string') {
        return escape(content);
    }

    if (typeof content === 'number') {
        return String(content);
    }
    return content;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({ children, content, ...rest }: TooltipProps) => {
    if (!content) {
        return children;
    }
    return (
        <Tippy content={sanitizeContent(content)} {...rest}>
            {children}
        </Tippy>
    );
};

Tooltip.defaultProps = {
    theme: 'dark',
    arrow: true,
    delay: [500, 0],
    performance: true,
    trigger: 'mouseenter', // maybe focus?
};

export default Tooltip;
