import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import '../../../Content/scss/core/_switch.scss';
import withFormikField from '../../common/utils/withFormikField';
import { createDataId } from '../../common/utils/dataId';

export interface SwitchProps {
    onChange: (checked: boolean) => void;
    name: string;
    value: boolean;
    dataId: string;
    disabled?: boolean;
    className?: string;
    label?: React.ReactNode;
    isAllowLabelClick?: boolean;
}

// refactor once we do not have angular implementations with the same CSS and HTML structure anymore, then restructure according to BEM
export const Switch: React.FunctionComponent<SwitchProps> = (props: SwitchProps) => {
    const [hasFocus, setHasFocus] = useState(false);

    const handleBlur = () => {
        setHasFocus(false);
    };
    const handleFocus = () => {
        setHasFocus(true);
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        props.onChange(e.target.checked);
    }
    function renderSwitch() {
        const classes = classNames('switch', props.label ? '' : props.className, { 'is-disabled': props.disabled }, { 'switch--has-focus': hasFocus });
        return (
            <label className={classes} data-id={createDataId('label', props.dataId)}>
                <input type="checkbox" onBlur={handleBlur} onFocus={handleFocus} disabled={props.disabled} name={props.name} data-id={props.dataId} checked={props.value} onChange={handleChange} />
                <span className="slider round" />
            </label>
        );
    }

    return props.label ? (
        <span data-id={createDataId('switch', props.dataId)} className={classNames('switch-wrapper', props.className, { 'is-disabled': props.disabled }, { 'switch-wrapper--has-focus': hasFocus })}>
            {renderSwitch()}
            <span className="switch-label" onClick={() => props.isAllowLabelClick && props.onChange(!props.value)}>
                {props.label}
            </span>
        </span>
    ) : (
        renderSwitch()
    );
};

export const SwitchField = withFormikField(Switch);
