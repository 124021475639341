import { AxiosInstance, AxiosResponse } from 'axios';
import { Response } from '../types/ApiTypes';

export default class LoginService {
    constructor(private loginApi: AxiosInstance, private idCardApi: AxiosInstance) {}

    loginWithUsername = async (request: LoginRequest): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/Login', request);
    };

    loginWithUsernameCaptcha = async (request: LoginRequest, token: string): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/Login/LoginWCaptcha', { Body: request, Token: token });
    };

    loginWithEmailCaptcha = async (request: EmailLoginRequest, token: string): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/Login/EmailLoginWCaptcha', { Body: request, Token: token });
    };

    loginWithIdtoken = async (request: IdLoginRequest, token: string): Promise<AxiosResponse<LoginUsersDTO>> => {
        return this.loginApi.post('/Login/v2/IdLogin/IdLoginWCaptcha', { Body: request, Token: token });
    };

    loginWithIdtokenCaptcha = async (request: IdLoginRequest, token: string): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/Login/IdLoginWCaptcha', { Body: request, Token: token });
    };

    loginWithMobileId = async (request: MobileIdLoginRequest): Promise<AxiosResponse<MobileIdLoginResponse>> => {
        return this.loginApi.post('/Mobile/MobileLogin', request);
    };

    loginWithMobileIdCaptcha = async (request: MobileIdLoginRequest, token: string): Promise<AxiosResponse<MobileIdLoginResponse>> => {
        return this.loginApi.post('/Mobile/MobileLoginWCaptcha', { Body: request, Token: token });
    };

    mobileCheck = async (request: MobileIdCheckRequest): Promise<AxiosResponse<MobileIdCheckResponse>> => {
        return this.loginApi.post('/Mobile/MobileCheck', request);
    };

    mobileCheckV2 = async (request: MobileIdCheckRequest): Promise<AxiosResponse<LoginUsersDTO>> => {
        return this.loginApi.post('v2/Mobile/MobileCheck', request);
    };

    loginWithIdCard = async (): Promise<AxiosResponse<any>> => {
        return this.idCardApi.post('/', {});
    };

    ipInfo = async (): Promise<AxiosResponse<IpInfoResponse>> => {
        return this.loginApi.get('/Login/IpInfo', { timeout: 3000 });
    };

    loginCheckEmailExists = async (email: string): Promise<AxiosResponse<EmailStatus>> => {
        return this.loginApi.get(`/Login/CheckEmailExists?email=${encodeURIComponent(email)}`);
    };

    sendPasswordResetLink = async (email: string): Promise<AxiosResponse<Response<any>>> => {
        return this.loginApi.get(`/Login/SendPasswordResetLink?email=${encodeURIComponent(email)}`);
    };

    loginWithSmartId = async (request: SmartIDStartDTO): Promise<AxiosResponse<SmartIdAuthorizationResponse>> => {
        return this.loginApi.post('/SmartId/Start', request);
    };

    loginWithSmartIdCaptcha = async (request: SmartIDStartDTO, token: string): Promise<AxiosResponse<SmartIdAuthorizationResponse>> => {
        return this.loginApi.post('/SmartId/StartWCaptcha', { Body: request, Token: token });
    };

    smartIdCheck = async (request: SmartIdCheckRequest): Promise<AxiosResponse<SSOSessionDTO>> => {
        return this.loginApi.post('/SmartId/Check', request);
    };

    smartIdCheckV2 = async (request: SmartIdCheckRequest): Promise<AxiosResponse<LoginUsersDTO>> => {
        return this.loginApi.post('/v2/SmartId/Check', request);
    };

    createSession = async (request: CreateSession): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/v1/CreateSession', request);
    };

    serbiaSSORedirection = (): string => {
        return this.loginApi.defaults.baseURL + '/SSO/SerbiaLogin';
    };

    serbiaSSOLogout = (): string => {
        return this.loginApi.defaults.baseURL + '/SSO/SerbiaLogout?force=true';
    };

    getKeycloakAuthUrl = async (request: AuthUrlRequest): Promise<AxiosResponse<string>> => {
        return this.loginApi.post('/SSO/GetKeycloakAuthUrl', request);
    };

    getKeycloakLogoutUrl = async (request: RedirectUriRequest): Promise<AxiosResponse<string>> => {
        return this.loginApi.post('/SSO/GetKeycloakLogoutUrl', request);
    };

    keycloakLogin = async (request: CodeRequest): Promise<AxiosResponse<Session>> => {
        return this.loginApi.post('/SSO/KeycloakLogin', request);
    };

    getOnboardingData = async (request: OnboardDataRequest): Promise<AxiosResponse<OnboardDTO>> => {
        return this.loginApi.post('/Onboarding/PrefillData', request);
    };

    onboardRequest = async (request: OnboardSubmitRequest): Promise<AxiosResponse<OnboardSubmitResponse>> => {
        return this.loginApi.post('/Onboarding/Onboard', request);
    };

    onboardCommunityCreditors = async (communityApiToken: string): Promise<AxiosResponse<CommunityCreditorDTO[]>> => {
        return this.loginApi.post('/Onboarding/CommunityCreditors', { CommunityApiToken: communityApiToken });
    };

    onboardAddSuppliers = async (request: AddSupplierDTO[], companyGuid: string): Promise<AxiosResponse<AddSupplierDTO[]>> => {
        return this.loginApi.post('/Onboarding/AddSuppliers?companyGuid=' + companyGuid, request);
    };
}

interface LoginBrowserInfo {
    BrowserName?: string;
    BrowserVersion?: string;
    DeviceName?: string;
    AppVersion?: string;
}

export interface LoginRequest extends LoginBrowserInfo {
    Username: string;
    Password: string;
}

export interface EmailLoginRequest extends LoginBrowserInfo {
    Email: string;
    Password: string;
}

export interface IdLoginRequest extends LoginBrowserInfo {
    idCardToken: string;
}

export interface MobileIdLoginRequest {
    Username: string;
    PhoneNo: string;
}

export interface SmartIdCheckRequest extends LoginBrowserInfo {
    sessionId: string;
}

export interface MobileIdLoginResponse {
    SessId: number;
    CheckCode: string;
    IsError: boolean;
    Error: string;
    Token: string;
    AuthStatus: AuthStatus;
    Country: string;
}

export interface MobileIdCheckRequest extends LoginBrowserInfo {
    SessId?: number;
    Token: string;
    Country: string;
}

export interface MobileIdCheckResponse {
    IsAuthenticated: boolean;
    IsWaiting: boolean;
    IsError: boolean;
    Error: string;
    Session: Session;
}

export interface Session {
    Token: string;
    ExpirationTime: string;
    UserId: number;
    UserLastCompanyGuid: string;
    UserCompanies: UserCompany[];
    IsBoAdmin: boolean;
    IsBetaUser: boolean;
    AuthStatus: AuthStatus;
}

export enum AuthStatus {
    NotFoundUser = 0,
    WrongCredentials = 1,
    GoodCredentials = 2,
    NotActiveUser = 3,
    ActiveUser = 4,
    NoAvailableCompanies = 14,
}

export enum EmailStatus {
    NotExist = 1,
    ExistsVerified = 2,
    ExistsNotVerified = 3,
}

export interface UserCompany {
    Id: number;
    Name: string;
}

export interface IpInfoResponse {
    IpAddress: string;
    Country: string;
    CountryCode: string;
    PhoneCode: string;
    OfficialLanguageCode: string;
    Region: string;
    Currency: string;
    FirstDay: string;
    StandardVatRate: string;
    IsEuCountry: boolean;
    Status: string;
}

export interface SmartIDStartDTO {
    CountryCode: string;
    PersonalCode: string;
    DisplayText: string;
}

export interface SmartIdAuthorizationResponse {
    sessionID: string;
    code: string;
}

export enum SSOAuthStatus {
    NotFoundUser = 0,
    WrongCredentials = 1,
    GoodCredentials = 2,
    NotActiveUser = 3,
    ActiveUser = 4,
    RefreshTokenException = 5,
    RefreshTokenSecurityViolation = 6,
    UserRefused = 7,
    Timeout = 8,
    DocumentUnusable = 9,
    WrongVC = 10,
    RequestPending = 11,
    PhoneAbsent = 12,
}

export interface SSOSessionDTO extends Omit<Session, 'AuthStatus'> {
    AuthStatus: SSOAuthStatus;
}

export interface AuthUrlRequest {
    RedirectUri: string;
    Locale: string;
    Verifier: string;
}

export interface RedirectUriRequest {
    RedirectUri: string;
}

export interface CodeRequest {
    Code: string;
    RedirectUri: string;
}

export interface OnboardDataRequest {
    OnboardToken: string;
    KeycloakCode: string;
    Verifier: string;
    RedirectUri: string;
}

export interface OnboardSubmitRequest {
    VATCode: string;
    FirstName: string;
    LastName: string;
    CommunityApiToken: string;
    OnboardToken: string;
}

export interface OnboardSubmitResponse {
    Success: boolean;
    Status: string;
    Message: string;
    Session: Session;
}

export interface CommunityCreditorDTO {
    name: string;
    legal_entity_trn: string;
    vat_code?: string;
}

export interface AddSupplierDTO {
    Name: string;
    RegistrationCode: string;
    VatCode?: string;
}

export interface OnboardDTO {
    User: OnboardUserDTO;
    Company: OnboardCompanyDTO;
    CommunityApiToken: string;
}

export interface OnboardCompanyDTO {
    Country: string;
    RegistrationNumber: string;
    CompanyName: string;
    VATCode: string;
}

export interface OnboardUserDTO {
    Email: string;
    FirstName: string;
    LastName: string;
}

export interface LoginUsersDTO {
    AuthStatus: SSOAuthStatus;
    Users: LoginUserDTO[];
}

export interface LoginUserDTO {
    Country: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Token: string;
    PersonalCode?: string;
}

export interface CreateSession extends LoginBrowserInfo {
    Country: string;
    Token: string;
    DeviceName: string;
    AppVersion: string;
}
