import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

import './ContentBlock.scss';
import { DataId } from '../../common/utils/dataId';

export interface ContentBlockProps {
    loading?: boolean;
    className?: string;
    isMuted?: boolean;
    dataId?: DataId;
}

export class ContentBlock extends BaseComponent<ContentBlockProps> {
    render() {
        const { loading, className, isMuted, dataId } = this.props;
        const classes = classNames('content-block', className, { 'content-block--loading': loading }, { 'content-block--muted': isMuted });
        return (
            <div className={classes} data-id={dataId || 'contentBlock'}>
                {this.props.children}
            </div>
        );
    }
}
