import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import Calendar from 'react-calendar';
import isAfter from 'date-fns/isAfter';

import { NUMBERS_DOT_SLASH } from '../../common/utils/validators';
import { formatDate } from '../../common/utils/formatters';
import { createDataId, WithDataId } from '../../common/utils/dataId';
import withFormikField from '../../common/utils/withFormikField';
import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import i18n from '../../i18n';
import { TextInput, TextInputType, TextInputProps } from '../TextInput/TextInput';
import Icon, { ICONS } from '../Icon/Icon';

import './CalendarDatePicker.scss';

export const dataId = 'calendar-date-picker';

export interface CalendarDatePickerProps extends WithDataId {
    activeStartDate?: Date;
    alwaysOpen?: boolean;
    dateFormat?: string;
    disabled?: boolean;
    error?: React.ReactNode;
    inputProps?: Partial<TextInputProps>;
    isRange?: boolean;
    label?: string;
    label2?: string;
    name?: string;
    onBlur?: () => void;
    onChange?: (d: Date | [Date, Date]) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    readOnly?: boolean;
    showDoubleView?: boolean;
    value: Date | [Date, Date] | string;
    wrapperClass?: string;
}

const isDate = (obj: any): boolean => {
    return obj instanceof Date && !isNaN(obj.valueOf());
};

const parseInputStringToDate = (str: string): Date | null => {
    if (!str) {
        return null;
    }
    const val = str
        .split('.')
        .join('/')
        .split('/');
    const [day, month, year] = val;
    const newDate = new Date(`${month}/${day}/${year}`);
    return isDate(newDate) ? newDate : null;
};

const CalendarDatePicker = (props: CalendarDatePickerProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [outsideEventListener, setOutsideEventListener] = useState<OutsideEventListener>();
    const [firstValue, setFirstValue] = useState<Date>(null);
    const [secondValue, setSecondValue] = useState<Date>(null);
    const [firstError, setFirstError] = useState<string>(null);
    const [secondError, setSecondError] = useState<string>(null);
    const [activeStartDate, setActiveStartDate] = useState<Date>();
    const pickerRef = useRef();

    const firstInputRef = useRef(null);
    const secondInputRef = useRef(null);
    const [activeInput, setActiveInput] = useState<1 | 2>(null);

    const registerOutsideListener = () => {
        if (outsideEventListener) {
            outsideEventListener.stop();
        }
        const outsideListener = new OutsideEventListener(pickerRef, () => setIsOpen(false), false);
        outsideListener.start();
        setOutsideEventListener(outsideListener);
    };

    const unregisterOutsideListener = () => {
        if (outsideEventListener) {
            outsideEventListener.stop();
            setOutsideEventListener(null);
        }
    };

    const setValues = (values: Date | [Date, Date]) => {
        if (!values) {
            return;
        }
        if (Array.isArray(values)) {
            values[0] !== firstValue && setFirstValue(values[0]);
            values[1] !== secondValue && setSecondValue(values[1]);
            setActiveStartDate(values[0]);
        } else {
            firstValue !== values && setFirstValue(values);
            setSecondValue(null);
            setActiveStartDate(values);
        }
    };

    useEffect(() => {
        if (typeof props.value === 'string') {
            const dateFromProps = new Date(props.value);
            isDate(dateFromProps) && setValues(dateFromProps);
        } else {
            setValues(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (isOpen) {
            registerOutsideListener();
        } else {
            unregisterOutsideListener();
        }
        props.activeStartDate && setActiveStartDate(props.activeStartDate);
    }, [isOpen]);

    const onBlur = () => {
        props.onBlur && props.onBlur();
    };

    const onChange = (v: Date | [Date, Date]) => {
        if (v) {
            setIsOpen(false);
            if (props.onChange) {
                if (props.isRange) {
                    if (!activeInput) {
                        // user has not focused a particulat input field for a change, onChange triggers when both dates are selected
                        props.onChange([new Date(v[0]?.toDateString()) || firstValue, new Date(v[1]?.toDateString()) || secondValue]);
                    } else if (activeInput === 1) {
                        setActiveInput(null);
                        firstInputRef?.current?.focus(); // this is needed to update calendar selection
                        const newFirstValue = new Date((Array.isArray(v) ? v[0] : (v as Date))?.toDateString()) || firstValue;
                        if (isAfter(newFirstValue, secondValue)) {
                            setFirstError('Start date should be before the end one');
                        } else {
                            setFirstValue(newFirstValue);
                            props.onChange([newFirstValue, secondValue]);
                        }
                    } else {
                        setActiveInput(null);
                        secondInputRef?.current?.focus(); // this is needed to update calendar selection
                        const newSecondDate = new Date((Array.isArray(v) ? v[1] : (v as Date))?.toDateString()) || secondValue;
                        if (isAfter(firstValue, newSecondDate)) {
                            setSecondError(i18n.t('component.datePicker.startDateShouldBeBefore'));
                        } else {
                            setSecondValue(newSecondDate);
                            props.onChange([firstValue, newSecondDate]);
                        }
                    }
                } else {
                    props.onChange(new Date((v as Date).toDateString()));
                }
            }
        }
    };

    const getDays = () => {
        return ['date.day.Sun', 'date.day.Mon', 'date.day.Tue', 'date.day.Wed', 'date.day.Thu', 'date.day.Fri', 'date.day.Sat'].map((s) => i18n.t(s));
    };

    const getMonths = (): string[] =>
        [
            'date.month.January',
            'date.month.February',
            'date.month.March',
            'date.month.April',
            'date.month.May',
            'date.month.June',
            'date.month.July',
            'date.month.August',
            'date.month.September',
            'date.month.October',
            'date.month.November',
            'date.month.December',
        ].map((s) => i18n.t(s));

    const updateFirstDate = (v: string) => {
        firstError && setFirstError(null);
        const newDate = parseInputStringToDate(v);
        // a check to prevent updating the date from input blur event (leads to unchangable value) when selected from a picker pop-up
        if (v !== formatDate(firstValue, props.dateFormat)) {
            if (newDate) {
                setFirstValue(newDate);
                onChange(props.isRange ? [newDate, secondValue] : newDate);
            } else if (v) {
                setFirstError(i18n.t('component.datePicker.invalidDate'));
            } else {
                setFirstValue(null);
                props.onChange && props.onChange(null);
            }
        }
    };

    const updateSecondDate = (v: string) => {
        secondError && setSecondError(null);
        const newDate = parseInputStringToDate(v);
        // a check to prevent updating the date from input blur event (leads to unchangable value) when selected from a picker pop-up
        if (v !== formatDate(secondValue, props.dateFormat)) {
            if (newDate) {
                setSecondValue(newDate);
                onChange([firstValue, newDate]);
            } else if (v) {
                setSecondError(i18n.t('component.datePicker.invalidDate'));
            }
        }
    };

    const onTextInputFocus = (inputNo: 1 | 2) => {
        !props.isRange && setIsOpen(true);
        setActiveInput(inputNo);
        setActiveStartDate(inputNo === 2 ? secondValue : firstValue);
    };

    return (
        <div className={classNames('calendar-wrapper', props.wrapperClass)}>
            <div className="calendar-inputs">
                <TextInput
                    blurOnEnter
                    disabled={props.disabled}
                    name={props.name}
                    label={props.label}
                    type={TextInputType.COMPACT}
                    dataId={createDataId(props.dataId, dataId, 'input')}
                    error={props.error || firstError}
                    onFocus={() => onTextInputFocus(1)}
                    onBlur={onBlur}
                    onKeyDown={props.onKeyDown || noop}
                    value={formatDate(firstValue, props.dateFormat)}
                    onlyChangeOnBlur
                    onChange={(e) => updateFirstDate(e.target?.value)}
                    ref={firstInputRef}
                    replaceComma
                    restoreOnEsc
                    validCharacters={NUMBERS_DOT_SLASH}
                    {...props.inputProps}
                />

                {props.isRange && (
                    <TextInput
                        blurOnEnter
                        disabled={props.disabled}
                        name={props.name}
                        label={props.label2 || props.label}
                        type={TextInputType.COMPACT}
                        dataId={createDataId(props.dataId, dataId, 'input')}
                        error={props.error || secondError}
                        onFocus={() => onTextInputFocus(2)}
                        onBlur={onBlur}
                        onKeyDown={props.onKeyDown || noop}
                        value={formatDate(secondValue, props.dateFormat)}
                        onlyChangeOnBlur
                        onChange={(e) => updateSecondDate(e.target?.value)}
                        ref={secondInputRef}
                        replaceComma
                        restoreOnEsc
                        validCharacters={NUMBERS_DOT_SLASH}
                        {...props.inputProps}
                    />
                )}
            </div>

            {(isOpen || props.alwaysOpen) && (
                <Calendar
                    activeStartDate={activeStartDate}
                    allowPartialRange={!!activeInput}
                    className={classNames('calendar', { 'calendar--always-open': props.alwaysOpen })}
                    data-id={createDataId(props.dataId, dataId, 'calendar')}
                    inputRef={pickerRef}
                    formatShortWeekday={(l, d) => getDays()[d.getDay()]}
                    formatMonth={(l, d) => getMonths()[d.getMonth()]}
                    goToRangeStartOnSelect={false}
                    nextLabel={<Icon name={ICONS.CHEVRON_RIGHT} />}
                    next2Label={null}
                    onChange={onChange}
                    onActiveStartDateChange={(change) => setActiveStartDate(change.activeStartDate)}
                    prevLabel={<Icon name={ICONS.CHEVRON_LEFT} />}
                    prev2Label={null}
                    returnValue={activeInput === 1 ? 'start' : activeInput === 2 ? 'end' : 'range'}
                    showDoubleView={props.showDoubleView}
                    selectRange={!activeInput}
                    value={[firstValue, secondValue]}
                />
            )}
        </div>
    );
};

export default CalendarDatePicker;

export const CalendarDatePickerField = withFormikField(CalendarDatePicker);
