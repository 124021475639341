import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button, ButtonType } from '../Buttons/Button';
import { createDataId } from '../../common/utils/dataId';
import { ICONS } from '../Icon/Icon';
import { formatDate } from '../../common/utils/formatters';
import { TextInput, TextInputType } from '../TextInput/TextInput';
import CalendarDateRangeModal, { areDateRangeValuesEmpty, DateRangeValues } from '../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { SelectOptionValue } from '../Select/SelectOption';
import { addDaysFromDate, getDifferenceInDays, subtractDaysFromDate } from '../../common/utils/datetime';

interface Props extends WithTranslation {
    wrapperClass?: string;
    dataId: string;
    datePickerActiveScope: string;
    datePickerScopeList?: SelectOptionValue<string>[];
    dateRange: [Date, Date];
    label: string;
    placeholder?: string;
    onChange: (val: DateRangeValues) => void;
}

const DateRangeFilter = ({ wrapperClass, dataId, datePickerActiveScope, datePickerScopeList, dateRange, label, placeholder, onChange, t }: Props) => {
    const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false);
    const [rangeInDays, setRangeInDays] = useState<number>();

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            setRangeInDays(getDifferenceInDays(dateRange[1], dateRange[0]));
        }
    }, [dateRange]);

    const shiftRange = (isNext?: boolean) => {
        let firstDate: Date, secondDate: Date;
        if (isNext) {
            firstDate = addDaysFromDate(dateRange[1], 1);
            secondDate = addDaysFromDate(firstDate, rangeInDays);
        } else {
            secondDate = subtractDaysFromDate(dateRange[0], 1);
            firstDate = subtractDaysFromDate(secondDate, rangeInDays);
        }
        onChange({ value: [firstDate, secondDate] });
    };

    const isDateShiftDisabled = () => !(dateRange.length && dateRange[0] && dateRange[1]);

    return (
        <div className={`${wrapperClass || ''} named-block`}>
            <h4>{label}</h4>
            <div className="named-block__subblock">
                <Button
                    className="date-arrow-btn"
                    onClick={() => shiftRange()}
                    disabled={isDateShiftDisabled()}
                    buttonType={ButtonType.ICON}
                    dataId={createDataId(dataId, 'date-filter-prev')}
                    icon={ICONS.CHEVRON_LEFT}
                ></Button>
                <Button
                    className="date-arrow-btn"
                    onClick={() => shiftRange(true)}
                    disabled={isDateShiftDisabled()}
                    buttonType={ButtonType.ICON}
                    dataId={createDataId(dataId, 'date-filter-next')}
                    icon={ICONS.CHEVRON_RIGHT}
                ></Button>
                <TextInput
                    placeholder={placeholder || t('component.invoiceFilter.customDates')}
                    dataId={createDataId(dataId, 'text-input')}
                    onChange={(e) => {
                        e.stopPropagation();
                        onChange({ value: [null, null] });
                    }}
                    onKeyDown={() => setIsDateRangeOpen(true)}
                    value={!areDateRangeValuesEmpty(dateRange) && `${formatDate(dateRange?.[0])} - ${formatDate(dateRange?.[1])}`}
                    type={TextInputType.DATE_PICKER}
                    showClear
                    icon={areDateRangeValuesEmpty(dateRange) && ICONS.CALENDAR}
                    onClick={() => setIsDateRangeOpen(true)}
                />
                {isDateRangeOpen && (
                    <CalendarDateRangeModal scope={datePickerActiveScope} scopeOptions={datePickerScopeList} value={dateRange} onChange={onChange} onClose={() => setIsDateRangeOpen(false)} />
                )}
            </div>
        </div>
    );
};

export default withTranslation()(DateRangeFilter);
