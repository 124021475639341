import classNames from 'classnames';
import React from 'react';
import { Menu } from 'react-aria-menubutton';

import { addDataId } from '../../common/utils/dataId';
import Scrollbars from '../Scrollbars/Scrollbars';

export interface SelectMenuProps {
    children?: React.ReactNode;
    className?: string;
    tag?: string; // default 'span'
    menuHeight?: number;
    dataId?: string;
    menuOpenUp?: boolean;
}

export const SelectMenu = ({ children, className, menuHeight = 250, menuOpenUp = false, dataId, ...rest }: SelectMenuProps) => {
    const classes = classNames('select__menu', className);
    return (
        <Menu className={classes} {...rest} style={{ top: `${menuOpenUp ? -menuHeight + 'px' : 'inherit'}` }}>
            <Scrollbars className={'scrollbars'} autoHeight={true} autoHeightMax={menuHeight} data-id={addDataId(dataId, '.scrollbarMenu')}>
                {children}
            </Scrollbars>
        </Menu>
    );
};
