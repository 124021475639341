import { AxiosInstance, AxiosResponse } from 'axios';

import { AutoTransactionDTO, AutoTransactionMeta, AutoTransactionRuleSimpleDTO, AutoTransactionUiObjectDTO, BaseSearch, PagedListContainerOfAutoTransactionsDTO } from '../types/ApiTypes';

export default class AutoTransactionService {
    constructor(private axios: AxiosInstance) {}

    getEmptyUiObject = async (): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.get('/AutoTransactions/GetEmptyUiObject');
    };

    updateAutoTransaction = async (autoTransaction: AutoTransactionUiObjectDTO): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.post('/AutoTransactions/SaveAutoTransaction', autoTransaction);
    };

    deleteAutoTransaction = async (atId: number): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.get(`/AutoTransactions/DeleteAutoTransaction`, {
            params: {
                atId,
            },
        });
    };

    duplicateAutoTransaction = async (atId: number): Promise<AxiosResponse<AutoTransactionDTO>> => {
        return this.axios.get(`AutoTransactions/DuplicateAutoTransaction`, {
            params: {
                atId,
            },
        });
    };

    getAutoTransactionsList = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOfAutoTransactionsDTO>> => {
        return this.axios.post(`AutoTransactions/GetList`, searchParams);
    };

    getAutoTransactionByNamePart = async (query: string, resultLimit: number): Promise<AxiosResponse<AutoTransactionRuleSimpleDTO[]>> => {
        return this.axios.get(`AutoTransactions/GetByNamePart`, {
            params: {
                searchParam: query || '',
                amount: resultLimit,
            },
        });
    };

    toggleRuleActivity = async (atId: number): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.get(`AutoTransactions/ToggleRuleActivity`, {
            params: {
                atId,
            },
        });
    };

    getAutoTransactionForUiById = async (id: number): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.get('/AutoTransactions/GetAutoTransactionForUiById' + (id ? '?id=' + id : ''));
    };

    getAutoTransactionSnapshotForInvoice = async (id: number): Promise<AxiosResponse<AutoTransactionUiObjectDTO>> => {
        return this.axios.get('/AutoTransactions/GetAutoTransactionSnapshotForInvoice', {
            params: {
                invoiceId: id,
            },
        });
    };

    getLastMetaInfosBySupplier = async (id: number, name: string): Promise<AxiosResponse<AutoTransactionMeta[]>> => {
        return this.axios.get('AutoTransactions/GetLastMetaInfosBySupplier?searchString=' + name + (id ? '&supplierId=' + id : ''));
    };

    checkAutoTransactionNameForDuplicates = async (atName: string, currentAtId: number): Promise<AxiosResponse<string>> => {
        return this.axios.get('AutoTransactions/checkAutoTransactionNameForDuplicates?atName=' + encodeURIComponent(atName) + '&currentAtId=' + currentAtId);
    };

    exportAutoTransactions = async (search: BaseSearch, exportType: string): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/AutoTransactions/ExportAutoTransactions?exportType=${exportType}`, search, {
            responseType: 'blob',
        });
    };
}
