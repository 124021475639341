import { AxiosInstance, AxiosResponse } from 'axios';

import {
    BaseSearch,
    InvoiceDashboardExtDTO,
    InvoiceDashboardMinDTO,
    InvoiceDataResponseDTO,
    PagedListContainerOf,
    InvoiceDTO,
    InvoiceSearchOptions,
    InvoiceSliderMinDTO,
    PagedListContainer,
    HistoryDTO,
    ReplaceTasksInWorkflow,
    InvoiceCustomizationFieldsSearchParams,
    InvoiceCustomizationDTO,
    FileDTO,
    InvoiceRegistryMinDTO,
    AttachmentType,
    AttachmentFilesCountResponseDTO,
    Currency,
    IsibVerificationDTO,
    InvoiceHeaderHistoryDTO,
} from '../types/ApiTypes';

export default class InvoiceService {
    constructor(private axios: AxiosInstance) {}

    checkIsDuplicate = async (invoiceData: Partial<InvoiceDTO>) => {
        return this.axios.post('invoice/CheckIsDuplicate', invoiceData);
    };

    changeInvoiceCompany = async (companyId: string, invoiceId: number): Promise<AxiosResponse<InvoiceDTO>> => {
        return this.axios.get(`invoice/ChangeInvoiceCompany?companyReceiverGuid=${companyId}&invoiceId=${invoiceId}`);
    };

    getAvailableCustomizationFields = async (searchParams?: InvoiceCustomizationFieldsSearchParams): Promise<AxiosResponse<PagedListContainer<InvoiceCustomizationDTO>>> => {
        return this.axios.get('Invoice/GetAvailableCustomizationFields', { params: searchParams });
    };

    getCurrencyList = async (): Promise<AxiosResponse<Currency[]>> => {
        return this.axios.get('Currency/GetAll');
    };

    getSliderInvoicesMin = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceSliderMinDTO>>> => {
        return this.axios.post('Invoice/GetSliderInvoicesMin', {
            ...searchParams,
        });
    };

    getDashboardInvoicesMin = async (searchParams: InvoiceSearchOptions): Promise<AxiosResponse<PagedListContainer<InvoiceDashboardMinDTO>>> => {
        return this.axios.post(`Invoice/GetDashboardInvoicesMin`, {
            ...searchParams,
        });
    };

    getDashboardInvoiceExt = async (invoiceId: string): Promise<AxiosResponse<InvoiceDashboardExtDTO>> => {
        return this.axios.get(`Invoice/GetDashboardInvoiceExt?invoiceId=${invoiceId}`);
    };

    getRegistryInvoicesMin = async (searchParams: InvoiceSearchOptions): Promise<AxiosResponse<PagedListContainer<InvoiceRegistryMinDTO>>> => {
        return this.axios.post(`Invoice/GetRegistryInvoicesMin`, {
            ...searchParams,
        });
    };

    getInvoiceData = async (invoiceId: string): Promise<AxiosResponse<InvoiceDataResponseDTO>> => {
        return this.axios.get(`Invoice/GetInvoiceDataById/${invoiceId}`);
    };

    getInvoiceHeaderHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetInvoiceHeaderHistory', search);
    };

    getPurchaseOrdersLinkingHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetPurchaseOrdersLinkingHistory', search);
    };

    getInvoiceCustomFieldsHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetInvoiceCustomFieldsHistory', search);
    };

    deleteInvoiceFile = async (file: FileDTO) => {
        return this.axios.post('Invoice/DeleteInvoiceFile', file);
    };

    downloadFileById = async (id: string, fromArchive: boolean, withFrontPage: boolean, language: string) => {
        const date = new Date();
        const offsetInHours = date.getTimezoneOffset() / 60;
        return this.axios.post(
            `File/DownloadFile/${id}?fromArchive=${fromArchive}&withFrontPage=${withFrontPage}&language=${language}&offsetInHours=${offsetInHours}`,
            {},
            {
                responseType: 'blob',
            },
        );
    };

    displayFileById = async (id: number, fromArchive?: string) => {
        return this.axios.get(`File/DisplayFileById/${id}${fromArchive ? `${fromArchive}` : ''}`, { responseType: 'blob' });
    };

    uploadInvoiceFile = async (file: FileDTO) => {
        return this.axios.post('Invoice/UploadInvoiceFile', file);
    };

    getAllAttachmentFilesSize = async (attachmentType: AttachmentType, objectId: number): Promise<AxiosResponse<AttachmentFilesCountResponseDTO>> => {
        return this.axios.get(`File/GetAllAttachmentFilesSize?attachmentType=${attachmentType}&objectId=${objectId}`);
    };

    exportStatusReset = async (invoiceId: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`Invoice/ExportStatusReset/${invoiceId}`);
    };

    updateInvoiceExportSetting = async (invoiceId: string, enabledExport: boolean): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`Invoice/UpdateInvoiceExportSetting/${invoiceId}?enabledExport=${enabledExport}`);
    };

    getInvoicesSmall = async (params: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<InvoiceDTO>>> => {
        return this.axios.post('invoice/GetInvoicesSmall', params);
    };

    getInvoiceHistory = async (invoiceId: number): Promise<AxiosResponse<HistoryDTO[]>> => {
        return this.axios.get('Invoice/GetInvoiceHistory', {
            params: {
                invoiceId,
            },
        });
    };

    getInvoiceTransactionRowsVatRates = async (invoiceId: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get(`/Invoice/GetInvoiceTransactionRowsVatRates?invoiceId=${invoiceId}`);
    };

    areTransactionRowsValidPenultimate = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreTransactionRowsValidPenultimate/' + id);
    };

    areTransactionRowsValid = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreTransactionRowsValid/' + id);
    };

    areCustomFieldsValid = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreCustomFieldsValid/' + id);
    };

    replaceTasks = async (workFlowObject: ReplaceTasksInWorkflow, confirmFirst: boolean): Promise<AxiosResponse<any>> => {
        return this.axios.post('invoice/ReplaceTasksInWorkflow?confirmFirst=' + confirmFirst, workFlowObject);
    };

    saveInvoiceCopy = async (originalId: number, invoice: InvoiceDTO): Promise<AxiosResponse<InvoiceDTO>> => {
        return this.axios.post('invoice/SaveCopy?originalId=' + originalId, invoice, { headers: { 'Content-Type': 'application/json' } });
    };

    setInvoiceDeleted = async (id: number): Promise<AxiosResponse<any>> => {
        return this.axios.post('invoice/SetInvoiceToDeleted/', id, { headers: { 'Content-Type': 'application/json' } });
    };

    checkTransactionRowsBudget = async (invoiceId: number): Promise<AxiosResponse<IsibVerificationDTO[]>> => {
        return this.axios.get('TransactionRow/VerifyIsibFunds?invoiceId=' + invoiceId);
    };
}
