import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeResponseDTO, BackOfficeUserDTO, UserNewCompanyAccessDTO, UserPasswordSetupDTO, UserPasswordSetupInitiationDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, BooleanResponse } from '../types/ApiTypes';
import { getWhitelabelParameter } from '../../../src/common/utils/whitelabelHelper';

export default class BoUsersService {
    constructor(private axios: AxiosInstance) {}

    getUserById = async (userGuid: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get(`/User/GetById?userGuid=${userGuid}`);
    };
    getCurrentUser = async (): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get('/User/GetCurrentUser');
    };
    getUsers = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeUserDTO>>> => {
        return this.axios.post('/User/GetUsers', searchParams);
    };
    saveUser = async (user: BackOfficeUserDTO): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.post('/User/SaveUser', user);
    };
    deleteUser = async (userGuid: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`/User/DeleteUser?userGuid=${userGuid}`);
    };
    savePassword = async (userGuid: string, newPassword: string): Promise<AxiosResponse<BackOfficeResponseDTO<BackOfficeUserDTO>>> => {
        return this.axios.get('/User/SaveUserPassword', {
            params: {
                userGuid,
                newPassword,
            },
        });
    };
    changePassword = async (userGuid: string, oldPassword: string, newPassword: string, checkOldPassword?: boolean): Promise<AxiosResponse<BackOfficeResponseDTO<BackOfficeUserDTO>>> => {
        return this.axios.get('/User/ChangeUserPassword', {
            params: {
                userGuid,
                oldPassword,
                newPassword,
                checkOldPassword,
            },
        });
    };
    resetUserPassword = async (userGuid: string, newPassword: string, whiteLabel?: string): Promise<AxiosResponse<BackOfficeResponseDTO<BackOfficeUserDTO>>> => {
        return this.axios.post('/User/SaveUserPassword', {
            userGuid,
            newPassword,
            whiteLabel,
        });
    };

    sendVerificationCodeToEmail = async (email: string): Promise<AxiosResponse<BooleanResponse>> => {
        return this.axios.post(`User/SendVerificationCodeToEmail?email=${encodeURIComponent(email)}&whitelabel=${getWhitelabelParameter()}`);
    };

    verifyEmail = async (code: string): Promise<AxiosResponse<BooleanResponse>> => {
        return this.axios.post(`User/CheckVerificationEmailCode?code=${encodeURIComponent(code)}`, code);
    };

    exportUsersToCsv = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/User/ExportUsersToCSV`, search, {
            responseType: 'blob',
        });
    };

    exportUsersToXls = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/User/ExportUsersToXls`, search, {
            responseType: 'blob',
        });
    };

    sendPasswordSetupEmail = async (model: UserPasswordSetupInitiationDTO): Promise<AxiosResponse<BackOfficeResponseDTO<any>>> => {
        return this.axios.post(`User/SendPasswordSetupEmail`, model);
    };

    sendNewCompanyAccessGrantedEmail = async (model: UserNewCompanyAccessDTO): Promise<AxiosResponse<BackOfficeResponseDTO<any>>> => {
        return this.axios.post(`User/SendNewCompanyAccessGrantedEmail`, model);
    };

    setupUserPassword = async (model: UserPasswordSetupDTO): Promise<AxiosResponse<BackOfficeResponseDTO<any>>> => {
        return this.axios.post(`User/SetupUserPassword`, model);
    };

    recoverUserPassword = async (model: UserPasswordSetupDTO): Promise<AxiosResponse<BackOfficeResponseDTO<UserPasswordSetupDTO>>> => {
        return this.axios.post(`User/ResetUserPassword `, model);
    };

    isAccountSetupCodeExpired = async (code: string): Promise<AxiosResponse<BooleanResponse>> => {
        return this.axios.post(`User/IsAccountSetupCodeExpired?code=${encodeURIComponent(code)}`, code);
    };

    sendVerificationEmailToUser = async (userGuid: string, email: string): Promise<AxiosResponse<BooleanResponse>> => {
        return this.axios.post(`User/SendVerificationEmailToUser?userGuid=${encodeURIComponent(userGuid)}&email=${encodeURIComponent(email)}&whitelabel=${getWhitelabelParameter()}`);
    };

    getUserEmailByResetPasswordToken = async (token: string): Promise<AxiosResponse<string>> => {
        return this.axios.post(`User/GetUserEmailByResetPasswordToken?token=${encodeURIComponent(token)}`, token);
    };

    verifyUserEmailByToken = async (token: string): Promise<AxiosResponse<BackOfficeResponseDTO<any>>> => {
        return this.axios.post(`User/VerifyUserEmailByToken?token=${encodeURIComponent(token)}`, token);
    };
}
