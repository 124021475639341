import classNames from 'classnames';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { createDataId } from '../../../common/utils/dataId';
import FormikField from '../../../common/utils/FormikField';
import { Button, ButtonType } from '../../../components/Buttons/Button';
import { ContentBlockBody } from '../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../components/ContentBlock/ContentBlockHeading';
import Notification, { NotificationStatus, NotificationType } from '../../../components/Notifications/Notification';
import { SwitchField } from '../../../components/Switch/Switch';
import TabList, { TabListBorderPlacement, TabListJustify, TabListType } from '../../../components/TabList/TabList';
import TabListItem from '../../../components/TabList/TabListItem';
import { LoginFields, LoginViewTab } from '../LoginView';
import { getCustomAzureLoginRedirectUrl, getGeneralAzureLoginRedirectUrl, isSufs } from '../../../common/utils/whitelabelHelper';
import Icon, { ICONS } from '../../../components/Icon/Icon';

import { UsernameTab } from './UsernameTab';
import { EmailTab } from './EmailTab';
import { IdCardTab } from './IdCardTab';
import { MobileIdTab } from './MobileIdTab';
import { SerbiaSSOTab } from './SerbiaSSOTab';
import { SmartIdTab } from './SmartIdTab';
import ForgotPassword from './ForgotPassword';
import EmailSent from './EmailSent';

export interface TabsAndFormsProps {
    children?: React.ReactNode;
    initialValues: LoginFields;
    isLoading: boolean;
    setIsLoading: (b: boolean) => void;
    handleSubmit: (values: LoginFields) => void;
    activeTab: LoginViewTab;
    showMobileIdCode: boolean;
    mobileIdVerificationCode: string;
    showSmartIdCode: boolean;
    smartIdVerificationCode: string;
    showFormControls: (values: LoginFields) => boolean;
    t: TFunction;
    tabs: LoginViewTab[];
    setActiveTab: (tab: LoginViewTab) => void;
    countryCode: string;
    isButtonDisabled?: boolean;
    resetCaptcha: () => void;
    handleKeycloak: () => void;
}

export const getIsSessionTimedOut = (location: Location) => location.search === '?expired';

const AzureLoginContent = ({ t }: { t: TFunction }) => (
    <>
        <h4 className="login__redirect-header">{t('view.login.redirectionWarning')}</h4>
        <p className="login__redirect-warning">{t('view.login.redirectionWarningContent')}</p>
    </>
);

const LoginTabsAndForms: React.FunctionComponent<TabsAndFormsProps> = (props: TabsAndFormsProps) => {
    const { t, activeTab, isLoading, isButtonDisabled, setIsLoading, resetCaptcha } = props;

    const isSessionTimedOut = getIsSessionTimedOut(location);
    const customAzureRedirectUrl = getCustomAzureLoginRedirectUrl();
    const generalAzureRedirectUrl = getGeneralAzureLoginRedirectUrl();

    const [showForgotPassModal, setShowForgotPassModal] = React.useState<boolean>(false);
    const [showEmailSentModal, setShowEmailSentModal] = React.useState<boolean>(false);
    const [forgotPassEmail, setForgotPassEmail] = React.useState<string>('');

    const url = new URL(window.location.href);
    const isErrorPresent = url.searchParams.has('error');
    const authStatus = url.searchParams.get('status');
    const isForgotPassword = location.search === '?forgot_password';
    const isUserLogout = location.search === '?userLogout';
    const loginFieldsClasses = classNames('login__fields', { 'login__fields--loading': isLoading && !props.showMobileIdCode });

    const showEmailSent = (state: boolean) => {
        if (!state) {
            setForgotPassEmail('');
        }
        setShowEmailSentModal(state);
    };

    const showForgotPassword = (state: boolean, emailSent?: boolean, email?: string) => {
        if (emailSent) {
            setForgotPassEmail(email);
            showEmailSent(true);
        }
        setShowForgotPassModal(state);
    };

    useEffect(() => {
        if (isSessionTimedOut) {
            resetCaptcha();
        }
        if (isForgotPassword) {
            showForgotPassword(true);
        }
    }, [location]);

    const onLoginClick = (values: LoginFields) => {
        const redirectLink = activeTab === LoginViewTab.AZURE ? generalAzureRedirectUrl : customAzureRedirectUrl;
        if (redirectLink) {
            setIsLoading(true);
            window.open(redirectLink, '_self');
        } else {
            if (props.isLoading) {
                return;
            }
            props.handleSubmit(values);
        }
    };

    const validationSchema = Yup.object<LoginFields>().shape({
        email: Yup.string().when('activeTab', {
            is: () => activeTab === LoginViewTab.EMAIL,
            then: Yup.string()
                .required(t('view.Accounting.MandatoryField'))
                .email(t('views.company.detail.email.InvalidFormat')),
            otherwise: Yup.string().notRequired(),
        }),
    });

    return showEmailSentModal ? (
        <EmailSent email={forgotPassEmail} />
    ) : showForgotPassModal ? (
        <ForgotPassword showForgotPassword={showForgotPassword} />
    ) : (
        <Formik initialValues={props.initialValues} onSubmit={onLoginClick} enableReinitialize={true} validateOnBlur={true} validateOnChange={true} validationSchema={validationSchema}>
            {(formik) => (
                <form
                    autoComplete="off"
                    onReset={formik.handleReset}
                    onSubmit={
                        activeTab === LoginViewTab.ID_CARD
                            ? () => {
                                  props.handleSubmit(formik.values);
                              }
                            : formik.handleSubmit
                    }
                    name="idLoginForm"
                    method="post"
                    action={process.env.REACT_APP_ID_LOGIN_URL}
                    target="id-card-iframe"
                >
                    <ContentBlockHeader className="login__block-header">
                        <div className="login__block-header-container">
                            <ContentBlockHeading type={ContentBlockHeadingType.BOLD} className={customAzureRedirectUrl ? 'light-text-body' : ''} dataId="headingBlockTitle">
                                {t('views.login.logInTitle')}
                            </ContentBlockHeading>
                            {!!customAzureRedirectUrl && <p className="login__heading-subtext">{t('views.login.withYourOrgAccount')}</p>}
                            <Notification dataId="timeout.notification" type={NotificationType.COMPONENT} visible={isSessionTimedOut} status={NotificationStatus.INFO} className="mt-7">
                                {t('views.login.sessionTimeoutMessage')}
                            </Notification>
                            <Notification dataId="userLogout.notification" type={NotificationType.COMPONENT} visible={isUserLogout} status={NotificationStatus.INFO} className="mt-7">
                                {t('views.login.userLogoutSecurityMessage')}
                            </Notification>
                            <Notification dataId="error.notification" type={NotificationType.COMPONENT} visible={isErrorPresent} status={NotificationStatus.WARNING} className="mt-7">
                                {authStatus && ['0', '1'].includes(authStatus) ? t('views.login.incorrectCredentialsTitle') : t('views.global.generalError')}
                            </Notification>
                        </div>
                    </ContentBlockHeader>
                    {!customAzureRedirectUrl && (
                        <ContentBlockBody className="login__block-body" dataId="tabForm.loginView">
                            <TabList borderPlacement={TabListBorderPlacement.TOP} type={TabListType.COMPACT} justifyTabs={TabListJustify.LEFT}>
                                {props.tabs.map((tab) => (
                                    <TabListItem
                                        dataId={createDataId('loginTab', tab)}
                                        key={tab}
                                        onClick={() => {
                                            props.setActiveTab(tab);
                                        }}
                                        isActive={activeTab === tab}
                                    >
                                        {t('views.login.tab.' + tab)}
                                    </TabListItem>
                                ))}
                            </TabList>
                            <div className={loginFieldsClasses} id="login-fields">
                                {activeTab === LoginViewTab.ID_CARD && <IdCardTab t={t} />}
                                {activeTab === LoginViewTab.USERNAME && <UsernameTab t={t} />}
                                {activeTab === LoginViewTab.MOBILE_ID && (
                                    <MobileIdTab showMobileIdCode={props.showMobileIdCode} mobileIdVerificationCode={props.mobileIdVerificationCode} t={t} countryCode={props.countryCode} />
                                )}
                                {activeTab === LoginViewTab.SMART_ID && (
                                    <SmartIdTab showSmartIdCode={props.showSmartIdCode} smartIdVerificationCode={props.smartIdVerificationCode} t={t} countryCode={props.countryCode} />
                                )}
                                {activeTab === LoginViewTab.SERBIA_SSO && <SerbiaSSOTab t={t} />}
                                {activeTab === LoginViewTab.EMAIL && <EmailTab t={t} />}
                                {activeTab === LoginViewTab.AZURE && !!generalAzureRedirectUrl && <AzureLoginContent t={t} />}
                                {activeTab !== LoginViewTab.AZURE && props.showFormControls(formik.values) && (
                                    <div className="login__footer-block">
                                        <div>
                                            <input type="hidden" name={'returnUrl'} value={formik.values.returnUrl} />
                                            <FormikField component={SwitchField} className="login__remember-me" label={t('views.login.rememberMe')} name={'rememberMe'} dataId={'rememberMe'} />
                                        </div>
                                        {activeTab === LoginViewTab.EMAIL && (
                                            <Button
                                                className="login__forgot-pass-button"
                                                onClick={() => {
                                                    showForgotPassword(true);
                                                }}
                                                dataId="forgotPasswordBtn"
                                                buttonType={ButtonType.TEXT}
                                            >
                                                {t('views.login.forgotPassword')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </ContentBlockBody>
                    )}

                    {(props.showFormControls(formik.values) || !!customAzureRedirectUrl) && (
                        <ContentBlockFooter className="login__block-footer" noSeparator={false}>
                            {!!customAzureRedirectUrl && <AzureLoginContent t={t} />}
                            <Button type="submit" loading={isLoading} disabled={isButtonDisabled} className="login__button" dataId="loginButton" id="loginButton">
                                {customAzureRedirectUrl || (activeTab === LoginViewTab.AZURE && generalAzureRedirectUrl) ? t('views.login.continueButton') : t('views.login.logInButton')}
                            </Button>
                            {!isSufs() && (
                                <div className="alternative-login">
                                    <div className="login-btn-separator"></div>
                                    <span className="continue-text">{t('views.login.orContinueUsing')}</span>
                                    <Button className="login__button up-login" dataId="UPLoginButton" onClick={props.handleKeycloak}>
                                        <div className="icon-wrapper">
                                            <Icon name={ICONS.UP_LOGO_SMALL}></Icon>
                                        </div>
                                        <div className="login-text">{t('views.login.upCorporateAccount')}</div>
                                    </Button>
                                </div>
                            )}
                        </ContentBlockFooter>
                    )}
                </form>
            )}
        </Formik>
    );
};

export default LoginTabsAndForms;
