import * as React from 'react';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';

import errors from '../../../config/httpErrors';
import { BaseComponent } from '../../components/BaseComponent';
import { Button } from '../../components/Buttons/Button';
import Icon, { ICONS } from '../../components/Icon/Icon';
import withSuspense from '../../common/hocs/withSuspense';

import './ErrorPage.scss';

export interface ErrorPageProps extends WithTranslation {}

const getErrorCodeFromUrl = (url: string) => {
    const splitUrl = url.match(/^.*\/(.*)\.\w+$/);
    if (splitUrl && splitUrl.length && errors.includes(splitUrl[1])) {
        return splitUrl[1];
    }
    return '404';
};

export class ErrorPage extends BaseComponent<ErrorPageProps> {
    render() {
        const { t } = this.props;
        const code = getErrorCodeFromUrl(window.location.href);
        const showContact = ['404', '500', '504'].includes(code);
        return (
            <div className="error-container">
                <Icon name={ICONS.PLUG} className="error-container__icon error-container__icon--left" />
                <div className="error-container__content">
                    <span className="error-container__text--big">{code}</span>
                    <span className="error-container__text--medium">{t(`views.error.${code}.Description`)}</span>
                    <span className="error-container__text">{t(`views.error.${code}.Message`)}</span>
                    {showContact && (
                        <span className="error-container__text">
                            <Trans i18nKey={`views.error.${code}.Contact`}>
                                <a style={{ color: '#FEC017', textDecoration: 'none' }} href="mailto:info@fitekin.ee">
                                    info@fitekin.ee
                                </a>
                            </Trans>
                        </span>
                    )}

                    <Button
                        className="error-container__action"
                        onClick={() => {
                            window.location.href = '../../app/#/dashboard';
                        }}
                    >
                        {t(`views.error.${code}.Action`)}
                    </Button>
                </div>
                <Icon name={ICONS.WIRE} className="error-container__icon error-container__icon--right" />
            </div>
        );
    }
}

export default withSuspense(withTranslation()(ErrorPage));
