import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import './AccountSetup.scss';
import { notify } from '../../../../common/utils/notify';
import { Button } from '../../../../components/Buttons/Button';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import withSuspense from '../../../../common/hocs/withSuspense';
import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import api from '../../../../services/ApiServices';
import PasswordRequirements from '../../../../components/UserProfile/components/ChangePassword/PasswordRequirements';
import { passwordMatch } from '../../../../components/UserProfile/components/ChangePassword/utils';

export interface AccountSetupProps extends WithTranslation {
    token: string;
    selectedCountry: string;
}

export interface AccountSetupFields {
    password: string;
    passwordRepeat: string;
}

const AccountSetup = (props: AccountSetupProps) => {
    const { selectedCountry, token, t } = props;
    const [isLoading, setIsloading] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);

    const getInitialFields = (): AccountSetupFields => {
        return {
            password: '',
            passwordRepeat: '',
        };
    };

    useEffect(() => {
        setIsloading(true);
        api.boUsers
            .isAccountSetupCodeExpired(token)
            .then((response) => {
                if (response.data) {
                    setIsTokenValid(false);
                } else {
                    setIsTokenValid(true);
                }
                setIsloading(false);
            })
            .catch(() => {
                setIsloading(false);
            });
    }, []);

    const validationSchema: Yup.ObjectSchema<AccountSetupFields> = Yup.object<AccountSetupFields>().shape({
        passwordRepeat: Yup.string()
            .ensure()
            .oneOf([Yup.ref('password')], t('component.changePassword.passwordsDoNotMatch'))
            .nullable(false),
    });

    const onSubmit = async (fields: AccountSetupFields) => {
        setIsloading(true);
        try {
            const result = await api.boUsers.setupUserPassword({ Password: fields.password, VerificationToken: token, Country: selectedCountry });
            if (result.data.Success) {
                // redirect to login, which decodes the token to login user automatically
                window.location.href = `/login?token=${result.data.Message}&redirect=true`;
            } else {
                notify.error(t(result.data.Message));
            }
        } catch (e) {
            notify.error(t('views.global.generalError'));
        }
        setIsloading(false);
    };

    const renderForm = () => {
        return (
            <Formik initialValues={getInitialFields()} onSubmit={onSubmit} enableReinitialize={true} validationSchema={validationSchema} validateOnBlur={true} validateOnChange={true}>
                {(formik) => (
                    <Form className="account-setup__form">
                        <ContentBlock className="account-setup__block">
                            <ContentBlockHeader className="account-setup__block__header">
                                <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId('accountSetup', 'heading')}>
                                    {t('views.login.accountSetupTitle')}
                                </ContentBlockHeading>
                            </ContentBlockHeader>
                            <ContentBlockBody className="account-setup__block__body" dataId={createDataId('accountSetup', 'block')}>
                                <FormikField
                                    label={t('view.UserList.NewUserPopover.InputLabel.Password')}
                                    component={TextInputField}
                                    name="password"
                                    password={true}
                                    type={TextInputType.BORDERED}
                                    dataId={createDataId('accountSetup', 'Password')}
                                    autosize={true}
                                />
                                <FormikField
                                    label={t('view.UserList.NewUserPopover.InputLabel.ConfirmPassword')}
                                    component={TextInputField}
                                    name="passwordRepeat"
                                    password={true}
                                    type={TextInputType.BORDERED}
                                    dataId={createDataId('accountSetup', 'PasswordRepeat')}
                                    autosize={true}
                                />
                                <PasswordRequirements
                                    isPristine={!formik.touched.passwordRepeat}
                                    isMatch={passwordMatch(formik.values.password, formik.values.passwordRepeat)}
                                    value={formik.values.passwordRepeat}
                                    t={t}
                                />
                            </ContentBlockBody>
                            <ContentBlockFooter className="account-setup__block__footer" noSeparator={false}>
                                <Button type="submit" loading={isLoading} disabled={isLoading || !formik.isValid} dataId={createDataId('accountSetup', 'button')} className="account-setup__button">
                                    {t('views.login.continueButton')}
                                </Button>
                            </ContentBlockFooter>
                        </ContentBlock>
                    </Form>
                )}
            </Formik>
        );
    };

    const renderText = (text: string) => {
        return (
            <ContentBlock className="account-setup__expiry">
                <ContentBlockBody className="account-setup__expiry__body" dataId={createDataId('accountSetup', 'link', 'expiry')}>
                    {text}
                </ContentBlockBody>
            </ContentBlock>
        );
    };

    return isLoading ? renderText(`${t('interceptorsFactory.PleaseWait')}...`) : isTokenValid ? renderForm() : renderText(t('EmailActivation_Error_Verification_Code_Expired'));
};

export default withSuspense(withTranslation()(AccountSetup));
