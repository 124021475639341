import * as React from 'react';
import { cssTransition, toast, ToastId, ToastOptions } from 'react-toastify';

import Notification, { NotificationStatus, NotificationTheme, NotificationType } from '../../components/Notifications/Notification';

interface Notify {
    success: NotifyFunction;
    info: NotifyFunction;
    warning: NotifyFunction;
    error: NotifyFunction;
    dismiss: (toastId?: ToastId) => void;
}

type NotifyFunction = (message: React.ReactNode | string, title?: React.ReactNode, config?: NotifyOptions) => void;

export interface NotifyOptions {
    theme?: NotificationTheme;
    toastOptions?: ToastOptions;
}

const Transition = cssTransition({
    enter: 'pullIn',
    exit: 'fadeOutRight',
    duration: [350, 300],
});

export const notifyDefaultOptions: NotifyOptions = {
    theme: NotificationTheme.LIGHT,
    toastOptions: {
        hideProgressBar: true,
        autoClose: 3000,
        closeOnClick: false,
        // we have our own close button in component
        closeButton: false,
        transition: Transition,
    },
};

export const notify: Notify = {
    success: (message, title, options) => {
        showToast(message, title, options, NotificationStatus.SUCCESS);
    },
    info: (message, title, options) => {
        showToast(message, title, options, NotificationStatus.INFO);
    },
    warning: (message, title, options) => {
        showToast(message, title, options, NotificationStatus.WARNING);
    },
    error: (message, title, options) => {
        showToast(message, title, options, NotificationStatus.ERROR);
    },
    dismiss: (toastId?: ToastId) => {
        toast.dismiss(toastId);
    },
};

function showToast(message: React.ReactNode | string, title: React.ReactNode, options: NotifyOptions = {}, status: NotificationStatus) {
    const mergedOptions = { ...notifyDefaultOptions, ...options };
    const defaultAutoClose = status === NotificationStatus.ERROR ? 10000 : notifyDefaultOptions.toastOptions.autoClose;
    let messageAsHtml: string;

    if (typeof message === 'string') {
        messageAsHtml = message;
    }

    toast(
        ({ closeToast }: { closeToast: () => void }) => (
            <Notification dataId="toast-container" visible={true} type={NotificationType.GLOBAL} status={status} theme={mergedOptions.theme} onClose={closeToast} title={title}>
                {messageAsHtml ? <div dangerouslySetInnerHTML={{ __html: messageAsHtml }} /> : message}
            </Notification>
        ),
        {
            ...notifyDefaultOptions.toastOptions,
            autoClose: defaultAutoClose,
            ...options.toastOptions,
        },
    );
}
