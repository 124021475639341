import classNames from 'classnames';
import { intersection } from 'lodash-es';
import * as React from 'react';

import { WithDataId } from '../../common/utils/dataId';
import { BaseComponent } from '../BaseComponent';
import Icon, { IconSize, IconType, ICONS } from '../Icon/Icon';
import { eventTrack } from '../../common/analytics/gtm';

import './Button.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    icon?: string;
    iconSize?: IconSize;
    iconRotation?: number;
    iconPlacement?: ButtonIconPlacement;
    iconType?: IconType;
    iconClass?: string;
    loading?: boolean;
    isActive?: boolean;
    contentAlign?: ButtonContentAlign;
    expand?: boolean;
    customTag?: string;
    buttonName?: string;
    checkmark?: boolean;
}

export type ButtonPropsWithDataId = ButtonProps & WithDataId;

export enum ButtonContentAlign {
    START = 'START',
    CENTER = 'CENTER',
    END = 'END',
}

export enum ButtonIconPlacement {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export enum ButtonType {
    ICON = 'ICON',
    ICON_TEXT = 'ICON_TEXT',
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    ICON_SQUARE = 'ICON_SQUARE',
    FLUID = 'FLUID',
    TEXT = 'TEXT',
    LEGACY = 'LEGACY',
    ALTERNATIVE = 'ALTERNATIVE',
    BRAND = 'BRAND',
}

export enum ButtonSize {
    SMALL = 'SMALL',
    NORMAL = 'NORMAL',
    LARGE = 'LARGE',
}

export enum AlternativeButtonEvent {
    ICON_CLICKED = 'ICON_CLICKED',
    TEXT_CLICKED = 'TEXT_CLICKED',
}

export class Button extends BaseComponent<ButtonPropsWithDataId> {
    static defaultProps: Partial<ButtonPropsWithDataId> = {
        type: 'button',
        iconPlacement: ButtonIconPlacement.TOP,
    };
    static getButtonClass(iconSize = IconSize.SM): string {
        const btnClass = {
            [IconSize.XS]: 'button--square-padding-xs',
            [IconSize.SM]: 'button--square-padding-sm',
            [IconSize.MD]: 'button--square-padding-md',
            [IconSize.LG]: 'button--square-padding-lg',
        };
        return btnClass[iconSize];
    }

    trackEventAlternativeButton = (eventType: AlternativeButtonEvent) => {
        if (this.props.buttonType === ButtonType.ALTERNATIVE) {
            eventTrack({
                event: 'action_description',
                label: eventType === AlternativeButtonEvent.ICON_CLICKED ? 'icon-clicked' : 'text-clicked',
            });

            eventTrack({
                category: 'action_description',
                label: this.props.buttonName,
            });
        }
    };

    render() {
        const {
            expand,
            children,
            buttonType,
            buttonSize,
            contentAlign,
            className,
            isActive,
            icon,
            iconSize,
            iconType,
            iconRotation,
            iconPlacement,
            iconClass,
            dataId,
            loading,
            customTag,
            checkmark,
            ...rest
        } = this.props;
        let type = '';
        if (buttonType === ButtonType.ICON_SQUARE) {
            type = Button.getButtonClass(iconSize);
        }
        const classes = classNames(
            'button',
            type,
            {
                'button--primary': !buttonType || buttonType === ButtonType.PRIMARY,
                'button--fluid': buttonType === ButtonType.FLUID,
                'button--icon': buttonType === ButtonType.ICON,
                'button--icon-square': buttonType === ButtonType.ICON_SQUARE,
                'button--icon-text': buttonType === ButtonType.ICON_TEXT,
                'button--secondary': buttonType === ButtonType.SECONDARY,
                'button--legacy': buttonType === ButtonType.LEGACY,
                'button--brand': buttonType === ButtonType.BRAND,
                'button--has-icon': !!icon,
                'button--is-active': isActive,
                'button--icon-inline': [ButtonIconPlacement.RIGHT, ButtonIconPlacement.LEFT].includes(iconPlacement) || buttonType === ButtonType.FLUID,
                'button--loading': loading,
                'button--content-start': contentAlign === ButtonContentAlign.START,
                'button--content-center': contentAlign === ButtonContentAlign.CENTER,
                'button--content-end': contentAlign === ButtonContentAlign.END,
                'button--expand': expand,
                'button--small': buttonSize === ButtonSize.SMALL,
                'button--alternative': buttonType === ButtonType.ALTERNATIVE,
            },
            className,
        );
        // support custom tag in case we have clickable elements inside our buttons (IE, FF have issues with these)
        const Tag: any = customTag || 'button';
        return (
            <Tag data-id={dataId} className={classes} {...rest}>
                {intersection([ButtonType.ICON, ButtonType.ICON_TEXT, ButtonType.ICON_SQUARE, ButtonType.FLUID, ButtonType.ALTERNATIVE], [buttonType]).length ? (
                    <>
                        {[ButtonIconPlacement.TOP, ButtonIconPlacement.LEFT].includes(iconPlacement) && !!icon && (
                            <Icon
                                name={icon}
                                size={iconSize}
                                rotation={iconRotation}
                                type={iconType}
                                className={`button__icon ${iconClass ? iconClass : ''}`}
                                data-id={`${dataId}.icon.leftOrTop`}
                                onClick={() => this.trackEventAlternativeButton(AlternativeButtonEvent.ICON_CLICKED)}
                            />
                        )}
                        {checkmark && <Icon name={ICONS.CHECKMARK_SMALL} className="button__check" />}
                        {children && (
                            <span className="button__content" data-id={`${dataId}.content`} onClick={() => this.trackEventAlternativeButton(AlternativeButtonEvent.TEXT_CLICKED)}>
                                {children}
                            </span>
                        )}
                        {[ButtonIconPlacement.RIGHT, ButtonIconPlacement.BOTTOM].includes(iconPlacement) && !!icon && (
                            <Icon name={icon} size={iconSize} type={iconType} rotation={iconRotation} className="button__icon" data-id={`${dataId}.icon.rightOrBottom`} />
                        )}
                    </>
                ) : (
                    <span className="button__content" data-id={`${dataId}.content`}>
                        {children}
                    </span>
                )}
            </Tag>
        );
    }
}
