import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { BaseSearch, FileDTO, PagedListContainer, RelatedDimensionsDTO } from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

export class RelatedDimensionsService {
    constructor(private axios: AxiosInstance) {}

    getRelatedDimensions = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<RelatedDimensionsDTO>>> => {
        return this.axios.post('/RelatedDimensions/GetList', searchParams);
    };

    exportRelatedDimensionsToXls = () => {
        this.axios.post('/RelatedDimensions/ExportRelatedDimensionsToXls', null, { responseType: 'blob' }).then(
            (response) => {
                if (response?.data) {
                    const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(file, 'Related-dimensions.xlsx');
                }
            },
            (e) => console.error(e),
        );
    };

    exportRelatedDimensionsToCsv = () => {
        this.axios.post('/RelatedDimensions/ExportRelatedDimensionsToCsv').then(
            (response) => {
                if (response?.data) {
                    const file = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                    saveAs(file, 'Related-dimensions.csv');
                }
            },
            (e) => console.error(e),
        );
    };

    uploadRelatedDimensionsFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/RelatedDimensions/UploadRelatedDimensionsFile', file, config);
    };
}

export default RelatedDimensionsService;
