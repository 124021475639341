import { TFunction } from 'i18next';
import * as React from 'react';

import Logo from '../../../components/logo/Logo';
import Modal, { ModalType } from '../../../components/Modal/Modal';
import { CountryDetectionStatus } from '../loginHelper';
import { isMaxima, isSwedbank } from '../../../common/utils/whitelabelHelper';

import CountryModal from './CountryModal';
import LanguageModal from './LanguageModal';
import CountryButton from './CountryButton';
import LanguageButton from './LanguageButton';

interface HeaderProps {
    t: TFunction;
    countryDetectionStatus: string;
    selectedCountry: string;
    selectedLanguage: any;
    setCountry: (countryCode: string) => void;
    setLanguage: (locale: string) => void;
}

export function Header({ t, countryDetectionStatus, selectedCountry, selectedLanguage, setCountry, setLanguage }: HeaderProps) {
    const [isLanguageModalOpen, toggleLanguageModal] = React.useState(false);
    const [isCountryModalOpen, toggleCountryModal] = React.useState(false);

    const handleToggleCountryModal = () => {
        toggleCountryModal(!isCountryModalOpen);
    };

    const handletoggleLanguageModal = () => {
        toggleLanguageModal(!isLanguageModalOpen);
    };

    return (
        <nav className="app__nav">
            <Logo className="app__logo" wrapperClassName="login__logo-wrapper" onLogin={true} />
            {countryDetectionStatus === CountryDetectionStatus.AVAILABLE && !isMaxima() && !isSwedbank() && <CountryButton countryCode={selectedCountry} onClick={handleToggleCountryModal} t={t} />}
            {countryDetectionStatus === CountryDetectionStatus.AVAILABLE && <LanguageButton languageCode={selectedLanguage} onClick={handletoggleLanguageModal} t={t} />}
            <Modal isOpen={isLanguageModalOpen} type={ModalType.FIXED_RIGHT} onClose={handletoggleLanguageModal} dataId="CountryAndLanguageModal">
                <LanguageModal closeModal={handletoggleLanguageModal} selectedLanguage={selectedLanguage} setLanguage={setLanguage} t={t} />
            </Modal>
            <Modal isOpen={isCountryModalOpen} type={ModalType.FIXED_RIGHT} onClose={handleToggleCountryModal} dataId="CountryModal">
                <CountryModal closeModal={handleToggleCountryModal} selectedCountry={selectedCountry} setCountry={setCountry} t={t} />
            </Modal>
        </nav>
    );
}
