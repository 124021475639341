import { AxiosInstance, AxiosResponse } from 'axios';

import { Currency } from '../types/ApiTypes';

export default class CurrencyService {
    constructor(private axios: AxiosInstance) {}

    getAll = async (): Promise<AxiosResponse<Currency[]>> => {
        return this.axios.get('/Currency/GetAll');
    };
}
