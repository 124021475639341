import classNames from 'classnames';
import Downshift, { StateChangeOptions } from 'downshift';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import withFormikField from '../../common/utils/withFormikField';
import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import { BaseComponent } from '../BaseComponent';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import { Button, ButtonType } from '../Buttons/Button';
import { IconSize, ICONS } from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Scrollbars from '../Scrollbars/Scrollbars';
import { TextInput, TextInputProps, TextInputType } from '../TextInput/TextInput';
import { WithDataId, createDataId } from '../../common/utils/dataId';

import './Typeahead.scss';

export interface Props<T> {
    placeholder?: string;
    onChange?: (item: TypeaheadItem<T>) => void;
    onBlur?: (item: TypeaheadItem<T>) => void;
    items: Array<TypeaheadItem<T>>;
    value?: TypeaheadItem<T>;
    filter?: (item: TypeaheadItem<T>, inputText: string) => boolean;
    inputProps?: Pick<TextInputProps, Exclude<keyof TextInputProps, 'onChange'>>;
    toggleVisible?: boolean;
    wrapperClass?: string;
    listWrapClass?: string;
    positionFixed?: boolean;
    error?: React.ReactNode;
}

export type TypeaheadProps<T> = Props<T> & WithTranslation & WithDataId;

export interface TypeaheadState {
    selectedItem: TypeaheadItem<any>;
    shouldFilter: boolean;
}

export interface TypeaheadItem<T> {
    value: T;
    text: string;
}

interface MenuProps {
    itemsOrSearchString: any;
    scheduleUpdate: () => void;
    loading: boolean;
}

export class TypeaheadMenuWrapper extends BaseComponent<MenuProps> {
    componentDidUpdate(prevProps: Readonly<MenuProps>) {
        if (!prevProps.itemsOrSearchString && !this.props.itemsOrSearchString) {
            return;
        }

        if ((!prevProps.itemsOrSearchString && this.props.itemsOrSearchString) || (prevProps.itemsOrSearchString && !this.props.itemsOrSearchString)) {
            this.props.scheduleUpdate();
            return;
        }

        if (this.props.itemsOrSearchString.length !== prevProps.itemsOrSearchString.length) {
            this.props.scheduleUpdate();
        }

        if (!prevProps.loading && this.props.loading) {
            this.props.scheduleUpdate();
        }
    }

    render() {
        return this.props.children;
    }
}

const IGNORED_VALUE: any = 'IGNORED_VALUE';

class TypeaheadInternal<T> extends BaseStatefulComponent<TypeaheadProps<T>, TypeaheadState> {
    private componentRootElement = React.createRef<HTMLDivElement>();
    private inputRef = React.createRef<HTMLInputElement>();
    private readonly outsideEventListener: OutsideEventListener;

    constructor(props: TypeaheadProps<T>) {
        super(props);
        this.state = {
            selectedItem: this.props.value || null,
            shouldFilter: !this.props.value,
        };
        this.outsideEventListener = new OutsideEventListener(this.componentRootElement, this.closeDropdown);
    }

    componentDidMount() {
        this.outsideEventListener.start();
    }

    componentWillUnmount() {
        this.outsideEventListener.stop();
    }

    componentDidUpdate(prevProps: TypeaheadProps<T>) {
        if (this.props.value !== prevProps.value) {
            this.setState((prevState) => ({
                ...prevState,
                selectedItem: this.props.value,
            }));
        }
    }

    handleStateChange = (changes: StateChangeOptions<TypeaheadItem<T>>) => {
        if (changes.hasOwnProperty('selectedItem')) {
            this.setState({ selectedItem: changes.selectedItem, shouldFilter: false }, () => {
                if (this.inputRef && this.inputRef.current) {
                    this.inputRef.current.blur();
                }
            });
            this.props.onChange(changes.selectedItem);
        } else if (changes.hasOwnProperty('inputValue') && changes.type !== '__autocomplete_controlled_prop_updated_selected_item__') {
            this.setState({ selectedItem: { text: changes.inputValue, value: IGNORED_VALUE }, shouldFilter: true });
            this.props.onChange(null);
        }
    };

    handleBlur = () => {
        if (this.state.selectedItem && this.state.selectedItem.value === IGNORED_VALUE) {
            this.setState({ selectedItem: null });
        }

        if (this.props.onBlur) {
            const value = this.state.selectedItem && this.state.selectedItem.value === IGNORED_VALUE ? null : this.state.selectedItem;
            this.props.onBlur(value);
        }
    };

    handleFocus = () => {};

    handleToggleClick = (isOpen: boolean, toggleMenu: () => void) => {
        if (this.inputRef && this.inputRef.current) {
            if (!isOpen) {
                this.handleFocus();
            }
        }
        toggleMenu();
    };

    closeDropdown = () => {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.blur();
        }
    };

    isToggleVisible() {
        const { toggleVisible, inputProps } = this.props;
        return toggleVisible || (inputProps && inputProps.type === TextInputType.BORDERED);
    }

    getFilteredItems = (inputValue: string): Array<TypeaheadItem<T>> => {
        return this.props.items.filter((item) => {
            if (this.props.filter) {
                return this.props.filter(item, inputValue) || !this.state.shouldFilter;
            }
            return !inputValue || item.text.toLowerCase().includes(inputValue.toLowerCase()) || !this.state.shouldFilter;
        });
    };

    render() {
        const { placeholder, items, inputProps, wrapperClass, listWrapClass, dataId, t } = this.props;
        const classes = classNames('typeahead', wrapperClass, { 'typeahead--has-label': inputProps && inputProps.label });
        const listWrapClasses = classNames('typeahead__list-wrap', listWrapClass);
        return (
            <Downshift defaultHighlightedIndex={0} onStateChange={this.handleStateChange} itemToString={(item) => (item ? item.text : '')} selectedItem={this.state.selectedItem}>
                {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem, getToggleButtonProps, openMenu, toggleMenu }) => (
                    <div className={classes} data-id={dataId || 'typeahead'} ref={this.componentRootElement}>
                        <Manager>
                            <Reference>
                                {({ ref }) => (
                                    <>
                                        <div className="typeahead__input-wrap" ref={ref}>
                                            <TextInput
                                                {...this.props.inputProps}
                                                dataId={createDataId(dataId || 'typeahead', 'input')}
                                                forwardRef={this.inputRef}
                                                placeholder={placeholder}
                                                error={this.props.error}
                                                hideError={true}
                                                {...getInputProps()}
                                                onFocus={() => {
                                                    this.handleFocus();
                                                    openMenu();
                                                }}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    this.handleBlur();
                                                    getInputProps().onBlur(e);
                                                }}
                                            />
                                            {this.isToggleVisible() && (
                                                <Button
                                                    dataId={createDataId(dataId || 'typeahead', 'inputToggle')}
                                                    tabIndex={-1}
                                                    iconSize={IconSize.XS}
                                                    className="typeahead__toggle"
                                                    buttonType={ButtonType.ICON}
                                                    icon={ICONS.CHEVRON_DOWN_24}
                                                    iconRotation={isOpen ? 180 : 0}
                                                    {...getToggleButtonProps()}
                                                    onClick={() => {
                                                        this.handleToggleClick(isOpen, toggleMenu);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {this.props.error && <InputErrorMessage dataId={dataId || 'typeahead.inputError'}>{this.props.error}</InputErrorMessage>}
                                    </>
                                )}
                            </Reference>
                            <CSSTransition unmountOnExit={true} classNames="fade" in={isOpen && !(!inputValue && items.length === 0)} timeout={150}>
                                <Popper positionFixed={!!this.props.positionFixed} placement="bottom-start">
                                    {({ ref, style, placement, scheduleUpdate }) => (
                                        <div ref={ref} style={style} data-placement={placement} className={listWrapClasses}>
                                            <Scrollbars>
                                                <TypeaheadMenuWrapper loading={false} scheduleUpdate={scheduleUpdate} itemsOrSearchString={inputValue}>
                                                    <ul className="typeahead__list" data-id={createDataId(dataId || 'typeahead', 'list')} role="menu" {...getMenuProps()}>
                                                        {this.getFilteredItems(inputValue).map((item, index) => (
                                                            <li
                                                                data-id={createDataId(dataId || 'typeahead', 'list', index)}
                                                                className={`typeahead__list-item ${highlightedIndex === index ? 'typeahead__list-item--active' : ''}`}
                                                                key={index}
                                                                {...getItemProps({
                                                                    key: index,
                                                                    index,
                                                                    item,
                                                                })}
                                                            >
                                                                <span className="typeahead__list-item-text" tabIndex={-1} data-id={createDataId(dataId || 'typeahead', 'list', index, 'a')}>
                                                                    {selectedItem && selectedItem.text === item.text && (
                                                                        <strong data-id={createDataId(dataId || 'typeahead', 'list', index, 'a', 'strong')}>{item.text}</strong>
                                                                    )}
                                                                    {!(selectedItem && selectedItem.text === item.text) && item.text}
                                                                </span>
                                                            </li>
                                                        ))}
                                                        {inputValue && this.getFilteredItems(inputValue).length === 0 && (
                                                            <li data-id={createDataId(dataId || 'typeahead', 'list', 'noResults')} className={`typeahead__list-item`}>
                                                                <span className="typeahead__list-item-text">{t('component.Typeahead.NoResults')}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </TypeaheadMenuWrapper>
                                            </Scrollbars>
                                        </div>
                                    )}
                                </Popper>
                            </CSSTransition>
                        </Manager>
                    </div>
                )}
            </Downshift>
        );
    }
}

export const Typeahead = withTranslation()(TypeaheadInternal);

export const TypeaheadField = withFormikField(Typeahead);
