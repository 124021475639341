import * as React from 'react';
import { Dispatchable0 } from 'redux-dispatchers';

const outsideEventTypes: Partial<Array<keyof DocumentEventMap>> = ['mousedown', 'touchstart', 'keydown', 'keyup', 'focus'];

export class OutsideEventListener implements EventListenerObject {
    private readonly node: React.RefObject<HTMLElement>;
    /**
     * Called when user is inside and moves out of node.
     */
    private onOutsideEvent: Dispatchable0;
    private isUserOutside: boolean;

    constructor(node: React.RefObject<HTMLElement>, onOutsideEvent: Dispatchable0, isUserOutside = true) {
        this.node = node;
        this.onOutsideEvent = onOutsideEvent;
        this.isUserOutside = isUserOutside;
    }

    start() {
        outsideEventTypes.forEach((type: string) => {
            document.addEventListener(type, this);
        });
    }

    stop() {
        outsideEventTypes.forEach((type: string) => {
            document.removeEventListener(type, this);
        });
    }

    handleEvent(event: Event) {
        if (!this.onOutsideEvent || !this.node) {
            return;
        }

        try {
            // see https://github.com/Microsoft/TypeScript/issues/15394#issuecomment-297495746 for "as Node" requirement
            const isOutsideNode = this.node.current && !this.node.current.contains(event.target as Node);
            if (isOutsideNode) {
                if (!this.isUserOutside) {
                    this.onOutsideEvent();
                }
                this.isUserOutside = true;
            } else {
                this.isUserOutside = false;
            }
        } catch (e) {
            console.debug(e);
            // trigger on outside window events;
            this.onOutsideEvent();
            this.isUserOutside = true;
        }
    }

    changeOnOutsideEvent(newOnOutsideEvent: Dispatchable0) {
        this.onOutsideEvent = newOnOutsideEvent;
    }
}
