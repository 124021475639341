import { AxiosInstance, AxiosResponse } from 'axios';

export interface WhoAmIDto {
    id: string;
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string;
    mail: string;
    mobilePhone: string;
    officeLocation: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
}

export default class AuthService {
    constructor(private axios: AxiosInstance) {}

    whoAmI = async (): Promise<AxiosResponse<WhoAmIDto>> => {
        return this.axios.get('/me');
    };
}
