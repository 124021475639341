import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeCompanyDTO, BackOfficeUserDTO } from '../types/BoApiTypes';

export default class BoUserCompaniesService {
    constructor(private axios: AxiosInstance) {}

    getUserCompanies = async (userGuid: string): Promise<AxiosResponse<Array<BackOfficeCompanyDTO>>> => {
        return this.axios.get('/UserCompany/GetUserCompanies', {
            params: {
                userGuid,
            },
        });
    };

    getCompanyUsers = async (companyGuid: string): Promise<AxiosResponse<Array<BackOfficeUserDTO>>> => {
        return this.axios.get('/UserCompany/GetCompanyUsers', {
            params: {
                companyGuid,
            },
        });
    };

    addUserToCompany = async (userGuid: string, companyGuid: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get('/UserCompany/AddUserToCompany', {
            params: {
                userGuid,
                companyGuid,
            },
        });
    };

    removeUserFromCompany = async (userGuid: string, companyGuid: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get('/UserCompany/RemoveUserFromCompany', {
            params: {
                userGuid,
                companyGuid,
            },
        });
    };
}
