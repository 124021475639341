import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import startOfWeek from 'date-fns/startOfWeek';
import lastOfWeek from 'date-fns/lastDayOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import lastOfMonth from 'date-fns/lastDayOfMonth';
import startOfYear from 'date-fns/startOfYear';
import lastOfYear from 'date-fns/lastDayOfYear';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';

export const addDaysFromToday = (numberOfDays: number): Date => {
    return addDays(new Date(), numberOfDays);
};

export const addDaysFromDate = (date: Date, numberOfDays: number): Date => {
    return addDays(date, numberOfDays);
};

export const subtractDaysFromToday = (numberOfDays: number): Date => {
    return subDays(new Date(), numberOfDays);
};

export const subtractDaysFromDate = (date: Date, numberOfDays: number): Date => {
    return subDays(date, numberOfDays);
};

export const addMonthsFromToday = (numberOfMonths: number): Date => {
    return addMonths(new Date(), numberOfMonths);
};

export const subtractMonthsFromToday = (numberOfMonths: number): Date => {
    return subMonths(new Date(), numberOfMonths);
};

export const getFirstDateOfWeek = (date: Date): Date => {
    return startOfWeek(date, { weekStartsOn: 1 });
};

export const getLastDateOfWeek = (date: Date): Date => {
    return lastOfWeek(date, { weekStartsOn: 1 });
};

export const getFirstDateOfMonth = (date: Date): Date => {
    return startOfMonth(date);
};

export const getLastDateOfMonth = (date: Date): Date => {
    return lastOfMonth(date);
};

export const getFirstDateOfYear = (date: Date): Date => {
    return startOfYear(date);
};

export const getLastDateOfYear = (date: Date): Date => {
    return lastOfYear(date);
};

export const getDifferenceInMonths = (date1: Date, date2: Date) => {
    return differenceInMonths(date1, date2);
};

export const getDifferenceInDays = (date1: Date, date2: Date) => {
    return differenceInDays(date1, date2);
};

export const getNoTimeDate = (d: Date): Date => new Date(d.setHours(0, 0, 0, 0));

export enum DefaultTimeRange {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    TOMORROW = 'TOMORROW',
    THIS_WEEK = 'THIS_WEEK',
    LAST_WEEK = 'LAST_WEEK',
    NEXT_WEEK = 'NEXT_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    NEXT_MONTH = 'NEXT_MONTH',
    LAST_90_DAYS = 'LAST_90_DAYS',
    LAST_180_DAYS = 'LAST_180_DAYS',
    THIS_YEAR = 'THIS_YEAR',
    LAST_YEAR = 'LAST_YEAR',
    CUSTOM = 'CUSTOM',
}

export const getDefaultRangeValues = (r: DefaultTimeRange) => {
    let rangeValues: [Date, Date];
    let date = new Date();
    let date2;
    let year;
    switch (r) {
        case DefaultTimeRange.YESTERDAY:
            date = subtractDaysFromToday(1);
            rangeValues = [date, date];
            break;
        case DefaultTimeRange.TOMORROW:
            date = addDaysFromToday(1);
            rangeValues = [date, date];
            break;
        case DefaultTimeRange.THIS_WEEK:
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.LAST_WEEK:
            date = subtractDaysFromToday(7);
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.NEXT_WEEK:
            date = addDaysFromToday(7);
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.THIS_MONTH:
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.LAST_MONTH:
            date = subtractMonthsFromToday(1);
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.NEXT_MONTH:
            date = addMonthsFromToday(1);
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.LAST_90_DAYS:
            date2 = subtractDaysFromToday(90);
            rangeValues = [date2, date];
            break;
        case DefaultTimeRange.LAST_180_DAYS:
            date2 = subtractDaysFromToday(180);
            rangeValues = [date2, date];
            break;
        case DefaultTimeRange.THIS_YEAR:
            rangeValues = [getFirstDateOfYear(date), getLastDateOfYear(date)];
            break;
        case DefaultTimeRange.LAST_YEAR:
            year = date.getFullYear();
            rangeValues = [new Date(getFirstDateOfYear(date).setFullYear(year - 1)), new Date(getLastDateOfYear(date).setFullYear(year - 1))];
            break;
        default:
            rangeValues = [date, date];
    }
    return rangeValues;
};
