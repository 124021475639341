import { AxiosInstance, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash-es';

import {
    Response,
    AccountDTO,
    ApiResultOfResponse,
    BaseSearch,
    CostObjectiveItemsCountForUserDTO,
    CostObjectiveType,
    DimensionItemUnion,
    DimensionDTO,
    CustomCostObjectiveMediumDTO,
    GroupMemberDTO,
    PagedListContainer,
    SearchType,
    UserDTO,
    PasswordDTO,
    VatCodeDTO,
    UserSetting,
    GroupMemberCommonDTO,
    CompanyAccessCheckDTO,
    CompanyAccessCheckResponseDTO,
    UserCreationDTO,
    UserCreationResultDTO,
} from '../types/ApiTypes';
import { BackOfficeCompanyDTO, BackOfficeUserDTO, CompanySearchRequestParams } from '../types/BoApiTypes';
import { User } from '../ApiClient';

export default class UserService {
    constructor(private axios: AxiosInstance) {}

    addUser = async (userData: UserDTO): Promise<AxiosResponse<ApiResultOfResponse>> => {
        return this.axios.post('/User/AddUser', userData);
    };

    updateUser = async (userData: UserCreationDTO): Promise<AxiosResponse<UserCreationResultDTO>> => {
        return this.axios.post('/User/UpdateUser', userData);
    };

    getUsers = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<UserDTO>>> => {
        // return this.axios.post('/User/GetUsers', searchParams, {
        // Temporary using BO DB user data as of https://tickets.unifiedpost.com/browse/EMR-6113
        return this.axios.post('/User/GetUsersFromBo', searchParams, {
            headers: {
                skipTrimming: true,
            },
        });
    };

    getActiveUsersCount = async (): Promise<AxiosResponse<number>> => {
        return this.axios.post('/User/GetActiveUsersCount');
    };

    getCurrentUser = async (): Promise<AxiosResponse<User>> => {
        return this.axios.get('/User/GetCurrentUser');
    };

    changeUserPassword = async (passwords: PasswordDTO): Promise<AxiosResponse<Response<any>>> => {
        return this.axios.post('User/ChangeUserPassword', passwords);
    };

    addGroupMember = async (userData: GroupMemberDTO): Promise<AxiosResponse<UserDTO>> => {
        return this.axios.post('/groupMember/AddGroupMember', userData);
    };

    editGroupMember = async (userData: GroupMemberDTO): Promise<AxiosResponse<UserDTO>> => {
        return this.axios.post('/groupMember/EditGroupMember', userData);
    };

    getGroupMember = async (userGuid: string): Promise<AxiosResponse<GroupMemberDTO>> => {
        return this.axios.get(`/groupMember/GetGroupMember?userGuid=${userGuid}`);
    };

    getCommonGroupMember = async (groupMemberId: number): Promise<AxiosResponse<GroupMemberCommonDTO>> => {
        return this.axios.get(`/groupMember/GetCommonGroupMember?groupMemberId=${groupMemberId}`);
    };

    deleteSubstitute = async (id: number): Promise<AxiosResponse<GroupMemberDTO>> => {
        return this.axios.post(`/Substitute/Delete/${id}`);
    };

    getCostObjectiveItemsForUser = async (baseSearch: BaseSearch, getItemsForUserOnly = false, filterByDate: string = null): Promise<AxiosResponse<PagedListContainer<DimensionItemUnion>>> => {
        return this.axios.post(`/User/GetCostObjectiveItemsForUser?getItemsForUserOnly=${getItemsForUserOnly}&filterByDate=${filterByDate}`, baseSearch);
    };

    getActiveVatCodesForUser = async (baseSearch: BaseSearch, getItemsForUserOnly = true, filterByDate: string = null): Promise<AxiosResponse<PagedListContainer<VatCodeDTO>>> => {
        const restrictions = cloneDeep(baseSearch.Restrictions) || [];
        restrictions.push({
            Field: 'ObjectType',
            Value: CostObjectiveType.VatCode.toString(),
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        return this.getCostObjectiveItemsForUser(
            {
                ...baseSearch,
                Restrictions: restrictions,
            },
            getItemsForUserOnly,
            filterByDate,
        ) as Promise<AxiosResponse<PagedListContainer<VatCodeDTO>>>;
    };
    getActiveAccountsForUser = async (baseSearch: BaseSearch, getItemsForUserOnly = true, filterByDate: string = null): Promise<AxiosResponse<PagedListContainer<AccountDTO>>> => {
        const restrictions = cloneDeep(baseSearch.Restrictions) || [];
        restrictions.push({
            Field: 'ObjectType',
            Value: CostObjectiveType.Account.toString(),
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        return this.getCostObjectiveItemsForUser(
            {
                ...baseSearch,
                Restrictions: restrictions,
            },
            getItemsForUserOnly,
            filterByDate,
        ) as Promise<AxiosResponse<PagedListContainer<AccountDTO>>>;
    };

    getActiveCostObjectiveItemsForUser = async (
        baseSearch: BaseSearch,
        customCostObjectiveId: number,
        getItemsForUserOnly = true,
        filterByDate: string = null,
    ): Promise<AxiosResponse<PagedListContainer<DimensionDTO>>> => {
        const restrictions = cloneDeep(baseSearch.Restrictions) || [];
        restrictions.push({
            Field: 'ObjectType',
            Value: CostObjectiveType.CustomCostObjective.toString(),
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        restrictions.push({
            Field: 'CustomCostObjectiveId',
            Value: customCostObjectiveId,
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        if (customCostObjectiveId === 0) {
            console.warn('request for all of the CostObjectiveItems dimensions detected, this should be avoided');
        }

        return this.getCostObjectiveItemsForUser(
            {
                ...baseSearch,
                Restrictions: restrictions,
            },
            getItemsForUserOnly,
            filterByDate,
        ) as Promise<AxiosResponse<PagedListContainer<DimensionDTO>>>;
    };

    getCostObjectivesAndItemsCountForUser = async (
        baseSearch: BaseSearch,
        getItemsForUserOnly = false,
        filterByDate: string = null,
    ): Promise<AxiosResponse<PagedListContainer<CustomCostObjectiveMediumDTO>>> => {
        const restrictions = cloneDeep(baseSearch.Restrictions) || [];
        restrictions.push({
            Field: 'ObjectType',
            Value: CostObjectiveType.CustomCostObjective.toString(),
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        restrictions.push({
            Field: 'CustomCostObjectiveId',
            Value: 0, //CCOs only
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        return this.axios.post(`/User/GetCostObjectiveItemsCountForUser?getItemsForUserOnly=${getItemsForUserOnly}&filterByDate=${filterByDate}`, {
            ...baseSearch,
            Restrictions: restrictions,
        });
    };

    getCostObjectiveItemsCountForUser = async (userGuid: string): Promise<AxiosResponse<CostObjectiveItemsCountForUserDTO[]>> => {
        return this.axios.get(`/User/GetCostObjectiveItemsCountForUser?userGuid=${userGuid}`);
    };

    toggleCostObjectiveItemForUser = async (userGuid: string, objectType: number, objectId: number): Promise<AxiosResponse<number>> => {
        return this.axios.get(`User/ToggleCostObjectiveItemForUser?userGuid=${userGuid}&objectType=${objectType}&objectId=${objectId}`);
    };

    getUserCompanies = async (params?: CompanySearchRequestParams): Promise<AxiosResponse<BackOfficeCompanyDTO[]>> => {
        return this.axios.get('BO/GetUserCompanies', { params });
    };

    bulkToggleCostObjectiveItemsForUser = async (isSelected: boolean, userGuid: string, objectType: CostObjectiveType, dimensionIds?: number[], ccoId?: number): Promise<AxiosResponse<number>> => {
        let url = `User/BulkToggleCostObjectiveItemsForUser?userGuid=${userGuid}&objectType=${objectType}&value=${isSelected}`;
        if (ccoId) {
            url += `&ccoId=${ccoId}`;
        }
        return this.axios.post(url, dimensionIds ?? []);
    };

    updateUserSettings = async (groupMemberId: number, userSettings: UserSetting[]): Promise<AxiosResponse<UserSetting[]>> => {
        return this.axios.post('GroupMember/UpdateUserSettings', userSettings, {
            params: {
                groupMemberId,
            },
        });
    };

    setSessionCompany = (companyGuid: string, userGuid: string): Promise<AxiosResponse<boolean>> => {
        // attention! body structure must match the following: {'userGuid':'', 'companyGuid':''}
        return this.axios.post('Session/SetCompany', {
            userGuid,
            companyGuid,
        });
    };

    checkAccessToCompany = async (body: CompanyAccessCheckDTO): Promise<AxiosResponse<CompanyAccessCheckResponseDTO>> => {
        return this.axios.post('/GroupMember/CheckAccessToCompany', body);
    };

    exportUsersToCsv = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/User/ExportUsersToCSV`, search, {
            responseType: 'blob',
        });
    };

    exportUsersToXls = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/User/ExportUsersToXls`, search, {
            responseType: 'blob',
        });
    };

    addNewUser = async (userData: UserCreationDTO): Promise<AxiosResponse<UserCreationResultDTO>> => {
        return this.axios.post('/User/CreateUser', userData);
    };

    isEmailUnique = async (email: string, userGuid: string, useGlobalSearch = false): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`User/IsEmailUnique?email=${encodeURIComponent(email)}${userGuid && `&userBoGuid=${userGuid}`}&useGlobalSearch=${useGlobalSearch}`);
    };

    isPersonalCodeUnique = async (personalCode: string, userGuid: string, country: string, useGlobalSearch = false): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`User/IsPersonalCodeUnique?personalCode=${personalCode}${userGuid && `&userBoGuid=${userGuid}`}&country=${country}&useGlobalSearch=${useGlobalSearch}`);
    };

    getUserByEmailOnly = async (email: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get(`User/GetUserByEmailOnly?email=${encodeURIComponent(email)}`);
    };
}
