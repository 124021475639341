import { AxiosInstance, AxiosResponse } from 'axios';
import { OrganizationShort, BackOfficeOrganizationDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../types/ApiTypes';

export default class BoOrganizationsService {
    constructor(private axios: AxiosInstance) {}

    getOrganizations = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeOrganizationDTO>>> => {
        return this.axios.post('/Organization/GetOrganizations', searchParams, {
            headers: {
                skipTrimming: true,
            },
        });
    };

    getOrganizationById = async (organizationGuid: string): Promise<AxiosResponse<PagedListContainerOf<BackOfficeOrganizationDTO>>> => {
        return this.axios.post('/Organization/GetById', {
            params: {
                organizationGuid,
            },
        });
    };

    saveOrganization = async (organization: OrganizationShort | BackOfficeOrganizationDTO): Promise<AxiosResponse<BackOfficeOrganizationDTO>> => {
        return this.axios.post('/Organization/SaveOrganization', organization);
    };

    deleteOrganization = async (organizationGuid: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.post('/Organization/DeleteOrganization', {
            params: {
                organizationGuid,
            },
        });
    };
}
