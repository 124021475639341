import { TFunction } from 'i18next';
import * as React from 'react';
import Icon, { ICONS } from '../../../components/Icon/Icon';
import { languages } from '../../../i18n';

export interface LanguageButtonProps {
    children?: React.ReactNode;
    onClick?: () => void;
    languageCode: string;
    t: TFunction;
}

function getLanguage(languageCode: string) {
    return languages.find((l) => l.locale === languageCode);
}

const LanguageButton: React.FunctionComponent<LanguageButtonProps> = ({ onClick, languageCode, t }: LanguageButtonProps) => {
    const language = getLanguage(languageCode);
    if (!language) {
        return null;
    }
    return (
        <div className="login__lang-button">
            <span>{t('views.login.language')}</span>
            <button onClick={onClick} data-id="lang.button">
                <Icon className="login__flag-icon" name={language.icon} />
                {language.name}
                <Icon className="login__arrow-down-icon" name={ICONS.ARROW_DOWN_SMALL} />
            </button>
        </div>
    );
};

export default LanguageButton;
