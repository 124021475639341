import { AxiosInstance, AxiosResponse } from 'axios';

import { Response, BaseSearch, HttpResponseMessage, PagedListContainer, WorkflowTemplateDTO, WorkflowTemplateItem } from '../types/ApiTypes';

export default class WorkflowTemplateService {
    constructor(private axios: AxiosInstance) {}

    addWorkflowTemplate = async (workflowTemplate: WorkflowTemplateDTO): Promise<AxiosResponse<Response<WorkflowTemplateDTO>>> => {
        return this.axios.post('/WorkflowTemplate/AddWorkflowTemplate', workflowTemplate);
    };

    updateWorkflowTemplate = async (workflowTemplate: WorkflowTemplateDTO): Promise<AxiosResponse<Response<WorkflowTemplateDTO>>> => {
        return this.axios.post('/WorkflowTemplate/UpdateWorkflowTemplate', workflowTemplate);
    };

    updateTemplates = async (workflowTemplate: WorkflowTemplateDTO): Promise<AxiosResponse<WorkflowTemplateDTO>> => {
        return this.axios.post('/WorkflowTemplate/UpdateTemplates', workflowTemplate);
    };

    getCompanyTemplates = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<WorkflowTemplateDTO>>> => {
        return this.axios.post('/WorkflowTemplate/GetCompanyTemplates', search);
    };

    getById = async (id: number): Promise<AxiosResponse<WorkflowTemplateDTO>> => {
        return this.axios.get('/WorkflowTemplate/GetById/' + id);
    };

    deleteTemplates = async (workflowTemplate: WorkflowTemplateDTO): Promise<AxiosResponse<HttpResponseMessage>> => {
        return this.axios.post('/WorkflowTemplate/DeleteTemplates', workflowTemplate);
    };

    AddWorkflowTemplateItem = async (workflowTemplates: WorkflowTemplateItem[]): Promise<AxiosResponse<HttpResponseMessage>> => {
        return this.axios.post('/WorkflowTemplate/AddWorkflowTemplateItem', workflowTemplates);
    };
}
