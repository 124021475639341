import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import JwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash-es';

import withSuspense from '../../common/hocs/withSuspense';
import currentLanguage, { storedLanguage } from '../../common/utils/languageHelper';
import { is1to1, isGrantThornton, isMaxima, isSuf, isSwedbank } from '../../common/utils/whitelabelHelper';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import KeyboardFocuser from '../../components/KeyboardFocuser/KeyboardFocuser';
import WhitelabelHelmet from '../../components/WhitelabelHelmet/WhitelabelHelmet';
import i18nInstance, { languages } from '../../i18n';
import api from '../../services/ApiServices';
import ErrorPage from '../error-page/ErrorPageView';
import MaintenanceView from '../maintenance/MaintenanceView';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import {
    selectedLanguageKey,
    selectedCountryKey,
    initSession,
    CountryDetectionStatus,
    SUPPORTED_COUNTRIES,
    redirectToApp,
    serbianSSOKey,
    getUserBrowserLanguage,
    getGeoIpCountry,
    getUserEmailByResetPasswordToken,
} from './loginHelper';
import LoginView from './LoginView';
import AccountSetup from './components/account-setup/AccountSetup';
import Onboarding from './components/onboarding/Onboarding';
import ResetPassword from './components/reset-password/ResetPassword';
import EmailVerification from './components/email-verification/EmailVerification';

export interface RootProps {
    children?: React.ReactNode;
}

type Props = RootProps & WithTranslation;

export interface State {
    countryDetectionStatus: CountryDetectionStatus;
    selectedCountry: string;
    selectedLanguage: string;
    isCheckingWebAccess: boolean;
    verificationToken: string;
    resetPasswordEmail: string;
    resetPasswordToken: string;
    emailVerificationToken: string;
}

class Root extends BaseStatefulComponent<Props, State> {
    private areWebStoreLinksHidden = is1to1() || isGrantThornton() || isSuf() || isMaxima() || isSwedbank();
    private isLoginPage = window.location.href.toLowerCase().includes('login');

    constructor(props: Props) {
        super(props);
        this.state = {
            countryDetectionStatus: CountryDetectionStatus.DETECTING,
            selectedCountry: localStorage.getItem(selectedCountryKey),
            selectedLanguage: currentLanguage(),
            isCheckingWebAccess: true,
            verificationToken: null, // if present, this is account setup (user onboarding) page
            resetPasswordEmail: null,
            resetPasswordToken: null,
            emailVerificationToken: null,
        };
    }

    async componentWillMount() {
        this.checkIsAccountSetupPage();
        this.checkIsEmailResetPasswordPage();
        this.checkIsEmailVerificationPage();
        await this.checkExternalLogin();
    }

    isCountrySupported(countryCode: string) {
        return !!SUPPORTED_COUNTRIES.find((c) => c.value === countryCode);
    }

    checkIsAccountSetupPage() {
        const url = new URL(window.location.href);
        const isEmailVerification = url.searchParams.get('account-setup') === 'true';
        const verificationToken = url.searchParams.get('verification-token');

        if (isEmailVerification && verificationToken) {
            this.setState({
                verificationToken,
            });
        }
    }

    async checkIsEmailResetPasswordPage() {
        const url = new URL(window.location.href);
        const isPasswordReset = url.searchParams.get('reset_password') === 'true';
        const resetPasswordToken = url.searchParams.get('token');

        if (isPasswordReset && resetPasswordToken) {
            const resetPasswordEmail = await getUserEmailByResetPasswordToken(resetPasswordToken);
            if (resetPasswordEmail) {
                this.setState({
                    resetPasswordToken,
                    resetPasswordEmail,
                });
            }
        }
    }

    checkIsEmailVerificationPage() {
        const url = new URL(window.location.href);
        const isEmailVerification = url.searchParams.get('validate_email') === 'true';
        const emailVerificationToken = url.searchParams.get('token');

        if (isEmailVerification && emailVerificationToken) {
            this.setState({
                emailVerificationToken,
            });
        }
    }

    initSessionAndRedirectToApp = async (url: URL, token: string, redirect: string) => {
        const companyGuid = url.searchParams.get('companyGuid');
        const serbianSSO = url.searchParams.get('sso');
        const tokenDecoded: any = JwtDecode(token);
        await initSession(
            {
                Token: token,
                ExpirationTime: new Date(tokenDecoded.ExpirationDate),
                UserLastCompanyGuid: tokenDecoded.BackOfficeCompanyGuid,
            },
            undefined,
        );
        const language = url.searchParams.get('language');
        const country = url.searchParams.get('country');

        if (language && languages.find((l) => l.locale === language)) {
            this.setLanguage(language);
        }

        if (country && SUPPORTED_COUNTRIES.find((c) => c.value === country)) {
            this.setCountry(country);
        }

        if (serbianSSO) {
            this.setSerbianSSO();
        }
        redirectToApp(undefined, companyGuid ? `${redirect}?companyGuid=${companyGuid}` : redirect);
    };

    // Is used also for user login on initial password setup EMR-6079
    checkExternalLogin = async () => {
        if (!this.isLoginPage) {
            return;
        }
        const url = new URL(window.location.href);
        const origin = window.location.href.split('?')[0];
        const token = url.searchParams.get('token');
        const redirect = url.searchParams.get('redirect');
        const code = url.searchParams.get('code');
        const loginType = url.searchParams.get('type');

        try {
            if (loginType && loginType === 'keycloak') {
                await api.login.getKeycloakAuthUrl({ RedirectUri: origin, Locale: localStorage.getItem(selectedLanguageKey), Verifier: '' }).then((result) => {
                    if (!isEmpty(result.data)) {
                        localStorage.setItem('keycloak', 'true');
                        window.location.href = result.data;
                    }
                });
            }
            if (code) {
                // check if it's supposed to be keycloak
                const keycloakItem = localStorage.getItem('keycloak');
                if (keycloakItem && keycloakItem === 'true') {
                    localStorage.removeItem('keycloak');
                    localStorage.setItem('loadingRedirect', 'true');
                    await api.login.keycloakLogin({ Code: code, RedirectUri: origin }).then((result) => {
                        if (result.data && result.data.Token) {
                            this.initSessionAndRedirectToApp(url, result.data.Token, 'true');
                        } else {
                            const errUri = origin + `?error&status=${result.data.AuthStatus}`;
                            api.login.getKeycloakLogoutUrl({ RedirectUri: errUri }).then((result) => {
                                window.location.href = result.data;
                            });
                        }
                    });
                }
            }
            if (token && redirect) {
                // for the case on onboarding e-mail verification
                await this.initSessionAndRedirectToApp(url, token, redirect);
            } else {
                this.setState({
                    isCheckingWebAccess: false,
                });
            }
        } catch (error) {
            console.error(error);
            this.setState({
                isCheckingWebAccess: false,
            });
        } finally {
            localStorage.removeItem('loadingRedirect');
        }
    };

    getCountry = async () => {
        const countryCode = await getGeoIpCountry();
        if (countryCode && this.isCountrySupported(countryCode)) {
            this.setCountry(countryCode);
        } else {
            this.setState({
                countryDetectionStatus: CountryDetectionStatus.UNAVAILABLE,
            });
        }
    };

    async componentDidMount() {
        const savedLanguage = storedLanguage();
        // if no saved language, try to detect it and change it, i18next will use the localStorage language by default
        if (!savedLanguage) {
            this.setLanguage(getUserBrowserLanguage());
        }

        if (SUPPORTED_COUNTRIES.find((c) => c.value === this.state.selectedCountry)) {
            this.setState({
                countryDetectionStatus: CountryDetectionStatus.AVAILABLE,
            });
            return;
        }
        // only use ipInfo if no selected language exists
        api.login
            .ipInfo()
            .then((response) => {
                const ipInfo = response.data;
                // as per https://fitekgroup.atlassian.net/browse/EMR-4395
                if (ipInfo.CountryCode === 'VN') {
                    ipInfo.CountryCode = 'EE';
                }

                if (ipInfo.CountryCode && this.isCountrySupported(ipInfo.CountryCode)) {
                    this.setCountry(ipInfo.CountryCode);
                } else {
                    this.setState({
                        countryDetectionStatus: CountryDetectionStatus.UNAVAILABLE,
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                this.getCountry();
            });
    }

    setLanguage = (locale: string) => {
        localStorage.setItem(selectedLanguageKey, locale);
        i18nInstance.changeLanguage(locale);
        this.setState({
            selectedLanguage: locale,
        });
    };

    setCountry = (countryCode: string) => {
        localStorage.setItem(selectedCountryKey, countryCode);
        this.setState({
            selectedCountry: countryCode,
            countryDetectionStatus: CountryDetectionStatus.AVAILABLE,
        });
    };

    setSerbianSSO = () => {
        localStorage.setItem(serbianSSOKey, 'true');
    };

    render() {
        const { t } = this.props;
        const { countryDetectionStatus, selectedLanguage, selectedCountry, isCheckingWebAccess, verificationToken, resetPasswordEmail, resetPasswordToken, emailVerificationToken } = this.state;

        if (isCheckingWebAccess && this.isLoginPage) {
            return null;
        }

        const isRedirect = localStorage.getItem('loadingRedirect');

        const renderView = () => {
            if (window.location.href.includes('onboarding')) {
                return <Onboarding selectedLanguage={selectedLanguage} />;
            }
            if (window['IS_MAINTENANCE']) {
                return <MaintenanceView />;
            }

            if (this.isLoginPage) {
                if (isRedirect) {
                    return (
                        <div className="loading-message">
                            <span className="main-text">{t('views.login.securingSession')}</span>
                            <span className="small-text">{t('views.login.pleaseWait')}</span>
                        </div>
                    );
                }
                if (verificationToken) {
                    // onboarding e-mail verification
                    return <AccountSetup token={verificationToken} selectedCountry={selectedCountry} />;
                }
                if (resetPasswordToken) {
                    return <ResetPassword email={resetPasswordEmail} token={resetPasswordToken} selectedCountry={selectedCountry} />;
                }
                if (emailVerificationToken) {
                    return <EmailVerification token={emailVerificationToken} />;
                }
                return (
                    <LoginView
                        areWebStoreLinksHidden={this.areWebStoreLinksHidden}
                        countryDetectionStatus={countryDetectionStatus}
                        selectedCountry={selectedCountry}
                        setCountry={this.setCountry}
                        setLanguage={this.setLanguage}
                    />
                );
            }
            return <ErrorPage />;
        };

        return (
            <>
                <WhitelabelHelmet />
                <KeyboardFocuser />
                <div className="app">
                    <Header
                        countryDetectionStatus={countryDetectionStatus}
                        selectedCountry={selectedCountry}
                        selectedLanguage={selectedLanguage}
                        t={t}
                        setCountry={this.setCountry}
                        setLanguage={this.setLanguage}
                    />
                    {renderView()}
                    {!this.areWebStoreLinksHidden && !isRedirect && <Footer t={t} />}
                </div>
            </>
        );
    }
}

export default withSuspense(withTranslation()(Root));
