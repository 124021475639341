import classNames from 'classnames';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import { debounce, isEmpty, noop } from 'lodash-es';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import { DataId } from '../../common/utils/dataId';
import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import { BaseComponent } from '../BaseComponent';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import Checkbox from '../Checkbox/Checkbox';
import Scrollbars from '../Scrollbars/Scrollbars';
import SectionSeparator from '../SectionSeparator/SectionSeparator';
import Tag from '../Tag/Tag';
import { TextInput, TextInputType } from '../TextInput/TextInput';
import { TypeaheadMenuWrapper } from '../Typeahead/Typeahead';

import './TagSelect.scss';

export interface Props<T> {
    placeholder?: string;
    onChange?: (items: Array<TagSelectItem<T>>) => void;
    onClose?: (items: Array<TagSelectItem<T>>) => void;
    values?: Array<TagSelectItem<T>>;
    filter?: (item: TagSelectItem<T>, inputText: string) => boolean;
    debounceInterval?: number;
    positionFixed?: boolean;
    className?: string;
    triggerClass?: string;
    /*
        use for async items
     */
    loadItems?: (input: string) => Promise<Array<TagSelectItem<T>>>;
    /*
        use for predefined items
     */
    items?: Array<TagSelectItem<T>>;
    noResultsText?: React.ReactNode;
    searchingText?: React.ReactNode;
    searchOnFocus?: boolean;
    limitTo?: number;
    limitToText?: React.ReactNode;
    error?: React.ReactNode;
    onTextInputChange?: (value: string) => void;
    onTextInputBlur?: () => void;
    children?: React.ReactNode;
    type?: TagSelectType;
    resultsTitle?: React.ReactNode;
    clearText?: React.ReactNode;
    dataId?: DataId;
    multiSelect?: boolean;
    clearSearchOnEnter?: boolean;
    autoOpen?: boolean;
    tabIndex?: number;
}

export enum TagSelectType {
    DEFAULT = 'DEFAULT',
    COMPACT = 'COMPACT',
    COMPACT_WITH_FILTER = 'COMPACT_WITH_FILTER',
}

export type TagSelectProps<T> = Props<T> & WithTranslation;

export interface TagSelectState<T> {
    items: Array<TagSelectItem<T>>;
    loading: boolean;
    error: boolean;
    latestQueryText: string;
    isResultsLimited: boolean;
    isOpen: boolean;
    selectedItems: Array<TagSelectItem<T>>;
}

export interface TagSelectItem<T> {
    value: T;
    text: string;
    children?: Array<TagSelectItem<T>>;
    parent?: TagSelectItem<T>;
}

interface TagSelectPositionerProps {
    scheduleUpdate: () => void;
}

export const TagSelectRepositionerContext = React.createContext<any>(undefined);

class TagSelectPositioner extends BaseComponent<TagSelectPositionerProps> {
    render() {
        return (
            <TagSelectRepositionerContext.Consumer>
                {(value) => {
                    if (this.props.scheduleUpdate && value) {
                        this.props.scheduleUpdate();
                    }
                    return null;
                }}
            </TagSelectRepositionerContext.Consumer>
        );
    }
}

export class TagSelectInternal<T> extends BaseStatefulComponent<TagSelectProps<T>, TagSelectState<T>> {
    private componentRootElement = React.createRef<HTMLDivElement>();
    private inputRef = React.createRef<HTMLInputElement>();
    private outsideEventListener: OutsideEventListener;

    static defaultProps: Partial<TagSelectProps<any>> = {
        debounceInterval: 300,
        items: [],
        filter: (item, inputValue) => {
            return !inputValue || item.text.toLowerCase().includes(inputValue.toLowerCase());
        },
        type: TagSelectType.DEFAULT,
        multiSelect: true,
    };

    constructor(props: TagSelectProps<T>) {
        super(props);
        this.state = {
            selectedItems: this.props.values || [],
            items: this.sortItems(this.props.items),
            loading: false,
            error: undefined,
            latestQueryText: undefined,
            isResultsLimited: false,
            isOpen: false,
        };
    }

    componentWillUnmount() {
        if (this.outsideEventListener) {
            this.outsideEventListener.stop();
        }
    }

    componentDidMount(): void {
        if (this.props.autoOpen) {
            this.setState((prevState) => ({
                ...prevState,
                isOpen: this.props.autoOpen,
            }));
        }
    }

    componentDidUpdate(prevProps: TagSelectProps<T>, prevState: TagSelectState<T>) {
        if (this.props.values !== prevProps.values) {
            this.setState({ selectedItems: this.props.values });
        }
        if (this.props.items !== prevProps.items) {
            this.setState({
                items: this.sortItems(this.props.items),
            });
        }

        if (this.state.isOpen && !prevState.isOpen) {
            if (this.outsideEventListener) {
                this.outsideEventListener.stop();
            }
            this.outsideEventListener = new OutsideEventListener(this.componentRootElement, this.debounceCloseDropdown, false);
            this.outsideEventListener.start();
        }
    }

    isSyncSelect = () => {
        return !this.props.loadItems;
    };

    isCompact() {
        return [TagSelectType.COMPACT].includes(this.props.type);
    }

    stateReducer = (state: DownshiftState<TagSelectItem<T>>, changes: StateChangeOptions<TagSelectItem<T>>) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                const newState: StateChangeOptions<TagSelectItem<T>> = {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: this.isCompact() ? '' : this.state.latestQueryText,
                };
                if (this.props.clearSearchOnEnter) {
                    this.handleInputChange('');
                    newState.highlightedIndex = undefined;
                    newState.inputValue = '';
                }
                return newState;
            default:
                return changes;
        }
    };

    preventPropagation = (e: any) => {
        e.stopPropagation();
    };

    callOnChange = () => {
        const { selectedItems } = this.state;
        if (this.props.onChange) {
            this.props.onChange(selectedItems);
        }
    };

    handleSelection = (selectedItem: TagSelectItem<T>) => {
        if (
            selectedItem.parent
                ? this.state.selectedItems.find((i) => i.value === selectedItem.parent.value)?.children?.find((i) => i.value === selectedItem.value)
                : this.state.selectedItems.find((i) => i.value === selectedItem.value)
        ) {
            this.removeItem(selectedItem, this.callOnChange);
        } else {
            this.addSelectedItem(selectedItem, this.callOnChange);
        }
    };

    debounceHandleSelection = debounce(this.handleSelection, 10); // workaround for https://github.com/downshift-js/downshift/issues/465

    removeItem = (item: TagSelectItem<T>, cb = () => {}) => {
        this.setState(({ selectedItems }) => {
            // removing a child but the parent should be still there
            if (item.parent) {
                const parentIndex = selectedItems.findIndex((p) => p.value === item.parent.value);
                selectedItems[parentIndex].children = selectedItems[parentIndex].children.filter((p) => p.value !== item.value);
                return {
                    selectedItems: [...selectedItems],
                };
            }
            return {
                selectedItems: selectedItems.filter((p) => p.value !== item.value),
            };
        }, cb);
    };

    clearAll = () => {
        this.setState(
            {
                selectedItems: [],
            },
            () => {
                this.callOnChange();
            },
        );
    };

    addSelectedItem(item: TagSelectItem<T>, cb = () => {}) {
        this.setState(({ selectedItems }) => {
            // adding a child then add the parent if it's not already there
            if (item.parent) {
                const parentIndex = selectedItems.findIndex((p) => p.value === item.parent.value);
                if (parentIndex === -1) {
                    return {
                        selectedItems: [...selectedItems, { ...item.parent, children: [item] }],
                    };
                } else {
                    selectedItems[parentIndex].children = [...(selectedItems[parentIndex].children || []), item];
                    return {
                        selectedItems: [...selectedItems],
                    };
                }
            }
            // adding a parent with all children
            return {
                selectedItems: [...selectedItems, item],
            };
        }, cb);
    }

    checkChildValue(item: TagSelectItem<T>) {
        const { selectedItems } = this.state;
        if (!selectedItems.some((p) => p.value === item.parent.value)) {
            return false;
        }
        return selectedItems.find((p) => p.value === item.parent.value).children?.some((p) => p.value === item.value);
    }

    sortItems = (items: Array<TagSelectItem<T>>) =>
        items
            .map((p) => {
                return { ...p, children: p.children || [] };
            })
            .sort((a, b) => a.children.length - b.children.length) || [];

    handleFocus = (cb: () => void) => {
        if (this.props.searchOnFocus) {
            if (!this.isSyncSelect()) {
                this.setState({ loading: true });
            }
            this.handleInputChange(this.state.latestQueryText || '');
            cb();
        }
    };

    handleBlur = () => {
        if (typeof this.props.onTextInputBlur === 'function') {
            this.props.onTextInputBlur();
        }
    };

    closeDropdown = (closed = true) => {
        this.setState({
            isOpen: !closed,
        });
        if (closed) {
            this.setState({
                latestQueryText: '',
            });
        }
    };

    debounceCloseDropdown = debounce(this.closeDropdown, 100);

    handleInputChange = async (input: string) => {
        this.setState({
            latestQueryText: input,
        });

        if (this.isSyncSelect()) {
            this.setState({
                latestQueryText: input,
            });
            this.setState({
                items: this.props.items.filter((i) => {
                    return this.props.filter(i, input);
                }),
            });
            return;
        }

        // async handling
        if (!this.props.searchOnFocus && (!input || input.length === 0)) {
            this.setState({
                items: [],
                loading: false,
                isResultsLimited: false,
            });
            return;
        }
        try {
            let items: Array<TagSelectItem<any>> = [];
            if (this.props.loadItems) {
                items = await this.props.loadItems(input);
                if (!items) {
                    items = [];
                }
            }
            const isResultsLimited = this.props.limitTo && items.length > this.props.limitTo;
            if (isResultsLimited) {
                items = items.slice(0, this.props.limitTo);
            }
            // only set the response when the query string matches current input, otherwise it is outdated response
            if (input === this.state.latestQueryText) {
                this.setState({
                    items,
                    loading: false,
                    isResultsLimited,
                });
            } else {
                this.setState({
                    loading: false,
                    isResultsLimited,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                loading: false,
                error: e,
                isResultsLimited: false,
            });
        }
    };

    debouncedHandleInputChange = debounce(this.handleInputChange, this.isSyncSelect() ? 0 : this.props.debounceInterval);

    render() {
        const { multiSelect, placeholder, searchOnFocus, className, triggerClass, t, children } = this.props;
        const classes = classNames('tagselect', className, { 'tagselect--compact': this.isCompact() });
        const triggerClasses = classNames('tagselect-trigger', triggerClass);
        const { items, selectedItems } = this.state;
        return (
            <>
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <span
                                className={triggerClasses}
                                data-id={`tagSelect.trigger.${this.props.dataId}`}
                                tabIndex={this.props.tabIndex || 0}
                                role={'button'}
                                onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                                    if (e.key === 'Enter') {
                                        e.stopPropagation();
                                        if (this.state.isOpen) {
                                            return;
                                        }
                                        this.closeDropdown(false);
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.bubbles = false;
                                    if (this.state.isOpen) {
                                        return;
                                    }
                                    this.closeDropdown(false);
                                }}
                                ref={ref}
                            >
                                {children}
                            </span>
                        )}
                    </Reference>

                    {ReactDOM.createPortal(
                        <CSSTransition unmountOnExit={true} classNames="fade" in={this.state.isOpen} timeout={150}>
                            <Popper
                                innerRef={(el) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                    // @ts-ignore
                                    this.componentRootElement.current = el;
                                }}
                                positionFixed={!!this.props.positionFixed}
                                placement={'bottom-start'}
                                modifiers={{
                                    offset: {
                                        offset: '0, 12px',
                                    },
                                    flip: {
                                        behavior: ['bottom', 'top', 'bottom'],
                                    },
                                }}
                            >
                                {({ ref, style, placement, scheduleUpdate, arrowProps }) => (
                                    <div ref={ref} style={style} data-placement={placement} className={classes} onClick={this.preventPropagation} data-id={this.props.dataId}>
                                        <span className="tagselect__arrow" ref={arrowProps.ref} style={arrowProps.style} />
                                        <TagSelectPositioner scheduleUpdate={scheduleUpdate} />
                                        <Downshift
                                            isOpen={true}
                                            defaultHighlightedIndex={0}
                                            stateReducer={this.stateReducer}
                                            onChange={this.debounceHandleSelection}
                                            itemToString={(item) => (item ? item.text : '')}
                                            selectedItem={null}
                                        >
                                            {({ getInputProps, getItemProps, getMenuProps, inputValue, highlightedIndex, openMenu }) => {
                                                return (
                                                    <div>
                                                        <div className="tagselect__input-wrap">
                                                            <TextInput
                                                                error={this.props.error}
                                                                hideError={true}
                                                                forwardRef={this.inputRef}
                                                                placeholder={placeholder}
                                                                {...getInputProps()}
                                                                autofocus={true}
                                                                showClear={true}
                                                                type={TextInputType.SEARCH_COMPACT}
                                                                disableAutofocusScroll={true}
                                                                onFocus={() => {
                                                                    this.handleFocus(openMenu);
                                                                }}
                                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                    this.handleBlur();
                                                                    getInputProps().onBlur(e);
                                                                }}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    this.debouncedHandleInputChange(e.target.value);
                                                                    getInputProps().onChange(e);
                                                                    if (!this.isSyncSelect()) {
                                                                        this.setState({ loading: true });
                                                                    }
                                                                    if (this.props.onTextInputChange) {
                                                                        this.props.onTextInputChange(e.target.value);
                                                                    }
                                                                }}
                                                                dataId={`${this.props.dataId ? this.props.dataId : 'tagSelect'}.filterInput`}
                                                            />
                                                        </div>
                                                        {!this.isCompact() && (
                                                            <SectionSeparator className="mx-5">
                                                                {this.props.resultsTitle || t('component.TagSelect.Results')} ({items.length})
                                                            </SectionSeparator>
                                                        )}
                                                        <Scrollbars autoHeightMax={260}>
                                                            <TypeaheadMenuWrapper scheduleUpdate={scheduleUpdate} itemsOrSearchString={selectedItems} loading={this.state.loading}>
                                                                <ul className="tagselect__list" role="menu" {...getMenuProps()} data-id={`${this.props.dataId || 'tagSelect'}.list`}>
                                                                    {items.map((item, index) => (
                                                                        <li
                                                                            className={`tagselect__list-item ${highlightedIndex === index ? 'tagselect__list-item--active' : ''}`}
                                                                            key={index}
                                                                            data-id={`${this.props.dataId || 'tagSelect'}.list.${index}`}
                                                                        >
                                                                            <div
                                                                                className="tagselect__list-item-text"
                                                                                {...getItemProps({
                                                                                    key: index,
                                                                                    index,
                                                                                    item,
                                                                                    onClick: () => {
                                                                                        if (!multiSelect) {
                                                                                            this.closeDropdown();
                                                                                        }
                                                                                    },
                                                                                })}
                                                                                tabIndex={-1}
                                                                                data-id={`${this.props.dataId || 'tagSelect'}.list.${index}.a`}
                                                                            >
                                                                                {multiSelect ? (
                                                                                    <Checkbox
                                                                                        onChange={noop} // adding because of React warning
                                                                                        label={item.text}
                                                                                        name={`option-${index}-checkbox`}
                                                                                        value={selectedItems && !!selectedItems.find((i) => i.value === item.value)}
                                                                                    />
                                                                                ) : (
                                                                                    <span data-id={`${this.props.dataId || 'tagSelect'}.list.${index}.a.span`}>{item.text}</span>
                                                                                )}
                                                                            </div>
                                                                            {item.children?.length > 0 && (
                                                                                <ul
                                                                                    className="tagselect__list-children"
                                                                                    role="menu"
                                                                                    {...getMenuProps()}
                                                                                    data-id={`${this.props.dataId || 'tagSelect'}.list-children`}
                                                                                >
                                                                                    {item.children.map((child, childIndex) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={`${childIndex}-child`}
                                                                                                className={'tagselect__list-item-child'}
                                                                                                data-id={`${this.props.dataId || 'tagSelect'}.list.${index}-child`}
                                                                                            >
                                                                                                <div
                                                                                                    className="tagselect__list-item-text tagselect__list-item-text-child"
                                                                                                    tabIndex={-1}
                                                                                                    data-id={`${this.props.dataId || 'tagSelect'}.children.${childIndex}.a`}
                                                                                                    onClick={() => this.handleSelection(child)}
                                                                                                >
                                                                                                    {multiSelect ? (
                                                                                                        <Checkbox
                                                                                                            onChange={noop} // adding because of React warning
                                                                                                            label={child.text}
                                                                                                            name={`option-child-${childIndex}-checkbox`}
                                                                                                            value={this.checkChildValue(child)}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <span data-id={`${this.props.dataId || 'tagSelect'}.children.${childIndex}.a.span`}>
                                                                                                            {child.text}
                                                                                                        </span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                                    {(inputValue || searchOnFocus) && items.length === 0 && !this.state.loading && (
                                                                        <li data-id={`${this.props.dataId || 'tagSelect'}.list.noResults`} className={`tagselect__list-item`}>
                                                                            <span className="tagselect__list-item-text">{this.props.noResultsText || t('component.Typeahead.NoResults')}</span>
                                                                        </li>
                                                                    )}
                                                                    {(inputValue || searchOnFocus) && items.length === 0 && this.state.loading && (
                                                                        <li data-id={`${this.props.dataId ? this.props.dataId : 'tagSelect'}.list.searching`} className={`tagselect__list-item`}>
                                                                            <span className="tagselect__list-item-text">{this.props.searchingText || t('component.Typeahead.Searching')}</span>
                                                                        </li>
                                                                    )}
                                                                    {this.state.isResultsLimited && !this.state.loading && (
                                                                        <li data-id={`${this.props.dataId ? this.props.dataId : 'tagSelect'}.list-resultsLimited`} className={`tagselect__list-item`}>
                                                                            <span className="tagselect__list-item-text tagselect__list-item-text--results-limited">
                                                                                {this.props.limitToText || t('component.Typeahead.SpecifySearch')}
                                                                            </span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </TypeaheadMenuWrapper>
                                                        </Scrollbars>
                                                        {!this.isCompact() && selectedItems.length > 0 && multiSelect && (
                                                            <SectionSeparator className="mx-5">
                                                                {t('component.TagSelect.AppliedFilter')} ({selectedItems.length})
                                                            </SectionSeparator>
                                                        )}
                                                        {!this.isCompact() && selectedItems && !isEmpty(selectedItems) && multiSelect && (
                                                            <div data-id={`${this.props.dataId ? this.props.dataId : 'tagSelect'}.tags`} className="tagselect__tags">
                                                                {selectedItems.map((item, index) => (
                                                                    <Tag
                                                                        dataId={`${this.props.dataId ? this.props.dataId : 'tagSelect'}.tags.${index}`}
                                                                        className="tagselect__tag"
                                                                        onRemove={() => {
                                                                            this.removeItem(item, this.callOnChange);
                                                                        }}
                                                                        key={item.text}
                                                                    >
                                                                        {item.text}
                                                                    </Tag>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {multiSelect && (
                                                            <div className="tagselect__bottom-content">
                                                                <SectionSeparator />
                                                                <button className="tagselect__clear" onClick={this.clearAll}>
                                                                    {this.props.clearText ? this.props.clearText : t('component.TagSelect.ClearFilters')}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        </Downshift>
                                    </div>
                                )}
                            </Popper>
                        </CSSTransition>,
                        document.body,
                    )}
                </Manager>
            </>
        );
    }
}

export const TagSelect = withTranslation()(TagSelectInternal);
