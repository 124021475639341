import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cloneDeep, forEach, isEqual } from 'lodash-es';
import { stringify } from 'qs';

import { notify } from '../common/utils/notify';
import i18n from '../i18n';

export interface AuthorizationData {
    companies: Array<{ Id: number; Name: string }>;
    token: string;
    companyGuid: number;
    fullName: string;
    isBoAdmin: boolean;
    user: User;
    userId: number;
    userName: string;
}

export interface User {
    BOGuid: string;
    CurrentVersion: string;
    Email: string;
    FirstName: string;
    FullName: string;
    FullNameAndPersonalCode: string;
    GroupMemberId: number;
    GroupMemberTitle: string;
    Id: number;
    IsNew: boolean;
    Language: string;
    LastName: string;
    MemberRoles: number[];
    PersonalCode: string;
    SystemSettings: string[]; // TODO: improve this type
}

const ApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '../WebApi/api',
    timeout: 100000,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

const BoApiClient = axios.create({
    baseURL: process.env.REACT_APP_BO_API_URL || '../BackOfficeWebApiCore/api',
    timeout: 100000,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

const LoginApiClient = axios.create({
    baseURL: process.env.REACT_APP_LOGIN_API_URL || '../LoginApi/api',
    timeout: 100000,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

const IdCardApiClient = axios.create({
    baseURL: process.env.REACT_APP_ID_LOGIN_URL,
    timeout: 100000,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

export function getApiClient() {
    return ApiClient;
}

export function getBoApiClient() {
    return BoApiClient;
}

export function getLoginApiClient() {
    return LoginApiClient;
}

export function getIdCardApiClient() {
    return IdCardApiClient;
}

const sessionTimer = {
    session: false,
    message: false,
    modalOpen: false,
};
const messageTime = 300000; // 5 minutes or 300 seconds
const checkSessionTimeout = 0;

function extendSession(expirationTime: number) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationTime);
    localStorage.setItem('ls.ExpirationTime', JSON.stringify(expirationDate));
}

function displaySessionTimeoutModal(timeout: number) {
    sessionTimer.message = true;
    sessionTimer.modalOpen = true;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(runSessionTimer, 1000);
    // display angular modal here
}

export function runSessionTimer() {
    const expirationTime = new Date(JSON.parse(localStorage.getItem('ls.ExpirationTime')));
    const timeLeft = expirationTime.getTime() - new Date().getTime();
    if (timeLeft <= messageTime && !sessionTimer.modalOpen && checkSessionTimeout) {
        displaySessionTimeoutModal(checkSessionTimeout);
    } else if (timeLeft <= 0 && checkSessionTimeout) {
        endSession(checkSessionTimeout);
    } else {
        updateSessionTimeout(timeLeft, checkSessionTimeout);
    }
}

function updateSessionTimeout(timeLeft: number, timeout: number) {
    if (timeout) {
        window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(runSessionTimer, timeLeft);
}

function endSession(timeout: number) {
    window.clearTimeout(timeout);
    window.location.href = '../../login/?expired';
    // log user out and redirect to login/?expired
}

export type UploadProgress = (percentage: number) => void;

function trimSpaces(data: any): any {
    if (!data || ['number', 'boolean'].includes(typeof data)) {
        return data;
    }
    if (typeof data === 'string') {
        return data.trim();
    }
    if (['object', 'array'].includes(typeof data)) {
        forEach(data, (value, key) => {
            data[key] = trimSpaces(value);
        });
        return data;
    }
    return data;
}

export function trimUrlParams(data: string, headers: any): any {
    const requestUrl = data.split('?')[0];
    const urlParams = data.split('?')[1];
    if (!!urlParams && urlParams.length > 0) {
        let params = urlParams.split('&').map((item: string) => item.split('='));
        params = params.map((param) => {
            return param.map((value) => {
                let decodedValue = cloneDeep(value);
                if (/%/.test(value)) {
                    decodedValue = decodeURI(value);
                }
                return decodedValue;
            });
        });
        const trimmedParams = trimSpaces(cloneDeep(params));
        const areParamsTrimmed = !isEqual(params, trimmedParams);
        if (areParamsTrimmed) {
            headers.areParamsTrimmed = areParamsTrimmed;
            return [requestUrl, trimmedParams.map((item: string[]) => item.join('=')).join('&')].join('?');
        }
    }
    return data;
}

export function trimRequestData(data: any, headers: any): any {
    const requestData = !!data && (/}/.test(data) ? JSON.parse(data) : data);
    if (requestData) {
        const trimmedData = trimSpaces(cloneDeep(requestData));
        const isDataTrimmed = !isEqual(requestData, trimmedData);
        if (isDataTrimmed) {
            headers.isDataTrimmed = isDataTrimmed;
            return { ...trimmedData };
        }
    }
    return data;
}

function addRequestHeaders(config: AxiosRequestConfig) {
    config.url = trimUrlParams(config.url, config.headers);
    if (!config.headers?.skipTrimming) {
        config.data = trimRequestData(config.data, config.headers);
    } else {
        delete config.headers.skipTrimming;
    }

    const authorizationData: AuthorizationData | null = JSON.parse(localStorage.getItem('ls.authorizationData'));
    if (authorizationData) {
        const date = new Date();
        config.headers.Authorization = `Bearer ${authorizationData.token}`;
        config.headers['Authorization-Token'] = authorizationData.token;
        config.headers['client-datetime'] = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    }

    return config;
}

function handleResponseFulfill(response: AxiosResponse) {
    if (
        (response.config.headers.hasOwnProperty('isDataTrimmed') && response.config.headers.isDataTrimmed) ||
        (response.config.headers.hasOwnProperty('areParamsTrimmed') && response.config.headers.areParamsTrimmed)
    ) {
        notify.info(i18n.t('General.Notification.Message.DataTrimmed'), i18n.t('General.Notification.Title.Info'));
    }
    if (checkSessionTimeout) {
        extendSession(response.headers['expirationtime']);
    }
    return response;
}

function handleResponseReject(error: AxiosError) {
    if (error.response && error.response.status === 403) {
        notify.error(i18n.t('views.error.403.Toast.Description'), i18n.t('views.error.403.Toast.Title'));
    }
    return Promise.reject(error);
}

ApiClient.interceptors.request.use(
    (config) => addRequestHeaders(config),
    (error) => {
        return Promise.reject(error);
    },
);

ApiClient.interceptors.response.use(handleResponseFulfill, handleResponseReject);

BoApiClient.interceptors.request.use(
    (config) => addRequestHeaders(config),
    (error) => {
        return Promise.reject(error);
    },
);

BoApiClient.interceptors.response.use(handleResponseFulfill, handleResponseReject);
