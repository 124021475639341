import * as React from 'react';
import { LogoProps } from '../Logo';

export const SufLogo: React.FunctionComponent<LogoProps> = ({ compact, className }: LogoProps) =>
    compact ? (
        <svg width="40" viewBox="-15 0 140 140" fill="none" className={className}>
            <path
                d="M108 0.799957C108 1.39996 108 1.89997 108 2.49997C108 42.4 108 82.3 108 122.2C108 122.8 108 123.3 108 124C104.2 124 100.5 124 96.8 124C96.8 127.8 96.8 131.4 96.8 135.1C64.6 135.1 32.6 135.1 0.399994 135.1C0.399994 134.7 0.399994 134.4 0.399994 134.1C0.399994 104.6 0.399994 75.1 0.399994 45.6C0.399994 45 0.699991 44.3 1.09999 43.9C4.19999 40.7 7.39999 37.5 10.7 34.3C11.4 33.6 11.7 33 11.7 32C11.7 21.5 11.7 11.1 11.7 0.599976C43.7 0.799976 75.9 0.799957 108 0.799957ZM92.2 16.6C73.6 16.6 55 16.6 36.3 16.6C36.3 27 36.3 37.3 36.3 47.8C25.8 47.8 15.4 47.8 4.89999 47.8C4.89999 75.5 4.89999 103.1 4.89999 130.7C34 130.7 63 130.7 92.2 130.7C92.2 92.6 92.2 54.6 92.2 16.6ZM16.2 5.29996C16.2 13.1 16.2 20.9 16.2 28.8C16.6 28.4 17 28.1 17.3 27.8C22.3 22.8 27.2 17.8 32.3 12.9C32.8 12.4 33.6 12.1 34.2 12.1C54.6 12.1 75.1 12.1 95.5 12.1C95.9 12.1 96.3 12.1 96.8 12.2C96.8 48 96.8 83.7 96.8 119.6C99.1 119.6 101.2 119.6 103.5 119.6C103.5 81.6 103.5 43.6 103.5 5.49997C74.3 5.29997 45.2 5.29996 16.2 5.29996ZM31.7 43.3C31.7 35.5 31.7 27.8 31.7 20.4C24.1 28 16.4 35.7 8.8 43.3C16.1 43.3 23.8 43.3 31.7 43.3Z"
                fill="#C5C5C5"
            />
            <path
                d="M48.6 61.2999C57.5 61.2999 66.5 61.2999 75.4 61.2999C77.7 61.2999 78.8 60.5999 78.8 59.0999C78.8 57.5999 77.7 56.7999 75.5 56.7999C57.6 56.7999 39.6 56.7999 21.7 56.7999C21.4 56.7999 21 56.7999 20.7 56.7999C19.3 56.9999 18.5 57.7999 18.5 59.0999C18.5 60.3999 19.4 61.1999 20.8 61.2999C22.5 61.3999 24.2 61.2999 25.9 61.2999C33.4 61.2999 41 61.2999 48.6 61.2999Z"
                fill="#D81A3C"
            />
            <path
                d="M48.5 70.6C39.5 70.6 30.6 70.6 21.6 70.6C19.3 70.6 18.3 71.3 18.3 72.9C18.3 74.4 19.4 75.1 21.5 75.1C39.5 75.1 57.5 75.1 75.5 75.1C75.6 75.1 75.7 75.1 75.8 75.1C77.7 75.1 78.8 74.3 78.8 72.9C78.8 71.5 77.8 70.7 75.9 70.7C66.7 70.6 57.6 70.6 48.5 70.6Z"
                fill="#213D59"
            />
            <path
                d="M48.6 84.5C39.6 84.5 30.6 84.5 21.6 84.5C21.1 84.5 20.6 84.5 20.2 84.6C19.2 84.8 18.5 85.5 18.4 86.6C18.3 87.7 18.8 88.5 19.9 88.8C20.4 89 21 89 21.6 89C39.6 89 57.7 89 75.7 89C76 89 76.3 89 76.6 89C78 88.9 78.9 87.9 78.9 86.7C78.9 85.5 77.9 84.6 76.5 84.5C73.6 84.5 70.6 84.5 67.7 84.5C61.3 84.5 54.9 84.5 48.6 84.5Z"
                fill="#C5C5C5"
            />
            <path d="M95.7 101.9H0.799988V133.7H95.7V101.9Z" fill="#C5C5C5" />
            <path
                d="M13.2 129.7C11.3 128.8 9.89999 127.5 8.89999 125.9C7.89999 124.3 7.39999 122.4 7.39999 120.2C7.39999 117.3 8.09999 114.7 9.39999 112.3C10.7 109.9 12.6 108.1 15 106.7C17.4 105.3 20.2 104.6 23.3 104.6C25.4 104.6 27.3 105 29 105.7C30.7 106.4 31.9 107.4 32.8 108.7L30.1 111C28.5 109 26.2 108 23 108C20.6 108 18.5 108.5 16.7 109.6C14.9 110.7 13.5 112.1 12.5 114C11.5 115.8 11 117.9 11 120.1C11 122.5 11.8 124.4 13.3 125.8C14.8 127.2 17 127.9 19.9 127.9C23.1 127.9 25.7 126.9 27.7 124.8L29.8 127.2C28.6 128.5 27.1 129.5 25.4 130.1C23.6 130.8 21.7 131.1 19.6 131.1C17.3 131.1 15.1 130.7 13.2 129.7Z"
                fill="#213D59"
            />
            <path
                d="M60 104.9L44.2 126.4C43 128.1 41.7 129.3 40.3 130.1C38.9 130.9 37.5 131.3 36.1 131.3C35 131.3 33.8 131 32.6 130.5L33.9 127.6C34.6 127.9 35.4 128 36.1 128C37.9 128 39.4 127.1 40.9 125.3L41.8 124.3L35.3 104.9H39.1L44.3 121.3L56.2 104.9H60Z"
                fill="#213D59"
            />
            <path
                d="M88.8 122.8C87.5 124.7 85.6 126.2 83.2 127.3C80.8 128.4 77.9 129 74.6 129.1L74 131.8H70.6L71.2 129.1C67.4 128.9 64.5 128 62.5 126.4C60.5 124.8 59.4 122.6 59.4 119.7C59.4 117.2 60.1 115 61.4 113C62.7 111 64.6 109.5 67 108.4C69.5 107.3 72.3 106.7 75.6 106.6L76.1 104H79.5L79 106.6C82.8 106.8 85.6 107.7 87.7 109.4C89.7 111 90.8 113.3 90.8 116.1C90.8 118.6 90.1 120.9 88.8 122.8ZM65.2 124.1C66.7 125.3 68.8 125.9 71.7 126.1L75 109.5C71.2 109.7 68.2 110.7 66.1 112.4C64 114.1 63 116.4 63 119.2C63 121.3 63.7 123 65.2 124.1ZM83.9 123.3C86 121.6 87 119.3 87 116.5C87 114.4 86.3 112.7 84.8 111.5C83.3 110.3 81.2 109.7 78.3 109.5L75 126.1C78.9 125.9 81.8 125 83.9 123.3Z"
                fill="#213D59"
            />
        </svg>
    ) : (
        <svg width="160" viewBox="0 0 452 140" fill="none" className={className}>
            <path
                d="M137.3 86.4C134.5 85.7 132.3 84.8 130.7 83.7L134.1 76.4C135.7 77.4 137.5 78.2 139.8 78.8C142 79.4 144.2 79.7 146.3 79.7C150.6 79.7 152.7 78.6 152.7 76.5C152.7 75.5 152.1 74.8 150.9 74.4C149.7 74 147.9 73.6 145.5 73.3C142.6 72.9 140.3 72.4 138.4 71.8C136.5 71.2 134.9 70.2 133.6 68.8C132.2 67.4 131.6 65.3 131.6 62.7C131.6 60.5 132.2 58.5 133.5 56.8C134.8 55.1 136.6 53.8 139.1 52.8C141.6 51.8 144.4 51.4 147.8 51.4C150.3 51.4 152.7 51.7 155.2 52.2C157.6 52.7 159.7 53.5 161.3 54.4L157.9 61.6C154.8 59.9 151.5 59 147.8 59C145.6 59 144 59.3 142.9 59.9C141.8 60.5 141.3 61.3 141.3 62.2C141.3 63.3 141.9 64 143.1 64.5C144.3 64.9 146.1 65.3 148.7 65.7C151.6 66.2 153.9 66.7 155.7 67.2C157.5 67.7 159.1 68.7 160.4 70.2C161.7 71.6 162.4 73.6 162.4 76.2C162.4 78.4 161.8 80.3 160.4 82C159.1 83.7 157.2 85 154.7 85.9C152.2 86.8 149.3 87.3 145.8 87.3C142.9 87.4 140.1 87.1 137.3 86.4Z"
                fill="#C5C5C5"
            />
            <path
                d="M203.8 52V87H194.2V82.8C192.9 84.3 191.3 85.5 189.4 86.3C187.5 87.1 185.5 87.5 183.4 87.5C178.8 87.5 175.2 86.2 172.5 83.5C169.8 80.9 168.5 76.9 168.5 71.7V52H178.6V70.3C178.6 75.9 181 78.8 185.7 78.8C188.1 78.8 190.1 78 191.5 76.4C193 74.8 193.7 72.5 193.7 69.3V52H203.8Z"
                fill="#C5C5C5"
            />
            <path
                d="M224.3 52.7H233.3V60.5H224.6V86.9H214.5V60.5H209V52.7H214.4V51.1C214.4 47.1 215.6 43.9 217.9 41.6C220.3 39.3 223.6 38.1 227.9 38.1C229.4 38.1 230.9 38.3 232.2 38.6C233.5 38.9 234.7 39.4 235.6 40L232.9 47.3C231.7 46.5 230.4 46.1 228.8 46.1C225.7 46.1 224.2 47.8 224.2 51.2V52.7H224.3Z"
                fill="#C5C5C5"
            />
            <path
                d="M237.1 85.6C235.9 84.4 235.3 82.9 235.3 81.1C235.3 79.2 235.9 77.7 237.1 76.6C238.3 75.5 239.8 74.9 241.6 74.9C243.4 74.9 244.9 75.5 246.1 76.6C247.3 77.7 247.9 79.3 247.9 81.1C247.9 82.9 247.3 84.4 246.1 85.6C244.9 86.8 243.4 87.4 241.6 87.4C239.8 87.4 238.3 86.8 237.1 85.6Z"
                fill="#C5C5C5"
            />
            <path
                d="M290.4 51.9999V81.0999C290.4 87.4999 288.7 92.2999 285.4 95.3999C282.1 98.4999 277.2 100.1 270.8 100.1C267.4 100.1 264.2 99.6999 261.2 98.8999C258.2 98.0999 255.7 96.8999 253.7 95.2999L257.7 87.9999C259.2 89.1999 261 90.1999 263.3 90.8999C265.6 91.5999 267.8 91.9999 270.1 91.9999C273.6 91.9999 276.2 91.1999 277.9 89.5999C279.6 87.9999 280.4 85.5999 280.4 82.3999V80.8999C277.8 83.7999 274.1 85.2999 269.4 85.2999C266.2 85.2999 263.3 84.6 260.6 83.2C257.9 81.8 255.8 79.7999 254.3 77.2999C252.7 74.6999 252 71.7999 252 68.4999C252 65.1999 252.8 62.2 254.3 59.7C255.8 57.2 258 55.1999 260.6 53.7999C263.3 52.3999 266.2 51.7 269.4 51.7C274.5 51.7 278.3 53.4 281 56.7V51.9999H290.4ZM277.8 74.4999C279.5 72.8999 280.4 70.8999 280.4 68.2999C280.4 65.6999 279.5 63.6999 277.8 62.0999C276.1 60.4999 273.9 59.7 271.2 59.7C268.5 59.7 266.3 60.4999 264.6 62.0999C262.9 63.6999 262 65.7999 262 68.2999C262 70.8999 262.9 72.8999 264.6 74.4999C266.3 76.0999 268.5 76.8999 271.2 76.8999C273.9 76.8999 276.1 76.0999 277.8 74.4999Z"
                fill="#C5C5C5"
            />
            <path
                d="M306.5 85.1C303.6 83.6 301.3 81.4 299.6 78.7C298 76 297.1 72.9 297.1 69.4C297.1 65.9 297.9 62.8 299.6 60.1C301.2 57.4 303.5 55.2 306.5 53.7C309.4 52.2 312.7 51.4 316.4 51.4C320.1 51.4 323.4 52.2 326.3 53.7C329.2 55.2 331.5 57.4 333.1 60.1C334.7 62.8 335.6 65.9 335.6 69.4C335.6 72.9 334.8 76 333.1 78.7C331.5 81.4 329.2 83.6 326.3 85.1C323.4 86.6 320.1 87.4 316.4 87.4C312.7 87.4 309.4 86.7 306.5 85.1ZM322.8 76.5C324.5 74.7 325.3 72.4 325.3 69.4C325.3 66.5 324.5 64.1 322.8 62.3C321.1 60.5 319 59.7 316.4 59.7C313.8 59.7 311.7 60.6 310 62.3C308.3 64.1 307.5 66.4 307.5 69.4C307.5 72.3 308.3 74.7 310 76.5C311.7 78.3 313.8 79.1 316.4 79.1C319 79.1 321.2 78.2 322.8 76.5Z"
                fill="#C5C5C5"
            />
            <path d="M376.1 52L361.3 87H350.8L336.1 52H346.6L356.3 75.8L366.3 52H376.1Z" fill="#C5C5C5" />
            <path
                d="M377.5 85.6C376.3 84.4 375.7 82.9 375.7 81.1C375.7 79.2 376.3 77.7 377.5 76.6C378.7 75.5 380.2 74.9 382 74.9C383.8 74.9 385.3 75.5 386.5 76.6C387.7 77.7 388.3 79.3 388.3 81.1C388.3 82.9 387.7 84.4 386.5 85.6C385.3 86.8 383.8 87.4 382 87.4C380.2 87.4 378.7 86.8 377.5 85.6Z"
                fill="#C5C5C5"
            />
            <path
                d="M409.8 52.7C411.9 51.8 414.2 51.4 416.9 51.4V60.8C415.8 60.7 415 60.7 414.6 60.7C411.7 60.7 409.4 61.5 407.8 63.1C406.2 64.7 405.3 67.2 405.3 70.4V86.9H395.2V51.9H404.9V56.5C406.1 54.9 407.7 53.6 409.8 52.7Z"
                fill="#C5C5C5"
            />
            <path
                d="M426.3 86.4C423.5 85.7 421.3 84.8 419.7 83.7L423.1 76.4C424.7 77.4 426.5 78.2 428.8 78.8C431 79.4 433.2 79.7 435.3 79.7C439.6 79.7 441.7 78.6 441.7 76.5C441.7 75.5 441.1 74.8 439.9 74.4C438.7 74 436.9 73.6 434.5 73.3C431.6 72.9 429.3 72.4 427.4 71.8C425.5 71.2 423.9 70.2 422.6 68.8C421.2 67.4 420.6 65.3 420.6 62.7C420.6 60.5 421.2 58.5 422.5 56.8C423.8 55.1 425.6 53.8 428.1 52.8C430.6 51.8 433.4 51.4 436.8 51.4C439.3 51.4 441.7 51.7 444.2 52.2C446.6 52.7 448.7 53.5 450.3 54.4L446.9 61.6C443.8 59.9 440.5 59 436.8 59C434.6 59 433 59.3 431.9 59.9C430.8 60.5 430.3 61.3 430.3 62.2C430.3 63.3 430.9 64 432.1 64.5C433.3 64.9 435.1 65.3 437.7 65.7C440.6 66.2 442.9 66.7 444.7 67.2C446.5 67.7 448.1 68.7 449.4 70.2C450.7 71.6 451.4 73.6 451.4 76.2C451.4 78.4 450.7 80.3 449.4 82C448.1 83.7 446.2 85 443.7 85.9C441.2 86.8 438.2 87.3 434.8 87.3C432 87.4 429.1 87.1 426.3 86.4Z"
                fill="#C5C5C5"
            />
            <path
                d="M108 0.799957C108 1.39996 108 1.89997 108 2.49997C108 42.4 108 82.3 108 122.2C108 122.8 108 123.3 108 124C104.2 124 100.5 124 96.8 124C96.8 127.8 96.8 131.4 96.8 135.1C64.6 135.1 32.6 135.1 0.399994 135.1C0.399994 134.7 0.399994 134.4 0.399994 134.1C0.399994 104.6 0.399994 75.1 0.399994 45.6C0.399994 45 0.699991 44.3 1.09999 43.9C4.19999 40.7 7.39999 37.5 10.7 34.3C11.4 33.6 11.7 33 11.7 32C11.7 21.5 11.7 11.1 11.7 0.599976C43.7 0.799976 75.9 0.799957 108 0.799957ZM92.2 16.6C73.6 16.6 55 16.6 36.3 16.6C36.3 27 36.3 37.3 36.3 47.8C25.8 47.8 15.4 47.8 4.89999 47.8C4.89999 75.5 4.89999 103.1 4.89999 130.7C34 130.7 63 130.7 92.2 130.7C92.2 92.6 92.2 54.6 92.2 16.6ZM16.2 5.29996C16.2 13.1 16.2 20.9 16.2 28.8C16.6 28.4 17 28.1 17.3 27.8C22.3 22.8 27.2 17.8 32.3 12.9C32.8 12.4 33.6 12.1 34.2 12.1C54.6 12.1 75.1 12.1 95.5 12.1C95.9 12.1 96.3 12.1 96.8 12.2C96.8 48 96.8 83.7 96.8 119.6C99.1 119.6 101.2 119.6 103.5 119.6C103.5 81.6 103.5 43.6 103.5 5.49997C74.3 5.29997 45.2 5.29996 16.2 5.29996ZM31.7 43.3C31.7 35.5 31.7 27.8 31.7 20.4C24.1 28 16.4 35.7 8.8 43.3C16.1 43.3 23.8 43.3 31.7 43.3Z"
                fill="#C5C5C5"
            />
            <path
                d="M48.6 61.2999C57.5 61.2999 66.5 61.2999 75.4 61.2999C77.7 61.2999 78.8 60.5999 78.8 59.0999C78.8 57.5999 77.7 56.7999 75.5 56.7999C57.6 56.7999 39.6 56.7999 21.7 56.7999C21.4 56.7999 21 56.7999 20.7 56.7999C19.3 56.9999 18.5 57.7999 18.5 59.0999C18.5 60.3999 19.4 61.1999 20.8 61.2999C22.5 61.3999 24.2 61.2999 25.9 61.2999C33.4 61.2999 41 61.2999 48.6 61.2999Z"
                fill="#D81A3C"
            />
            <path
                d="M48.5 70.6C39.5 70.6 30.6 70.6 21.6 70.6C19.3 70.6 18.3 71.3 18.3 72.9C18.3 74.4 19.4 75.1 21.5 75.1C39.5 75.1 57.5 75.1 75.5 75.1C75.6 75.1 75.7 75.1 75.8 75.1C77.7 75.1 78.8 74.3 78.8 72.9C78.8 71.5 77.8 70.7 75.9 70.7C66.7 70.6 57.6 70.6 48.5 70.6Z"
                fill="#213D59"
            />
            <path
                d="M48.6 84.5C39.6 84.5 30.6 84.5 21.6 84.5C21.1 84.5 20.6 84.5 20.2 84.6C19.2 84.8 18.5 85.5 18.4 86.6C18.3 87.7 18.8 88.5 19.9 88.8C20.4 89 21 89 21.6 89C39.6 89 57.7 89 75.7 89C76 89 76.3 89 76.6 89C78 88.9 78.9 87.9 78.9 86.7C78.9 85.5 77.9 84.6 76.5 84.5C73.6 84.5 70.6 84.5 67.7 84.5C61.3 84.5 54.9 84.5 48.6 84.5Z"
                fill="#C5C5C5"
            />
            <path d="M95.7 101.9H0.799988V133.7H95.7V101.9Z" fill="#C5C5C5" />
            <path
                d="M13.2 129.7C11.3 128.8 9.89999 127.5 8.89999 125.9C7.89999 124.3 7.39999 122.4 7.39999 120.2C7.39999 117.3 8.09999 114.7 9.39999 112.3C10.7 109.9 12.6 108.1 15 106.7C17.4 105.3 20.2 104.6 23.3 104.6C25.4 104.6 27.3 105 29 105.7C30.7 106.4 31.9 107.4 32.8 108.7L30.1 111C28.5 109 26.2 108 23 108C20.6 108 18.5 108.5 16.7 109.6C14.9 110.7 13.5 112.1 12.5 114C11.5 115.8 11 117.9 11 120.1C11 122.5 11.8 124.4 13.3 125.8C14.8 127.2 17 127.9 19.9 127.9C23.1 127.9 25.7 126.9 27.7 124.8L29.8 127.2C28.6 128.5 27.1 129.5 25.4 130.1C23.6 130.8 21.7 131.1 19.6 131.1C17.3 131.1 15.1 130.7 13.2 129.7Z"
                fill="#213D59"
            />
            <path
                d="M60 104.9L44.2 126.4C43 128.1 41.7 129.3 40.3 130.1C38.9 130.9 37.5 131.3 36.1 131.3C35 131.3 33.8 131 32.6 130.5L33.9 127.6C34.6 127.9 35.4 128 36.1 128C37.9 128 39.4 127.1 40.9 125.3L41.8 124.3L35.3 104.9H39.1L44.3 121.3L56.2 104.9H60Z"
                fill="#213D59"
            />
            <path
                d="M88.8 122.8C87.5 124.7 85.6 126.2 83.2 127.3C80.8 128.4 77.9 129 74.6 129.1L74 131.8H70.6L71.2 129.1C67.4 128.9 64.5 128 62.5 126.4C60.5 124.8 59.4 122.6 59.4 119.7C59.4 117.2 60.1 115 61.4 113C62.7 111 64.6 109.5 67 108.4C69.5 107.3 72.3 106.7 75.6 106.6L76.1 104H79.5L79 106.6C82.8 106.8 85.6 107.7 87.7 109.4C89.7 111 90.8 113.3 90.8 116.1C90.8 118.6 90.1 120.9 88.8 122.8ZM65.2 124.1C66.7 125.3 68.8 125.9 71.7 126.1L75 109.5C71.2 109.7 68.2 110.7 66.1 112.4C64 114.1 63 116.4 63 119.2C63 121.3 63.7 123 65.2 124.1ZM83.9 123.3C86 121.6 87 119.3 87 116.5C87 114.4 86.3 112.7 84.8 111.5C83.3 110.3 81.2 109.7 78.3 109.5L75 126.1C78.9 125.9 81.8 125 83.9 123.3Z"
                fill="#213D59"
            />
        </svg>
    );

export default SufLogo;
