import classNames from 'classnames';
import React from 'react';

import { LabelStyle } from '../TextInput/TextInput';

import './LabelWrapper.scss';

export interface LabelProps {
    label: string;
    children: React.ReactNode;
    className?: string;
    labelClassName?: string;
    contentClassName?: string;
    namePlacement?: LabelNamePlacement;
    alignment?: LabelAlignment;
    labelStyle?: LabelStyle;
    dataId?: string;
}

export enum LabelNamePlacement {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
}

export enum LabelAlignment {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export const LabelWrapper = (props: LabelProps) => {
    const { label, children, className, alignment, namePlacement, contentClassName, labelClassName, labelStyle } = props;
    const classes = classNames('label-wrapper', className, {
        'label-wrapper--align-left': alignment === LabelAlignment.LEFT,
        'label-wrapper--align-right': alignment === LabelAlignment.RIGHT,
    });

    const labelNameClasses = classNames('label-wrapper__name', labelClassName, {
        'label-wrapper--name-top': namePlacement === LabelNamePlacement.TOP,
        'label-wrapper--name-bottom': namePlacement === LabelNamePlacement.BOTTOM,
        'label-wrapper--name-uppercase': labelStyle === LabelStyle.UPPERCASE,
    });

    const labelContentClasses = classNames('label-wrapper__content', contentClassName);

    return (
        <div className={classes} data-id={props.dataId}>
            <label className={labelNameClasses}>{label}</label>
            <span className={labelContentClasses}>{children}</span>
        </div>
    );
};

LabelWrapper.defaultProps = {
    namePlacement: LabelNamePlacement.TOP,
    nameAlignment: LabelAlignment.LEFT,
    labelStyle: LabelStyle.UPPERCASE,
};

export default LabelWrapper;
