import { AxiosInstance, AxiosResponse } from 'axios';

import { BaseSearch, PagedListContainer, InvoiceTypeDTO } from '../types/ApiTypes';

const basicSearchParams: BaseSearch = {
    SortItems: [
        {
            SortColumn: 'Code',
            SortDirection: 0,
        },
    ],
    PagingOptions: {
        Count: 25,
        Page: 1,
    },
    Restrictions: [],
};
export default class InvoiceTypesService {
    constructor(private axios: AxiosInstance) {}

    getInvoiceTypes = async (searchParams: BaseSearch = basicSearchParams, isIncludeInUseCount = false): Promise<AxiosResponse<PagedListContainer<InvoiceTypeDTO>>> => {
        return this.axios.post(`/InvoiceType/GetList?isIncludeInUseCount=${isIncludeInUseCount}`, {
            ...searchParams,
        });
    };

    saveInvoiceType = async (invoiceType: InvoiceTypeDTO): Promise<AxiosResponse<InvoiceTypeDTO>> => {
        return this.axios.post('/InvoiceType/Add', invoiceType);
    };

    editInvoiceType = async (invoiceType: InvoiceTypeDTO): Promise<AxiosResponse<InvoiceTypeDTO>> => {
        return this.axios.post('/InvoiceType/Update', invoiceType);
    };

    deleteInvoiceType = async (invoiceType: InvoiceTypeDTO): Promise<AxiosResponse<InvoiceTypeDTO>> => {
        return this.axios.post('/InvoiceType/Delete', invoiceType);
    };
}
