import { TFunction } from 'i18next';
import * as React from 'react';
import FormikField from '../../../common/utils/FormikField';

import { TextInputField, TextInputType } from '../../../components/TextInput/TextInput';

export function SmartIdTab({ t, showSmartIdCode, smartIdVerificationCode, countryCode }: { t: TFunction; showSmartIdCode: boolean; smartIdVerificationCode: string; countryCode: string }) {
    if (showSmartIdCode) {
        return (
            <div className="login__smart-id-message">
                <p>
                    <strong>{t('views.login.controlCode.title')}</strong>
                </p>
                <p className="mt-3">{t('views.login.controlCode.description.smartId')}</p>
                <div className="login__smart-id-code-wrapper">
                    <span>{t('views.login.controlCode')}</span>
                    <span className="login__smart-id-code">{smartIdVerificationCode}</span>
                </div>
            </div>
        );
    }
    return (
        <>
            <FormikField component={TextInputField} type={TextInputType.WITH_ADDON} addonBefore={countryCode} label={t('views.login.idCode.label')} name="idCode" dataId="idCode" />
        </>
    );
}
