import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeCompanyDTO, BackOfficeUserDTO, BackOfficeManagerDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../types/ApiTypes';

export default class BoManagersService {
    constructor(private axios: AxiosInstance) {}

    getManagers = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeManagerDTO>>> => {
        return this.axios.post('/Manager/GetManagers', searchParams);
    };

    getManagerCompanies = async (userGuid: string): Promise<AxiosResponse<Array<BackOfficeCompanyDTO>>> => {
        return this.axios.get('/UserCompany/GetManagerCompanies', {
            params: {
                userGuid,
            },
        });
    };

    getCompanyManagers = async (companyGuid: string): Promise<AxiosResponse<Array<BackOfficeUserDTO>>> => {
        return this.axios.get('/ManagerCompany/GetCompanyManagers', {
            params: {
                companyGuid,
            },
        });
    };

    addManagerToCompany = async (userGuid: string, companyGuid: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get('/ManagerCompany/AddManagerToCompany', {
            params: {
                userGuid,
                companyGuid,
            },
        });
    };

    removeManagerFromCompany = async (userGuid: string, companyGuid: string): Promise<AxiosResponse<BackOfficeUserDTO>> => {
        return this.axios.get('/ManagerCompany/RemoveManagerFromCompany', {
            params: {
                userGuid,
                companyGuid,
            },
        });
    };
}
