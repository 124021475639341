import React, { useEffect, useState } from 'react';
import { addDays, isAfter } from 'date-fns';
import { cloneDeep, debounce, isEmpty } from 'lodash-es';
import { withTranslation, WithTranslation } from 'react-i18next';
import { browserName, fullBrowserVersion } from 'react-device-detect';

import { eventTrack } from '../../common/analytics/gtm';
import { notify } from '../../common/utils/notify';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import Icon, { ICONS } from '../../components/Icon/Icon';
import { AuthStatus, LoginUserDTO, MobileIdLoginResponse, SSOAuthStatus } from '../../services/controllers/LoginService';
import api from '../../services/ApiServices';
import useReCaptcha3 from '../../common/hooks/useReCaptcha3';
import { isMaxima, isAWhiteLabel, isSwedbank, getGeneralAzureLoginRedirectUrl } from '../../common/utils/whitelabelHelper';

import CountryAndLanguageSelect from './components/CountryAndLanguageSelect';
import AppStoreLinks from './components/AppStoreLinks';
import LoginTabsAndForms, { getIsSessionTimedOut } from './components/LoginTabsAndForms';
import { CountryDetectionStatus, getCountryMobilePrefix, initSession, redirectToApp, selectedLanguageKey } from './loginHelper';
import withSuspense from '../../common/hocs/withSuspense';
import UsersSelector from './components/users-selector/UsersSelector';

export interface LoginViewProps extends WithTranslation {
    countryDetectionStatus: CountryDetectionStatus;
    areWebStoreLinksHidden: boolean;
    selectedCountry: string;
    setCountry: (countryCode: string) => void;
    setLanguage: (locale: string) => void;
}

export enum LoginViewTab {
    AZURE = 'AZURE',
    ID_CARD = 'ID_CARD',
    USERNAME = 'USERNAME',
    EMAIL = 'EMAIL',
    MOBILE_ID = 'MOBILE_ID',
    SMART_ID = 'SMART_ID',
    SERBIA_SSO = 'SERBIA_SSO',
}

export interface LoginFields {
    idCode: string;
    mobile: string;
    username: string;
    email: string;
    emailPassword: string;
    password: string;
    rememberMe: boolean;
    idCardToken: string;
    returnUrl: string;
    timestamp?: number;
    activeTab?: LoginViewTab;
}

const USING_RECAPTCHA_3 = !isMaxima() && !isSwedbank();

// seconds
const CAPTCHA_3_REINIT_PERIOD = 120;

export const LoginView: React.FunctionComponent<LoginViewProps> = (props: LoginViewProps) => {
    const { countryDetectionStatus, areWebStoreLinksHidden, selectedCountry, setCountry, setLanguage, t } = props;

    const [activeTab, setActiveTab] = useState<LoginViewTab>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showMobileIdCode, setShowMobileIdCode] = useState<boolean>(false);
    const [mobileIdVerificationCode, setMobileIdVerificationCode] = useState<string>(undefined);
    const [mobileIdCheck, setMobileIdCheck] = useState<number>(null);
    const [showSmartIdCode, setShowSmartIdCode] = useState<boolean>(false);
    const [smartIdVerificationCode, setSmartIdVerificationCode] = useState<string>(undefined);
    const [smartIdCheck, setSmartIdCheck] = useState<number>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [loginEnabled, setLoginEnabled] = useState<boolean>(false);
    const [usersSelectorList, setUsersSelectorList] = useState<{ users: LoginUserDTO[]; values: LoginFields; personalCode: string }>(null);

    const [captchaReinitTimer, setCaptchaReinitTimer] = useState<number>(null);
    const [forceFetchCaptchaToken, setForceFetchCaptchaToken] = useState<boolean>(false);
    const [token, reloadCaptcha] = useReCaptcha3(USING_RECAPTCHA_3, forceFetchCaptchaToken);

    const startCaptchaReload = () => {
        if (captchaReinitTimer) {
            window.clearTimeout(captchaReinitTimer);
        }
        const newInitTimer: number = window.setTimeout(() => {
            reloadCaptcha();
            startCaptchaReload();
        }, CAPTCHA_3_REINIT_PERIOD * 1000);
        setCaptchaReinitTimer(newInitTimer);
        return;
    };

    useEffect(() => {
        if (getIsSessionTimedOut(location)) {
            setForceFetchCaptchaToken(true);
        }
        startCaptchaReload();
    }, [location]);

    const createUserSession = async (user: LoginUserDTO, values: LoginFields) => {
        setIsLoading(true);
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const response = await api.login.createSession({
                Token: user.Token,
                Country: user.Country,
                BrowserName: browserName,
                BrowserVersion: fullBrowserVersion,
                DeviceName: null,
                AppVersion: null,
            });
            const result = response.data;
            const authStatus = result.AuthStatus;
            if (authStatus === AuthStatus.ActiveUser) {
                await initSession(result, values);
                redirectToApp(result);
                return;
            } else if (authStatus === AuthStatus.WrongCredentials || authStatus === AuthStatus.NotFoundUser) {
                notify.error(t('controller.rootController.credentials_incorrect'), t('views.login.incorrectCredentialsTitle'));
            } else if (authStatus === AuthStatus.NotActiveUser) {
                notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
            } else if (authStatus === AuthStatus.NoAvailableCompanies) {
                notify.error(t('views.login.noAvailableCompanies'), t('views.login.accessDenied.title'));
            } else {
                notify.error(t('views.global.generalError'));
            }
            setIsLoading(false);
            setShowSmartIdCode(false);
            setForceFetchCaptchaToken(true);
        } catch (e) {
            setIsLoading(false);
            setShowSmartIdCode(false);
            setForceFetchCaptchaToken(true);
            notify.error(t('views.global.generalError'));
        }
    };

    const handleIdTokenLogin = async (values: LoginFields, idCardToken: string) => {
        const { t } = props;
        setIsLoading(true);
        try {
            const result = await api.login.loginWithIdtoken(
                {
                    idCardToken,
                    BrowserName: browserName,
                    BrowserVersion: fullBrowserVersion,
                    DeviceName: null,
                    AppVersion: null,
                },
                token,
            );

            if (result && result.data) {
                const authStatus = result.data.AuthStatus;
                if (authStatus === SSOAuthStatus.ActiveUser) {
                    if (result.data.Users?.length > 1) {
                        setUsersSelectorList({ users: result.data.Users, values, personalCode: result.data.Users[0]?.PersonalCode });
                    } else {
                        createUserSession(result.data.Users[0], values);
                        return;
                    }
                } else if (authStatus === SSOAuthStatus.WrongCredentials || authStatus === SSOAuthStatus.NotFoundUser) {
                    notify.error(t('controller.rootController.credentials_incorrect'), t('views.login.incorrectCredentialsTitle'));
                } else if (authStatus === SSOAuthStatus.NotActiveUser) {
                    notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
                } else {
                    notify.error(t('views.global.generalError'));
                }
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
            notify.error(t('views.global.generalError'));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!USING_RECAPTCHA_3 || (USING_RECAPTCHA_3 && token)) {
            setLoginEnabled(true);
            const queryParams = new URLSearchParams(location.search);

            if (queryParams.has('idCardToken') && !usersSelectorList) {
                setActiveTab(LoginViewTab.ID_CARD);
                const idCardToken = queryParams.get('idCardToken');
                if (idCardToken) {
                    handleIdTokenLogin(undefined, idCardToken);
                } else {
                    notify.error(queryParams.get('idLoginError'));
                    setForceFetchCaptchaToken(true);
                }
            }
        }
    }, [token]);

    const handleResize = () => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const getStoredLoginData = () => {
        try {
            const storedData: LoginFields = JSON.parse(localStorage.getItem('loginData'));

            if (storedData) {
                const storedDate = new Date(storedData.timestamp);
                // if saved timestamp is more than 90 days old, ignore the data and delete it from localStorage
                if (isAfter(new Date(), addDays(storedDate, 90))) {
                    localStorage.removeItem('loginData');
                    return undefined;
                }
            }
            return storedData;
        } catch (e) {
            return undefined;
        }
    };

    const debounceHandleResize = debounce(handleResize, 300);

    useEffect(() => {
        const storedData = getStoredLoginData();
        setActiveTab(storedData?.activeTab || LoginViewTab.USERNAME);

        if (!location.pathname.endsWith('/')) {
            history.replaceState(undefined, undefined, location.pathname + '/');
        }

        window.addEventListener('resize', debounceHandleResize);
        handleResize();
        //sendPageView('/login'); // Google Tag Manager

        return () => window.removeEventListener('resize', debounceHandleResize);
    }, []);

    const getAvailableTabs = (selectedCountry: string): Array<LoginViewTab> => {
        const tabs: Array<LoginViewTab> = [];
        if (selectedCountry === 'EE') {
            if (isMobile) {
                if (activeTab === LoginViewTab.ID_CARD) {
                    setActiveTab(LoginViewTab.USERNAME);
                }
                tabs.push(LoginViewTab.USERNAME, LoginViewTab.MOBILE_ID, LoginViewTab.SMART_ID, LoginViewTab.EMAIL);
            } else {
                tabs.push(LoginViewTab.USERNAME, LoginViewTab.ID_CARD, LoginViewTab.MOBILE_ID, LoginViewTab.SMART_ID, LoginViewTab.EMAIL);
            }
        } else if (selectedCountry === 'LT') {
            tabs.push(LoginViewTab.USERNAME, LoginViewTab.MOBILE_ID, LoginViewTab.SMART_ID, LoginViewTab.EMAIL);
        } else if (selectedCountry === 'LV') {
            tabs.push(LoginViewTab.USERNAME, LoginViewTab.SMART_ID, LoginViewTab.EMAIL);
        } else if (selectedCountry === 'RS') {
            tabs.push(LoginViewTab.USERNAME, LoginViewTab.SERBIA_SSO, LoginViewTab.EMAIL);
        } else {
            tabs.push(LoginViewTab.USERNAME, LoginViewTab.EMAIL);
        }

        if (getGeneralAzureLoginRedirectUrl()) {
            // Azure login will not appear in Serbia
            tabs.push(LoginViewTab.AZURE);
        }

        if (!tabs.includes(activeTab)) {
            setActiveTab(LoginViewTab.USERNAME);
        }
        return tabs;
    };

    const handleUsernameLogin = async (values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const result = USING_RECAPTCHA_3
                ? await api.login.loginWithUsernameCaptcha(
                      {
                          Password: values.password,
                          Username: values.username,
                          BrowserName: browserName,
                          BrowserVersion: fullBrowserVersion,
                      },
                      token,
                  )
                : await api.login.loginWithUsername({
                      Password: values.password,
                      Username: values.username,
                      BrowserName: browserName,
                      BrowserVersion: fullBrowserVersion,
                  });
            if (result && result.data) {
                const authStatus = result.data.AuthStatus;
                if (authStatus === AuthStatus.ActiveUser) {
                    await initSession(result.data, values);
                    redirectToApp(result.data);
                    return;
                } else if (authStatus === AuthStatus.WrongCredentials || authStatus === AuthStatus.NotFoundUser) {
                    notify.error(t('controller.rootController.credentials_incorrect'), t('views.login.incorrectCredentialsTitle'));
                } else if (authStatus === AuthStatus.NotActiveUser) {
                    notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
                } else if (authStatus === AuthStatus.NoAvailableCompanies) {
                    notify.error(t('views.login.noAvailableCompanies'), t('views.login.accessDenied.title'));
                } else {
                    notify.error(t('views.global.generalError'));
                }
                setIsLoading(false);
                setForceFetchCaptchaToken(true);
            }
        } catch (e) {
            notify.error(t('views.global.generalError'));
            setIsLoading(false);
            setForceFetchCaptchaToken(true);
        }
    };

    const handleEmailLogin = async (values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const result = await api.login.loginWithEmailCaptcha(
                {
                    Password: values.emailPassword,
                    Email: values.email,
                    BrowserName: browserName,
                    BrowserVersion: fullBrowserVersion,
                },
                token,
            );
            if (result && result.data) {
                const authStatus = result.data.AuthStatus;
                if (authStatus === AuthStatus.ActiveUser) {
                    await initSession(result.data, values);
                    redirectToApp(result.data);
                    return;
                } else if (authStatus === AuthStatus.WrongCredentials || authStatus === AuthStatus.NotFoundUser) {
                    notify.error(t('controller.rootController.credentials_incorrect'), t('views.login.incorrectCredentialsTitle'));
                } else if (authStatus === AuthStatus.NotActiveUser) {
                    notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
                } else if (authStatus === AuthStatus.NoAvailableCompanies) {
                    notify.error(t('views.login.noAvailableCompanies'), t('views.login.accessDenied.title'));
                } else {
                    notify.error(t('views.global.generalError'));
                }
                setIsLoading(false);
                setForceFetchCaptchaToken(true);
            }
        } catch (e) {
            notify.error(t('views.global.generalError'));
            setIsLoading(false);
            setForceFetchCaptchaToken(true);
        }
    };

    const checkForControlCode = async (loginResponse: MobileIdLoginResponse, values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const result = await api.login.mobileCheckV2({
                Token: loginResponse.Token,
                BrowserName: browserName,
                BrowserVersion: fullBrowserVersion,
                Country: loginResponse.Country,
                DeviceName: null,
                AppVersion: null,
            });
            const mobileCheckAuthStatus = result.data?.AuthStatus;

            if (mobileCheckAuthStatus === SSOAuthStatus.RequestPending) {
                if (mobileIdCheck) {
                    window.clearTimeout(mobileIdCheck);
                }
                const newMobileIdCheck: number = window.setTimeout(() => {
                    checkForControlCode(loginResponse, values);
                }, 2000);
                setMobileIdCheck(newMobileIdCheck);
                return;
            }

            if (mobileCheckAuthStatus === SSOAuthStatus.ActiveUser || mobileCheckAuthStatus === SSOAuthStatus.GoodCredentials) {
                if (result.data.Users?.length > 1) {
                    setUsersSelectorList({ users: result.data.Users, values, personalCode: values.idCode });
                } else {
                    createUserSession(result.data.Users[0], values);
                    return;
                }
            } else if (mobileCheckAuthStatus === SSOAuthStatus.NotActiveUser) {
                notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
            } else {
                notify.error(t('views.global.generalError'));
            }
            setIsLoading(false);
            setShowMobileIdCode(false);
            setForceFetchCaptchaToken(true);
        } catch (e) {
            setIsLoading(false);
            setShowMobileIdCode(false);
            setForceFetchCaptchaToken(true);
            notify.error(t('views.global.generalError'));
        }
    };

    const checkForSmartIdUsers = async (sessionId: string, values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const response = await api.login.smartIdCheckV2({ sessionId, BrowserName: browserName, BrowserVersion: fullBrowserVersion });
            const result = response.data;
            const smartCheckAuthStatus = result.AuthStatus;
            if (smartCheckAuthStatus === SSOAuthStatus.RequestPending) {
                if (smartIdCheck) {
                    window.clearTimeout(smartIdCheck);
                }
                const newSmartIdCheck: number = window.setTimeout(() => {
                    checkForSmartIdUsers(sessionId, values);
                }, 2000);
                setSmartIdCheck(newSmartIdCheck);
                return;
            }
            if (smartCheckAuthStatus === SSOAuthStatus.ActiveUser) {
                if (result.Users?.length > 1) {
                    setUsersSelectorList({ users: result.Users, values, personalCode: values.idCode });
                } else {
                    createUserSession(result.Users[0], values);
                    return;
                }
            } else if (smartCheckAuthStatus === SSOAuthStatus.RefreshTokenException || smartCheckAuthStatus === SSOAuthStatus.RefreshTokenSecurityViolation) {
                notify.error(t('views.login.smartId.tokenError'), t('views.login.accessDenied.title'));
            } else if (smartCheckAuthStatus === SSOAuthStatus.UserRefused) {
                notify.error(t('views.login.smartId.userRefused'), t('views.login.accessDenied.title'));
            } else if (smartCheckAuthStatus === SSOAuthStatus.Timeout) {
                notify.error(t('views.login.smartId.userTimedOut'), t('views.login.accessDenied.title'));
            } else if (smartCheckAuthStatus === SSOAuthStatus.NotActiveUser) {
                notify.error(t('views.login.accessDenied.message'), t('views.login.accessDenied.title'));
            } else {
                notify.error(t('views.global.generalError'));
            }
            setIsLoading(false);
            setShowSmartIdCode(false);
            setForceFetchCaptchaToken(true);
        } catch (e) {
            setIsLoading(false);
            setShowSmartIdCode(false);
            setForceFetchCaptchaToken(true);
            notify.error(t('views.global.generalError'));
        }
    };

    const handleSmartIdLogin = async (values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const result = USING_RECAPTCHA_3
                ? await api.login.loginWithSmartIdCaptcha(
                      {
                          CountryCode: selectedCountry,
                          PersonalCode: values.idCode,
                          DisplayText: process.env.REACT_APP_SMART_ID_DISPLAY_TEXT,
                      },
                      token,
                  )
                : await api.login.loginWithSmartId({
                      CountryCode: selectedCountry,
                      PersonalCode: values.idCode,
                      DisplayText: process.env.REACT_APP_SMART_ID_DISPLAY_TEXT,
                  });

            if (result && result.data) {
                const { sessionID, code } = result.data;
                setShowSmartIdCode(true);
                setSmartIdVerificationCode(code);
                checkForSmartIdUsers(sessionID, values);
            }
        } catch (e) {
            if (e.hasOwnProperty('response') && e.response?.data?.ExceptionMessage === 'User not registered.') {
                notify.error(t('views.login.smartId.error.notRegisteredWithSmartId'), t('views.login.incorrectCredentialsTitle'));
            } else {
                notify.error(t('views.global.generalError'));
            }
            setIsLoading(false);
            setForceFetchCaptchaToken(true);
        }
    };

    const handleSerbiaSSOLogin = async () => {
        window.location.href = api.login.serbiaSSORedirection();
    };

    const handleMobileIdLogin = async (values: LoginFields) => {
        setForceFetchCaptchaToken(false);
        const { t } = props;
        try {
            const result = USING_RECAPTCHA_3
                ? await api.login.loginWithMobileIdCaptcha(
                      {
                          PhoneNo: getCountryMobilePrefix(selectedCountry) + values.mobile,
                          Username: values.idCode,
                      },
                      token,
                  )
                : await api.login.loginWithMobileId({
                      PhoneNo: getCountryMobilePrefix(selectedCountry) + values.mobile,
                      Username: values.idCode,
                  });

            if (result && result.data) {
                const authStatus = result.data.AuthStatus;
                if (authStatus === AuthStatus.WrongCredentials || authStatus === AuthStatus.NotFoundUser) {
                    notify.error(t('controller.rootController.credentials_incorrect'), t('views.login.incorrectCredentialsTitle'));
                    setIsLoading(false);
                    setForceFetchCaptchaToken(true);
                } else {
                    setShowMobileIdCode(true);
                    setMobileIdVerificationCode(result.data.CheckCode);
                    checkForControlCode(result.data, values);
                }
            }
        } catch (e) {
            setIsLoading(false);
            setForceFetchCaptchaToken(true);
        }
    };

    const loadIdLoginInFrame = async () => {
        const elem = document.getElementById('login-fields');
        if (elem) {
            const frame = document.createElement('iframe');
            frame.setAttribute('name', 'id-card-iframe');
            frame.setAttribute('id', 'id-card-iframe');
            frame.setAttribute('src', process.env.REACT_APP_ID_LOGIN_URL);
            await elem.appendChild(frame);
        }
    };

    const handleSubmit = async (values: LoginFields) => {
        eventTrack({
            event: 'login_method',
            label: activeTab + ' ' + selectedCountry,
        });
        if (values.rememberMe) {
            const cachedValues = cloneDeep(values);
            delete cachedValues.password;
            const storedData = getStoredLoginData();

            // do not renew the timestamp
            if (storedData) {
                cachedValues.timestamp = storedData.timestamp || new Date().getTime();
            } else {
                cachedValues.timestamp = new Date().getTime();
            }
            cachedValues.activeTab = activeTab;
            localStorage.setItem('loginData', JSON.stringify(cachedValues));
        } else {
            localStorage.removeItem('loginData');
        }
        setIsLoading(true);
        sessionStorage.setItem('loginSession', 'true');
        if (activeTab === LoginViewTab.ID_CARD) {
            // ID card login is handled from query parameters on load
            await loadIdLoginInFrame();
        }
        if (activeTab === LoginViewTab.USERNAME) {
            handleUsernameLogin(values);
        }
        if (activeTab === LoginViewTab.EMAIL) {
            handleEmailLogin(values);
        }
        if (activeTab === LoginViewTab.MOBILE_ID) {
            handleMobileIdLogin(values);
        }
        if (activeTab === LoginViewTab.SMART_ID) {
            handleSmartIdLogin(values);
        }
        if (activeTab === LoginViewTab.SERBIA_SSO) {
            handleSerbiaSSOLogin();
        }
    };

    const getInitialValues = (): LoginFields => {
        return {
            rememberMe: false,
            email: '',
            idCode: '',
            mobile: '',
            password: '',
            returnUrl: window.location.href.split('?')[0],
            ...getStoredLoginData(),
        };
    };

    const showFormControls = () => {
        return activeTab !== LoginViewTab.MOBILE_ID || (activeTab === LoginViewTab.MOBILE_ID && !showMobileIdCode);
    };

    const isLoginEnabled = () => {
        if (activeTab === LoginViewTab.USERNAME || activeTab === LoginViewTab.ID_CARD) {
            return loginEnabled;
        }
        return true;
    };

    const handleCaptchaReset = () => {
        setForceFetchCaptchaToken(true);
    };

    const handleSelectorClose = () => {
        setUsersSelectorList(null);
        history.replaceState(undefined, undefined, location.pathname);
    };

    const isMaintenanceMessageVisible = () => {
        /*.env values are always strings*/
        if (process.env.REACT_APP_MAINTENANCE_MESSAGE_VISIBLE === 'true') {
            return isAWhiteLabel ? process.env.REACT_APP_NO_MAINTENANCE_MESSAGE_FOR_WHITELABELS !== 'true' : true;
        }
        return false;
    };

    const handleKeycloak = () => {
        api.login.getKeycloakAuthUrl({ RedirectUri: window.location.href.split('?')[0], Locale: localStorage.getItem(selectedLanguageKey), Verifier: '' }).then((result) => {
            if (!isEmpty(result.data)) {
                localStorage.setItem('keycloak', 'true');
                window.location.href = result.data;
            }
        });
    };

    if (countryDetectionStatus === CountryDetectionStatus.DETECTING) {
        return null;
    }

    if (usersSelectorList) {
        return (
            <UsersSelector
                isLoading={isLoading}
                handleLogin={createUserSession}
                backToLogin={handleSelectorClose}
                users={usersSelectorList.users}
                values={usersSelectorList.values}
                personalCode={usersSelectorList.personalCode}
            />
        );
    }
    return (
        <section className="login__container">
            {isMaintenanceMessageVisible() && (
                <ContentBlock className="login__message">
                    <Icon name={ICONS.NOTIFICATION_WARNING_2} className="login__message-icon" />
                    <p>
                        <strong>{t('views.login.maintenance.title')}</strong>
                    </p>
                    <p>{t('views.login.maintenance.message')}</p>
                </ContentBlock>
            )}

            <ContentBlock className="login__block">
                {countryDetectionStatus === CountryDetectionStatus.AVAILABLE && (
                    <LoginTabsAndForms
                        mobileIdVerificationCode={mobileIdVerificationCode}
                        showMobileIdCode={showMobileIdCode}
                        smartIdVerificationCode={smartIdVerificationCode}
                        showSmartIdCode={showSmartIdCode}
                        activeTab={activeTab}
                        handleSubmit={handleSubmit}
                        initialValues={getInitialValues()}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        showFormControls={showFormControls}
                        t={t}
                        tabs={getAvailableTabs(selectedCountry)}
                        setActiveTab={setActiveTab}
                        countryCode={selectedCountry}
                        isButtonDisabled={!isLoginEnabled()}
                        resetCaptcha={handleCaptchaReset}
                        handleKeycloak={handleKeycloak}
                    />
                )}
                {countryDetectionStatus === CountryDetectionStatus.UNAVAILABLE && <CountryAndLanguageSelect setCountry={setCountry} setLanguage={setLanguage} t={t} />}

                {!areWebStoreLinksHidden && <AppStoreLinks />}
            </ContentBlock>
        </section>
    );
};

export default withSuspense(withTranslation()(LoginView));
