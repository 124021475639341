import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeResellerDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../types/ApiTypes';

export default class BoResellersService {
    constructor(private axios: AxiosInstance) {}

    getResellers = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeResellerDTO>>> => {
        return this.axios.post('/Reseller/GetResellers', searchParams, {
            headers: {
                skipTrimming: true,
            },
        });
    };

    getResellerById = async (resellerGuid: string): Promise<AxiosResponse<BackOfficeResellerDTO>> => {
        return this.axios.post('/Reseller/GetById', {
            params: {
                resellerGuid,
            },
        });
    };

    saveReseller = async (reseller: BackOfficeResellerDTO): Promise<AxiosResponse<BackOfficeResellerDTO>> => {
        return this.axios.post('/Reseller/SaveReseller', reseller);
    };

    deleteReseller = async (resellerGuid: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.post('/Reseller/SaveReseller', { params: { resellerGuid } });
    };
}
