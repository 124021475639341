import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { addDataId } from '../../common/utils/dataId';
import Icon, { ICONS } from '../Icon/Icon';

require('./Checkbox.scss');

export interface CheckboxProps {
    name: string;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    readonly?: boolean;
    value?: boolean;
    label?: string | React.ReactNode | undefined;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    partiallyChecked?: boolean;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({ className, name, disabled, readonly, onBlur, onChange, value, label, partiallyChecked }: CheckboxProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const classes = classNames('checkbox2', className, { 'checkbox2--has-focus': hasFocus }, { 'checkbox2--no-label': !label });

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(e);
        }
        setHasFocus(false);
    };
    const handleFocus = () => {
        setHasFocus(true);
    };

    return (
        <label className={classes} data-id={addDataId(name, '.label')}>
            <input
                data-id={addDataId(name, '.input')}
                disabled={disabled || readonly}
                checked={value}
                type="checkbox"
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className="checkbox2__input"
            />
            <span className="checkbox2__title">{label}</span>
            <span className="checkbox2__checkmark">
                {partiallyChecked ? <span className="checkbox2__icon checkbox2__icon--partial" /> : <Icon name={ICONS.CHECKMARK_SMALL} className="checkbox2__icon" />}
            </span>
        </label>
    );
};

Checkbox.defaultProps = {
    readonly: false,
};

export default Checkbox;
